import { format as dateFnsFormat, Locale } from 'date-fns';
import { parseActorDate } from '../../../shared/utils/parseDates';
import { STypeGrpIds } from '../../../shared/utils/sTypeGrpIds';
import { BondResponse } from '../bond/type';
import { BondSameIssuerResponse } from '../sameIssuer/type';
import { BondList } from '../listOrFavorites';
import { AccountsOrder } from '../../accounts';
import { AccountPositionTitres } from '../../accounts/positions/type';
import { SecuritiesSecurity } from '../../securities';

export const makeBondFullNameTransformFn = (props: {
  intRateFormatter: (value: number) => string,
  locale: Locale,
  maxSIssuerName1Length?: number,
}) => (bond: BondResponse | SecuritiesSecurity | BondList | BondSameIssuerResponse | AccountsOrder | AccountPositionTitres | undefined) => {
  const { intRateFormatter, locale, maxSIssuerName1Length } = props;
  if (bond == null) {
    return '';
  }
  try {
    if (bond['S_ISSUER.NAME_1'] == null || bond['S_ACC.INT_RATE'] == null || bond['S_ACC.OBL_END_DATE'] == null) {
      return '';
    }
    const cCurrencyCode = bond['C_CURRENCY.CODE'];
    const sIssuerName1 = bond['S_ISSUER.NAME_1'];
    const sAccIntRate = intRateFormatter(bond['S_ACC.INT_RATE']);
    const sTypeGrpId = bond['S_TYPE_GRP.ID'];
    const isPerpetual = sTypeGrpId === STypeGrpIds.OBLIGATIONS_PERP;
    const sAccOblEndDate = bond['S_ACC.OBL_END_DATE'];
    const sAccOblEndDateString = !isPerpetual
      ? dateFnsFormat(parseActorDate(sAccOblEndDate), 'dd/MM/yyyy', { locale })
      : '∞';
    const sIsuerName1Abbreviation = maxSIssuerName1Length != null && sIssuerName1.length > maxSIssuerName1Length
      ? `${sIssuerName1.slice(0, maxSIssuerName1Length)}.`
      : sIssuerName1;
    return `${cCurrencyCode} ${sIsuerName1Abbreviation} ${sAccIntRate} ${sAccOblEndDateString}`;
  } catch (err) {
    return '';
  }
};
