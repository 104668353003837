import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useAccountOrdersArDocFileNameExtension } from '../../hooks';
import { LoadingFileTypeIcon } from '../../../../../../../Components/fileTypeIcon';

export const AccountOrdersArDocFileIcon = (props: {
  size?: string | number | undefined,
  stroke?: ouiStyle.Constants.Colors,
  loadingStrokeStart?: ouiStyle.Constants.Colors,
  loadingStrokeEnd?: ouiStyle.Constants.Colors,
}) => {
  const {
    size,
    stroke,
    loadingStrokeEnd,
    loadingStrokeStart,
  } = props;
  const {
    data,
    isLoading,
  } = useAccountOrdersArDocFileNameExtension();
  return (
    <LoadingFileTypeIcon
      fileType={data}
      isLoading={isLoading}
      size={size}
      stroke={stroke}
      loadingStrokeStart={loadingStrokeStart}
      loadingStrokeEnd={loadingStrokeEnd}
    />
  );
};
