import { atom, Atom } from 'jotai';
import { bondSearchParamsSearchTermAtom } from '../../../shared';

export const makeSearchTermAtom = (searchTermUrlAtom: Atom<string>) => {
  const rootAtom = atom<string | null>(null);
  const consumerAtom = atom((get) => {
    const root = get(rootAtom);
    if (root === null) {
      return get(searchTermUrlAtom);
    }
    return root;
  }, (_get, set, value: string) => {
    set(rootAtom, value);
  });
  return consumerAtom;
};

export const listOrFavoriteSearchTermAtom = makeSearchTermAtom(bondSearchParamsSearchTermAtom);

export const resetListOrFavoriteSearchTermAtom = atom(null, (_get, set) => { set(listOrFavoriteSearchTermAtom, ''); });
