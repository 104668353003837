import * as React from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';
import ouiDom from '@goldwasserexchange/oui-dom';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import { TextInput } from '../../../../OUI-dom/Inputs';
import { DefaultTextInputComponent } from '../../../../OUI-dom/Inputs/components/text/components';
import { InputTypeContextProvider } from '../../../../OUI/Inputs';
import ouiStyle from '@goldwasserexchange/oui-style';
import { Eye, EyeOff } from 'react-feather';

const TextInputComponent = (props: {
  htmlName: string,
  value: string,
  inputMode?: React.HTMLAttributes<HTMLInputElement>['inputMode'],
  pattern?: React.InputHTMLAttributes<HTMLInputElement>['pattern'],
}) => {
  const {
    htmlName,
    value,
    inputMode,
    pattern,
  } = props;
  const [passwordInputType, setPasswordInputType] = React.useState<'password' |'text'>('password');
  const passwordRevealToggle = React.useCallback(
    () => {
      setPasswordInputType((prev) => (prev === 'password' ? 'text' : 'password'));
    },
    [setPasswordInputType],
  );
  return (
    <InputTypeContextProvider type={passwordInputType}>
      <DefaultTextInputComponent
        htmlName={htmlName}
        value={value}
        inputMode={inputMode}
        pattern={pattern}
      />
      <ouiDom.Button.Button
        type="button"
        onClick={passwordRevealToggle}
        borderColor={ouiStyle.Constants.Colors.transparent}
        paddingHorizontal="half"
      >
        {passwordInputType === 'password' ? <EyeOff color={ouiStyle.Constants.Colors.primary} /> : <Eye color={ouiStyle.Constants.Colors.primary} />}
      </ouiDom.Button.Button>
    </InputTypeContextProvider>
  );
};

export const PasswordInput = (props: {
  withRepeat?: boolean,
}) => {
  const {
    withRepeat = false,
  } = props;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  return (
    <>
      <TextInput
        fieldPath={valueState.auth.password.path.join('.')}
        type="password"
      >
        <TopModel name="TextInputLabelContent">
          <Trans>Mot de passe</Trans>
        </TopModel>
        <TopModel name="TextInputComponent">
          {TextInputComponent}
        </TopModel>
      </TextInput>
      {withRepeat ? (
        <TextInput
          fieldPath={valueState.auth.passwordRepeat.path.join('.')}
          type="password"
          dependenciesFieldPaths={[
            valueState.auth.password.path.join('.'),
          ]}
        >
          <TopModel name="TextInputLabelContent">
            <Trans>Répétition du mot de passe</Trans>
          </TopModel>
          <TopModel name="TextInputEqualsRefError">
            <Trans>Différent du mot de passe</Trans>
          </TopModel>
        </TextInput>
      ) : null}
    </>
  );
};
