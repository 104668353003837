import * as React from 'react';
import { getIn, makeName } from '@goldwasserexchange/oblis-frontend-utils';
import { isNil } from 'ramda';
import { useMemo } from 'react';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import { useLingui } from '@lingui/react';
import {
  useFirstSearchKey, useGetGetFn,
} from '../../../OUI/Inputs';
import {
  T_ADD_TABLE_NAME,
} from './types';
import { ParentNameWithColumnProp } from '../../utils';
import {
  SelectListInput, TextInput, CheckboxListInput, RadioListInput,
} from '../../../OUI-dom/Inputs';
import {
  getDataTable, getElementFieldPathHooks, getColumnData, getFormatter, getTypeRepresentation, getUseMakeFieldPath,
} from './getters';
import {
  ColumnContextProvider, useColumn, useTableName,
} from './context';
import { YesNoInput } from '../../../OUI-dom/Inputs/components/yesNo';
import { SearchKey } from '../../../OUI/Inputs/components/shared/components/list/context/searchKeys/context';
import { useLinguiLocale } from '../../useLinguiLocale';

const TAddLabel = () => {
  const columnName = useColumn();
  const tableName = useTableName();
  const columnData = getColumnData(tableName, columnName);
  if ('label' in columnData) {
    return columnData.label;
  }
  return '';
};

const useGetFormatter = (column) => {
  const tableName = useTableName();
  return getFormatter(tableName, column);
};

const useGetUseMakeFieldPath = (column) => {
  const tableName = useTableName();
  const useMakeFieldPath = getUseMakeFieldPath(tableName, column);
  return useMemo(() => useMakeFieldPath, []);
};

const textLabel = (<TopModel name="TextInputLabelContent">{TAddLabel}</TopModel>);

const useGetHelpTextByColumnName = (columnName) => {
  const tableName = useTableName();
  const columnData = getColumnData(tableName, columnName);
  if (columnData && 'helpText' in columnData) {
    return columnData.helpText;
  }
  return undefined;
};

const useGetCurrentHelpText = () => {
  const columnName = useColumn();
  const helpText = useGetHelpTextByColumnName(columnName);
  return helpText;
};

const TAddHelpText = () => {
  const helpText = useGetCurrentHelpText();
  return helpText;
};

const useHasHelpTextByColumn = (columnName) => {
  const helpText = useGetHelpTextByColumnName(columnName);
  return !isNil(helpText);
};

const useGetHelpPopperContentByColumnName = (columnName) => {
  const tableName = useTableName();
  const columnData = getColumnData(tableName, columnName);
  if (columnData && 'helpPopperContent' in columnData) {
    return columnData.helpPopperContent;
  }
  return undefined;
};

const useGetCurrentHelpPopperContent = () => {
  const columnName = useColumn();
  const helpPopperContent = useGetHelpPopperContentByColumnName(columnName);
  return helpPopperContent;
};

const TAddHelpPopperContentText = () => {
  const helpPopperContent = useGetCurrentHelpPopperContent();
  return helpPopperContent;
};

const useHasHelpPopperContentByColumn = (columnName) => {
  const helpPopperContent = useGetHelpPopperContentByColumnName(columnName);
  return !isNil(helpPopperContent);
};

const textHelpText = (
  <TopModel name="TextInputFooterHelpContent">
    {TAddHelpText}
  </TopModel>
);

const textHelpPopperContentText = (
  <TopModel name="TextInputHelpPopperContent">
    {TAddHelpPopperContentText}
  </TopModel>
);

const TAddTextInput = (props: React.PropsWithChildren<ParentNameWithColumnProp>): JSX.Element => {
  const {
    column,
    parentName,
    dependenciesFieldPaths,
    children,
  } = props;
  const useGetFieldPath = useGetUseMakeFieldPath(column);
  const fieldPath = useGetFieldPath(parentName, column);
  const { formatter } = useGetFormatter(column);
  const hasHelpText = useHasHelpTextByColumn(column);
  const hasHelpPopperContent = useHasHelpPopperContentByColumn(column);
  return (
    <TextInput
      fieldPath={fieldPath}
      dependenciesFieldPaths={dependenciesFieldPaths}
      {...formatter}
    >
      {children}
      {textLabel}
      {hasHelpText ? textHelpText : null}
      {hasHelpPopperContent ? textHelpPopperContentText : null}
    </TextInput>
  );
};

const useMakeList = (column) => {
  const tableName = useTableName();
  const { data } = getDataTable(tableName, column);
  const list = React.useMemo(() => Object.keys(data), [data]);
  return list;
};

const useGetCheckboxData = () => {
  const tableName = useTableName();
  const column = useColumn();
  const primaryKey = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const { data } = getDataTable(tableName, column);
  return data[primaryKey];
};

const useMakeUseFilterFn = (column) => {
  const tableName = useTableName();
  const { useFilterFn } = getDataTable(tableName, column);
  return useFilterFn;
};

const useMakeUseSortFn = (column) => {
  const tableName = useTableName();
  const { useSortFn } = getDataTable(tableName, column);
  return useSortFn;
};

const useMakeGetFn = () => {
  const column = useColumn();
  const tableName = useTableName();
  const locale = useLinguiLocale();
  const {
    _,
  } = useLingui();
  const { data, tableName: linkedTableName } = getDataTable(tableName, column);
  const getFn = React.useCallback((source: { ID: string } | string | undefined | null, path: string) => {
    const primaryKey = typeof source === 'string' || source == null ? source : source.ID;
    const rawValue = getIn(data, makeName(primaryKey, path));
    if (!rawValue) {
      return null;
    }
    const { representer } = getTypeRepresentation(linkedTableName, path);
    return !representer ? rawValue : representer(locale, _, rawValue);
  }, [data, linkedTableName, locale]);
  return getFn;
};

const getSearchKeys = (tableName, column) => {
  const { searchKeys } = getDataTable(tableName, column);
  return !isNil(searchKeys) ? searchKeys : [];
};

const useGetSearchKeys = (column): SearchKey[] => {
  const tableName = useTableName();
  const searchKeys = getSearchKeys(tableName, column);
  return searchKeys;
};

const TAddSelectInputListLabel = () => {
  const firstSearchKey = useFirstSearchKey() ?? 'LML_DESCRIPTION';
  const getFn = useGetGetFn();
  const primaryKey = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const fieldLmlValue = getFn(primaryKey, firstSearchKey);
  return fieldLmlValue;
};

const radiolistLabel = (<TopModel name="RadioListInputListElementLabelContent">{TAddSelectInputListLabel}</TopModel>);

const radioLabel = (<TopModel name="RadioListInputLabelContent">{TAddLabel}</TopModel>);

const radioHelpText = (
  <TopModel name="RadioListInputFooterHelpContent">
    {TAddHelpText}
  </TopModel>
);

const radioHelpPopperContentText = (
  <TopModel name="RadioListInputHelpPopperContent">
    {TAddHelpPopperContentText}
  </TopModel>
);

const TAddRadioListInput = (props: React.PropsWithChildren<ParentNameWithColumnProp>): JSX.Element => {
  const {
    parentName,
    column,
    dependenciesFieldPaths,
    children,
  } = props;
  const useGetFieldPath = useGetUseMakeFieldPath(column);
  const fieldPath = useGetFieldPath(parentName, column);
  const list = useMakeList(column);
  const searchKeys = useGetSearchKeys(column);
  const useFilterFn = useMakeUseFilterFn(column);
  const useSortFn = useMakeUseSortFn(column);
  const hasHelpText = useHasHelpTextByColumn(column);
  const hasHelpPopperContent = useHasHelpPopperContentByColumn(column);
  return (
    <RadioListInput
      fieldPath={fieldPath}
      list={list}
      useGetFn={useMakeGetFn}
      searchKeys={searchKeys}
      useFilterFn={useFilterFn}
      useSortFn={useSortFn}
      dependenciesFieldPaths={dependenciesFieldPaths}
    >
      {children}
      {radioLabel}
      {radiolistLabel}
      {hasHelpText ? radioHelpText : null}
      {hasHelpPopperContent ? radioHelpPopperContentText : null}
    </RadioListInput>
  );
};

const selectlistLabel = (<TopModel name="SelectInputListElementLabelContent">{TAddSelectInputListLabel}</TopModel>);

const selectLabel = (<TopModel name="SelectInputLabelContent">{TAddLabel}</TopModel>);

const selectHelpText = (
  <TopModel name="SelectInputFooterHelpContent">
    {TAddHelpText}
  </TopModel>
);

const selectHelpPopperContentText = (
  <TopModel name="SelectInputHelpPopperContent">
    {TAddHelpPopperContentText}
  </TopModel>
);

const TAddSelectInput = (props: React.PropsWithChildren<ParentNameWithColumnProp>): JSX.Element => {
  const {
    parentName,
    column,
    children,
    dependenciesFieldPaths,
  } = props;
  const useGetFieldPath = useGetUseMakeFieldPath(column);
  const fieldPath = useGetFieldPath(parentName, column);
  const list = useMakeList(column);
  const searchKeys = useGetSearchKeys(column);
  const useFilterFn = useMakeUseFilterFn(column);
  const useSortFn = useMakeUseSortFn(column);
  const hasHelpText = useHasHelpTextByColumn(column);
  const hasHelpPopperContent = useHasHelpPopperContentByColumn(column);
  return (
    <SelectListInput
      fieldPath={fieldPath}
      list={list}
      useGetFn={useMakeGetFn}
      searchKeys={searchKeys}
      useFilterFn={useFilterFn}
      useSortFn={useSortFn}
      dependenciesFieldPaths={dependenciesFieldPaths}
    >
      {children}
      {selectLabel}
      {selectlistLabel}
      {hasHelpText ? selectHelpText : null}
      {hasHelpPopperContent ? selectHelpPopperContentText : null}
    </SelectListInput>
  );
};

const useGetElementFieldPathHooks = (column: string) => {
  const tableName = useTableName();
  const elementFieldPathHook = getElementFieldPathHooks(tableName, column);
  return elementFieldPathHook;
};

const TAddCheckBoxListLabel = (): React.ReactNode => {
  const data = useGetCheckboxData();
  const translated = 'value' in data ? data.value : '';
  return translated as React.ReactNode;
};

const checkBoxlistLabel = (<TopModel name="CheckboxListInputListElementLabelContent">{TAddCheckBoxListLabel}</TopModel>);

const checkboxLabel = (<TopModel name="CheckboxListInputLabelContent">{TAddLabel}</TopModel>);

const checkBoxHelpText = (
  <TopModel name="CheckboxListInputFooterHelpContent">
    {TAddHelpText}
  </TopModel>
);

const checkBoxHelpPopperContentText = (
  <TopModel name="CheckboxListInputHelpPopperContent">
    {TAddHelpPopperContentText}
  </TopModel>
);

const TAddCheckboxListInput = (props: React.PropsWithChildren<ParentNameWithColumnProp>): JSX.Element => {
  const {
    parentName = '', column, dependenciesFieldPaths, children,
  } = props;
  const tableName = useTableName();
  const useGetFieldPath = useGetUseMakeFieldPath(column);
  const fieldPath = useGetFieldPath(parentName, column);
  const list = useMakeList(column);
  const elementFieldPathHook = useGetElementFieldPathHooks(column);
  const columnData = getColumnData(tableName, column);
  const useFilterFn = useMakeUseFilterFn(column);
  const useSortFn = useMakeUseSortFn(column);
  const hasHelpText = useHasHelpTextByColumn(column);
  const hasHelpPopperContent = useHasHelpPopperContentByColumn(column);
  const checkBoxFieldPaths = columnData && 'elementFieldPathsFn' in columnData && columnData.elementFieldPathsFn ? columnData.elementFieldPathsFn(fieldPath) : [];
  return (
    <CheckboxListInput
      fieldPath={fieldPath}
      list={list}
      elementFieldPathHook={elementFieldPathHook}
      useFilterFn={useFilterFn}
      useSortFn={useSortFn}
      searchKeys={['value']}
      checkboxPotentialFieldPaths={checkBoxFieldPaths}
      dependenciesFieldPaths={dependenciesFieldPaths}
    >
      {children}
      {checkboxLabel}
      {checkBoxlistLabel}
      {hasHelpText ? checkBoxHelpText : null}
      {hasHelpPopperContent ? checkBoxHelpPopperContentText : null}
    </CheckboxListInput>
  );
};

const useGetYesNoSortFnByColumnName = (columnName) => {
  const tableName = useTableName();
  const columnData = getColumnData(tableName, columnName);
  return columnData && 'useSortFn' in columnData ? columnData.useSortFn : undefined;
};

const TAddYesNoInput = (props: React.PropsWithChildren<ParentNameWithColumnProp>): JSX.Element => {
  const {
    parentName,
    column,
    dependenciesFieldPaths,
    children,
  } = props;
  const useGetFieldPath = useGetUseMakeFieldPath(column);
  const fieldPath = useGetFieldPath(parentName, column);
  const hasHelpText = useHasHelpTextByColumn(column);
  const hasHelpPopperContent = useHasHelpPopperContentByColumn(column);
  const useSortFn = useGetYesNoSortFnByColumnName(column);
  return (
    <YesNoInput
      fieldPath={fieldPath}
      useSortFn={useSortFn}
      dependenciesFieldPaths={dependenciesFieldPaths}
    >
      {children}
      {radioLabel}
      {hasHelpText ? radioHelpText : null}
      {hasHelpPopperContent ? radioHelpPopperContentText : null}
    </YesNoInput>
  );
};

export const TAddInput = (props: React.PropsWithChildren<ParentNameWithColumnProp>): JSX.Element => {
  const { column, parentName, children } = props;
  const columnData = getColumnData<Record<string, unknown>>(T_ADD_TABLE_NAME, column);
  const { inputType, validators, useMakeDependenciesFieldPaths = () => [] } = columnData;
  const deps = useMakeDependenciesFieldPaths(parentName, column);
  const dependenciesFieldPaths = [
    ...deps,
    ...(validators && 'logic' in validators ? ouiBase.utils.hookstate.extractPathsFromLogic(validators.logic) : []).map((field) => ouiBase.Form.replaceParentNameTag(parentName, field) as string),
  ];
  switch (inputType) {
    case 'Text': {
      return (
        <ColumnContextProvider column={column}>
          <TAddTextInput
            parentName={parentName}
            column={column}
            dependenciesFieldPaths={dependenciesFieldPaths}
          >
            {children}
          </TAddTextInput>
        </ColumnContextProvider>
      );
    }
    case 'RadioList': {
      return (
        <ColumnContextProvider column={column}>
          <TAddRadioListInput
            parentName={parentName}
            column={column}
            dependenciesFieldPaths={dependenciesFieldPaths}
          >
            {children}
          </TAddRadioListInput>
        </ColumnContextProvider>
      );
    }
    case 'CheckboxList': {
      return (
        <ColumnContextProvider column={column}>
          <TAddCheckboxListInput
            parentName={parentName}
            column={column}
            dependenciesFieldPaths={dependenciesFieldPaths}
          >
            {children}
          </TAddCheckboxListInput>
        </ColumnContextProvider>
      );
    }
    case 'Select': {
      return (
        <ColumnContextProvider column={column}>
          <TAddSelectInput
            parentName={parentName}
            column={column}
            dependenciesFieldPaths={dependenciesFieldPaths}
          >
            {children}
          </TAddSelectInput>
        </ColumnContextProvider>
      );
    }
    case 'YesNo': {
      return (
        <ColumnContextProvider column={column}>
          <TAddYesNoInput
            parentName={parentName}
            column={column}
            dependenciesFieldPaths={dependenciesFieldPaths}
          >
            {children}
          </TAddYesNoInput>
        </ColumnContextProvider>
      );
    }
    case 'Custom': {
      const { Input, inputProps } = columnData;
      return (
        <ColumnContextProvider column={column}>
          <Input
            {...inputProps}
            parentName={parentName}
            dependenciesFieldPaths={dependenciesFieldPaths}
          />
        </ColumnContextProvider>
      );
    }
    default: {
      throw new TypeError('unknown TAdd input type');
    }
  }
};
