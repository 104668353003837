import {
  GetPlaceCommand,
} from '@aws-sdk/client-location';
import { getLocationClient } from './getLocationClient';

export const tryGetPlaceFetcher = async (props: {
  placeIndexName: string,
  placeId: string,
  locale: string,
}) => {
  const {
    placeIndexName,
    placeId,
    locale,
  } = props;
  const locationClient = await getLocationClient();
  const command = new GetPlaceCommand({
    PlaceId: placeId,
    IndexName: placeIndexName,
    Language: locale,
  });
  try {
    const response = await locationClient.send(command);
    return response.Place;
  } catch (err) {
    return undefined;
  }
};
