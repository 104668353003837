import startsWith from 'ramda/es/startsWith';
import map from 'ramda/es/map';
import ouiBase from '@goldwasserexchange/oui';
import { isNil } from 'ramda';

export type OnboardingStringConds = 'conseilInvestmentGoalsNOK' | 'conseilInvestmentGoalsNOK' | 'conseilFinancialSituationNOK' | 'conseilFinancialSituationOk' | 'gestionFinancialSituationNOK' | 'gestionInvestmentGoalsNOK' | 'gestionFinancialSituationOk'

export type OnboardingConds = OnboardingStringConds | {
  type: 'formikValuesAreValid',
  names: string[],
} | {
  type: OnboardingStringConds,
} | {
  type: 'isStepReached',
  stepName: string,
} | {
  type: 'formFilterLogic',
  logic: ouiBase.utils.hookstate.FormValueFilterLogic<boolean>,
  parentName: string,
} | {
  type: 'tAddHaveFiles',
} | {
  type: 'moralHasFiles',
} | {
  type: 'mandatariesAreValid',
} | {
  type: 'mandatariesBeneficiariesAreValid',
} | {
  type: 'beneficiariesAreValid',
} | {
  type: 'conseilInvestmentGoalsOk',
} | {
  type: 'gestionInvestmentGoalsOk',
} | {
  type: 'titularsNeedRepresentative',
} | {
  type: 'canAddProcurationToTitulars',
} | {
  type: 'represented',
} | {
  type: 'titularsAndRepresentativeAreValidOrPersonTypeForMinor',
} | {
  type: 'titularsAndRepresentativeAreValid',
} | {
  type: 'representativeOrTitularsValid',
} | {
  type: 'titularProcurationsAreValid',
} | {
  type: 'procurationsAreValid',
} | {
  type: 'canAddUboToMandatary',
} | {
  type: 'isServiceSelectionCorrect',
} | {
  type: 'fiscalityReachable',
} | {
  type: 'hasNokProducts',
}

type GoToOptions = {
  actions?: string[],
  cond?: OnboardingConds,
  fullPath?: boolean,
}

type Options = {
  actions?: string[],
  cond?: OnboardingConds,
  save?: boolean,
}

type ActionDefinition = {
  target: string,
  actions: string[],
  cond?: string | {
    type: string,
    stepName?: string,
  },
}

export enum NavAction {
  'NEXT' = 'NEXT',
  'PREVIOUS' = 'PREVIOUS',
  'SUBMIT' = 'SUBMIT',
  'SKIP' = 'SKIP'
}

const makeNav = (
  type: NavAction,
  target: string,
  options: Options = {},
): Record<string, ActionDefinition> => {
  const { actions: sourceActions = [], cond, save = true } = options;
  const actions = ['setDirection', save ? 'save' : null, ...sourceActions].filter<string>((action): action is string => !isNil(action));
  return {
    [type]: {
      target,
      actions,
      ...(cond ? { cond } : {}),
    },
  };
};

export const getGoToActionType = (target: string): string => `GOTO${target.replace(/\./g, '_')}`;

export const makeGoTo = (parentName: string, target: string, options?: GoToOptions): { [type: string]: ActionDefinition } => {
  const fullTarget = `${parentName ? `${parentName}.` : ''}${target}`;
  const type = getGoToActionType(fullTarget);
  const { actions = [], cond, fullPath = false } = options || {};
  return ({
    [type]: {
      target: fullPath ? fullTarget : target,
      actions: [...actions, 'save'],
      cond,
    },
  });
};

export const makeNext = (target: string, options?: Options): { [type: string]: ActionDefinition } => makeNav(NavAction.NEXT, target, options);

export const makePrev = (target: string, options?: Options): { [type: string]: ActionDefinition } => makeNav(NavAction.PREVIOUS, target, options);

export const makeSubmit = (target: string, options?: Options): { [type: string]: ActionDefinition } => makeNav(NavAction.SUBMIT, target, options);

export const makeSkip = (target: string, options?: Options): { [type: string]: ActionDefinition } => makeNav(NavAction.SKIP, target, options);

export const getGotosOn = (on) => Object.fromEntries(Object.entries(on).filter(([key]) => startsWith('GOTO', key)));

export const nestIn = (parentTarget, transitions, override = {}) => map(({ target, ...rest }) => ({ target: `${parentTarget ? `${parentTarget}.` : ''}${target}`, ...rest, ...override }), transitions);
