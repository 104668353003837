import { useCallback } from 'react';
import { useSecuritiesSecurityTransformApi } from '../api';
import { SecuritiesSecurity } from '../../type';
import { lmlGetPositionsTitleCase } from '../../../../accounts';
import { makeBondFullNameTransformFn } from '../../../../bonds/shared/bondFullName';
import { Locale as DateFnsLocale } from 'date-fns';
import { useNumberFormatter } from '../../../../../../../Components/NumberDisplay';
import { useGetDateFnsLocale } from '../../../../../../../Components/getDateLocale';
import { useAtomValue } from 'jotai';
import { pathnameUrlLanguageAtom } from '../../../../../../../history';
import { isBond } from '../utils';

const makeSecuritiesSecurityFullNameTransformFn = (props: {
  locale: string,
  dateFnsLocale: DateFnsLocale,
  intRateFormatter: (value: number) => string,
  maxSIssuerName1Length?: number,
  withIsin?: boolean,
}) => {
  const {
    locale,
    dateFnsLocale,
    intRateFormatter,
    maxSIssuerName1Length,
    withIsin = false,
  } = props;
  return (securitiesSecurity: SecuritiesSecurity | undefined) => {
    if (securitiesSecurity == null) {
      return undefined;
    }

    const sTypeGrpId = securitiesSecurity['S_TYPE_GRP.ID'];
    if (!isBond(sTypeGrpId)) {
      const titleCaser = lmlGetPositionsTitleCase(locale);
      const lml2 = securitiesSecurity['S_ACC.LML2_DESCRIPTION'];
      const fullName = titleCaser(lml2);
      if (withIsin === true) {
        const isin = securitiesSecurity['S_ACC.ISIN'];
        return `${fullName} (${isin})`;
      }
      return fullName;
    }

    const fullName = makeBondFullNameTransformFn({
      locale: dateFnsLocale,
      intRateFormatter,
      maxSIssuerName1Length,
    })(securitiesSecurity);

    if (withIsin === true) {
      const isin = securitiesSecurity['S_ACC.ISIN'];
      return `${fullName} (${isin})`;
    }

    return fullName;
  };
};

const intRateFormat = {
  format: {
    style: 'percent' as const,
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  },
  divider: 100,
};

export const useSecuritiesSecurityFullName = (props?: {
  withIsin?: boolean,
}) => {
  const {
    withIsin,
  } = props ?? {};
  const locale = useAtomValue(pathnameUrlLanguageAtom);
  const intRateFormatter = useNumberFormatter(intRateFormat);
  const dateFnsLocale = useGetDateFnsLocale();
  const transformFn = useCallback(makeSecuritiesSecurityFullNameTransformFn({
    locale,
    intRateFormatter,
    dateFnsLocale,
    withIsin,
  }), [
    locale,
    intRateFormatter,
    dateFnsLocale,
    withIsin,
  ]);
  const response = useSecuritiesSecurityTransformApi(transformFn);
  return response;
};
