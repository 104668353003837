import * as React from 'react';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';
import { useSecuritiesSecuritySAccLml2Description, useSecuritiesSecuritySAccLml2DescriptionFormatted } from '../../hooks';

export const SecuritiesSecuritySAccLml2Description = () => {
  const {
    data,
    isLoading,
    error,
  } = useSecuritiesSecuritySAccLml2Description();
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading}
      error={error}
    />
  );
};

export const SecuritiesSecuritySAccLml2DescriptionFormatted = () => {
  const {
    data,
    isLoading,
    error,
  } = useSecuritiesSecuritySAccLml2DescriptionFormatted();
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading}
      error={error}
    />
  );
};
