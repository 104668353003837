import { useMemo } from 'react';
import { AccountDocument } from '../type';
import { Accounts } from '../../enum';
import { useAccountDocumentsStringifiedParams } from './defaultParams';
import { getServiceUrl } from '../../../../shared';
import { ACTOR_DEDUPING_INTERVAL } from '../../../shared';
import { useFetchWithAuthWithSend } from '../../../shared/utils/fetchWithAuth';
import { makeApiUrl } from '../../../../../../aws';
import useSWR, { KeyedMutator } from 'swr';

const useMakeAccountDocumentsUrl = () => {
  const stringifiedParams = useAccountDocumentsStringifiedParams();
  const url = stringifiedParams !== null ? `${makeApiUrl('actor')}/${getServiceUrl(Accounts.Documents)}${stringifiedParams}` : null;
  return url;
};

type BaseUseAccountDocumentApiResponse = {
  isLoading: boolean,
  error?: any,
  mutate: KeyedMutator<AccountDocument[]>,
  isValidating: boolean,
}

type UseAccountDocumentsApiResponse = {
  data: undefined | AccountDocument[],
} & BaseUseAccountDocumentApiResponse;

type FilterPredicate = (accountsCashflow: AccountDocument) => boolean;

type SortPredicate = (prevAccountDocument: AccountDocument, nextAccountDocument: AccountDocument) => number;

type UseAccountDocumentsApiProps = {
  filterPredicate?: FilterPredicate,
  sortPredicate?: SortPredicate,
};
export const useAccountDocumentsApi = (props: UseAccountDocumentsApiProps): UseAccountDocumentsApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakeAccountDocumentsUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountDocument[], any>(
    url,
    fetcher,
    {
      dedupingInterval: ACTOR_DEDUPING_INTERVAL,
    },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    let { data } = result;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.filter((position) => filterPredicate(position));
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformArrayFunction<T> = (accountDocuments: AccountDocument[] | undefined) => T

type UseAccountDocumentsApiTransformProps<T> = {
  transformFunction: TransformArrayFunction<T>,
} & UseAccountDocumentsApiProps

type UseAccountDocumentApiTransformResponse<T> = {
  data: T | undefined,
} & BaseUseAccountDocumentApiResponse;

export function useAccountDocumentsTransformApi<T>(props: UseAccountDocumentsApiTransformProps<T>): UseAccountDocumentApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useAccountDocumentsApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: (!isLoading && !error) ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseAccountDocumentApiResponse = {
  data: AccountDocument | undefined,
} & BaseUseAccountDocumentApiResponse;

export const useAccountDocumentApi = (props: UseAccountDocumentsApiProps): UseAccountDocumentApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const url = useMakeAccountDocumentsUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountDocument[], any>(
    url,
    fetcher,
    {
      dedupingInterval: ACTOR_DEDUPING_INTERVAL,
    },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    // eslint-disable-next-line prefer-destructuring
    let data: AccountDocument[] | AccountDocument | undefined = result.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.find((document) => filterPredicate(document));
      }
      if (Array.isArray(data)) {
        // eslint-disable-next-line prefer-destructuring
        data = data[0];
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformFunction<T> = (accountDocument: AccountDocument | undefined) => T

type UseAccountDocumentApiTransformProps<T> = {
  transformFunction: TransformFunction<T>,
} & UseAccountDocumentsApiProps;

export function useAccountDocumentTransformApi<T>(props: UseAccountDocumentApiTransformProps<T>): UseAccountDocumentApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useAccountDocumentApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: !isLoading && !error ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseAccountDocumentFieldApiResponse<Field extends keyof AccountDocument> = {
  data: AccountDocument[Field] | undefined,
} & BaseUseAccountDocumentApiResponse;

type UseAccountDocumentFieldApiProps<Field extends keyof AccountDocument> = {
  field: Field,
} & UseAccountDocumentsApiProps

export function useAccountDocumentFieldApi<Field extends keyof AccountDocument>(props: UseAccountDocumentFieldApiProps<Field>): UseAccountDocumentFieldApiResponse<Field> {
  const {
    filterPredicate,
    sortPredicate,
    field,
  } = props;
  const response = useAccountDocumentApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
    } = response;
    return {
      ...response,
      data: data ? data[field] : undefined,
    };
  }, [response, field]);
  return result;
}
