import { Trans } from '@lingui/macro';
import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import { ActionFilter, useSendOnClick } from '../../../../Machine';
import { DirectNavigationButtonContainer } from './container';
import ouiStyle from '@goldwasserexchange/oui-style';

export const BackToServiceSelection = (props: {
  padding?: ouiStyle.InStyle['padding'],
  margin?: ouiStyle.InStyle['margin'],
}) => {
  const {
    padding,
    margin,
  } = props;
  const onClick = useSendOnClick('GOTOorientation_serviceSelection');
  return (
    <DirectNavigationButtonContainer
      padding={padding}
      margin={margin}
    >
      <ActionFilter
        is="GOTOorientation_serviceSelection"
      >
        <ouiDom.Button.Button
          type="button"
          onClick={onClick}
        >
          <Trans>
            Retourner à la sélection du service
          </Trans>
        </ouiDom.Button.Button>
      </ActionFilter>
    </DirectNavigationButtonContainer>
  );
};
