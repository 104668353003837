import * as React from 'react';
import { Accounts } from '../../../accounts/enum';
import {
  AccountDocumentArDocDocumentType,
  AccountHistoryArDocDocumentType,
  AccountOrdersArDocDocumentType,
  AccountPositionsHistoryArDocDocumentType,
} from '../../../accounts';

export const ArDocDocumentType = <Service extends Accounts.History | Accounts.Orders | Accounts.Documents | Accounts.PositionsHistory>(
  props: {
    service: Service,
  },
) => {
  const {
    service,
  } = props;
  if (service === Accounts.History) {
    return <AccountHistoryArDocDocumentType />;
  }
  if (service === Accounts.Documents) {
    return <AccountDocumentArDocDocumentType />;
  }
  if (service === Accounts.PositionsHistory) {
    return <AccountPositionsHistoryArDocDocumentType />;
  }
  if (service === Accounts.Orders) {
    return <AccountOrdersArDocDocumentType />;
  }
  return null;
};
