import { BrowserHistory } from 'history';
import { createPath } from 'history';
// same dependency as swr
import hash from 'stable-hash';

type To = Parameters<BrowserHistory['replace']>[0] | Parameters<BrowserHistory['push']>[0];
type State = Parameters<BrowserHistory['replace']>[1] | Parameters<BrowserHistory['push']>[1];

const navigateParamsHasChanged = (browserHistory: BrowserHistory, to: To, state: State) => {
  const currentPathname = createPath(browserHistory.location);
  const currentStateHash = hash(browserHistory.location.state);
  const toPathname = typeof to === 'string' ? to : createPath(to);
  const toStateHash = hash(state);
  return currentPathname !== toPathname || currentStateHash !== toStateHash;
};

export const safeHistory = (browserHistory: BrowserHistory) => ({
  action: browserHistory.action,
  location: browserHistory.location,
  createHref: browserHistory.createHref,
  push: (to : To, state?: State) => {
    if (navigateParamsHasChanged(browserHistory, to, state)) {
      browserHistory.push(to, state);
    }
  },
  replace: (to : To, state?: State) => {
    if (navigateParamsHasChanged(browserHistory, to, state)) {
      browserHistory.replace(to, state);
    }
  },
  go: browserHistory.go,
  back: browserHistory.back,
  forward: browserHistory.forward,
  listen: browserHistory.listen,
  block: browserHistory.block,
});
