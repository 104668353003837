import { useContext } from 'react';
import { useHookstate } from '@hookstate/core';
import { useFormData } from '@goldwasserexchange/oui/src/Form';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import {
  authMachineContext,
  cognitoUserContext,
  authErrorMessageContext,
  authErrorNameContext,
  placeIndexNameContext,
  singleTableNameContext,
} from './context';

export const useAuthMachineContext = () => {
  const context = useContext(authMachineContext);
  if (context === null) {
    throw new Error('you are outside of Auth context');
  }
  return context;
};

export const useAuthMachineState = () => {
  const [state] = useAuthMachineContext();
  return state;
};

export const useAuthMachineStateMatches = (match: string) => {
  const state = useAuthMachineState();
  return state.startsWith(match);
};

export const useAuthMachineSend = () => {
  const [, send] = useAuthMachineContext();
  return send;
};

export const useAuthMachineCognitoUser = () => {
  const cognitoUser = useContext(cognitoUserContext);
  return cognitoUser;
};

export const useAuthMachineErrorName = () => {
  const error = useContext(authErrorNameContext);
  return error;
};

export const useAuthMachineErrorMessage = () => {
  const error = useContext(authErrorMessageContext);
  return error;
};

export const useUserBucketName = () => {
  const valueState = useFormData<UncastedDataStructure>();
  const userBucketName = useHookstate(valueState.auth.bucketName).get();
  return userBucketName;
};

export const useIsConnected = (withReAuth = true) => {
  const connected = useAuthMachineStateMatches('connected');
  const signingOut = useAuthMachineStateMatches('connected.signout');
  const redirecting = useAuthMachineStateMatches('connected.redirectToHome');
  const transferSignOut = useAuthMachineStateMatches('connected.transfer.logout');
  const transferSignIn = useAuthMachineStateMatches('connected.transfer.transferSignIn');
  const reAuth = useAuthMachineStateMatches('connected.reAuth');
  if (withReAuth === false) {
    return connected && !(
      signingOut
      || redirecting
      || transferSignOut
      || transferSignIn
    );
  }
  return connected && !(
    signingOut
    || redirecting
    || transferSignOut
    || transferSignIn
    || reAuth
  );
};

export const useAuthIsSettle = () => {
  const connected = useAuthMachineStateMatches('connected');
  const disconnected = useAuthMachineStateMatches('disconnected');
  return connected || disconnected;
};

export const usePlaceIndexName = () => {
  const value = useContext(placeIndexNameContext);
  return value;
};

export const useSingleTableNameContext = () => {
  const value = useContext(singleTableNameContext);
  return value;
};
