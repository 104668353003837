import { dataStructure, AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { msg, Trans } from '@lingui/macro';
import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { useLingui } from '@lingui/react';
import { MessageDescriptor } from '@lingui/core';

export const CHANGE_FEE = 'changeFee';

const changeFeesLabels: Record<AccountType, { label: MessageDescriptor }> = {
  [AccountType.RTO]: {
    label: msg`0,50%`,
  },
  [AccountType.CONSEIL]: {
    label: msg`0,50%`,
  },
  [AccountType.GESTION]: {
    label: msg`0,50%`,
  },
};

export const useChangeFeesLabel = (accountType: dataStructure.accountType.listType) => {
  const {
    _,
  } = useLingui();
  const translated = _(changeFeesLabels[accountType].label);
  return translated;
};

export const ChangeFeesLabel = (props: { accountType: dataStructure.accountType.listType }) => {
  const { accountType } = props;
  const translated = useChangeFeesLabel(accountType);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{translated}</>;
};

export const ChangeFeesLabelOnPrimaryDimension = () => {
  const accountType = ouiBase.utils.dimensions.useCurrentPrimaryDimension() as dataStructure.accountType.listType;
  return (
    <ChangeFeesLabel accountType={accountType} />
  );
};

const changeFeesLabel = (
  <Trans>
    Opérations de change devises
  </Trans>
);

export const changeFeesTopModels = (
  <>
    <TopModel name={`TableSecondaryTitleCellContent-${CHANGE_FEE}`}>
      {changeFeesLabel}
    </TopModel>
    <TopModel name={`TableBodyRowCellContent--${CHANGE_FEE}`}>
      {ChangeFeesLabelOnPrimaryDimension}
    </TopModel>
  </>
);
