/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { animated } from 'react-spring';
import { Check } from 'react-feather';
import ouiStyle from '@goldwasserexchange/oui-style';
import {
  useChecked,
  InputVariantsNames,
  InputVariantHooksArray,
  useBaseVariant,
  useCheckedVariant,
  useHasFocusVariant,
  useDisabledVariant,
  useValidationVariant,
} from '../../../../../../../OUI/Inputs';
import { useScaleEnterLeave, useVariantSpring } from '../../../../../utils';

const checkboxListInputDomListElementButtonContentStyle: ouiStyle.InStyle = {
  color: ouiStyle.Constants.Colors.inverted,
  svg: true,
  fill: true,
};
const checkboxListInputDomListElementButtonContentCss = ouiStyle.makeCss(
  checkboxListInputDomListElementButtonContentStyle,
);

const baseCheckboxListInputDomListElementButtonContentCss = ouiStyle.makeCssAndExtract(
  checkboxListInputDomListElementButtonContentStyle,
  ['color'],
);

const focusCheckboxListInputDomListElementButtonContentStyle: ouiStyle.InStyle = {
  ...checkboxListInputDomListElementButtonContentStyle,
  color: ouiStyle.Constants.Colors.accent,
};

const focusCheckboxListInputDomListElementButtonContentCss = ouiStyle.makeCssAndExtract(
  focusCheckboxListInputDomListElementButtonContentStyle,
  ['color'],
);

const errorCheckboxListInputDomListElementButtonContainerStyle: ouiStyle.InStyle = {
  ...checkboxListInputDomListElementButtonContentStyle,
  color: ouiStyle.Constants.Colors.danger,
};

const errorCheckboxListInputDomListElementButtonContentCss = ouiStyle.makeCssAndExtract(
  errorCheckboxListInputDomListElementButtonContainerStyle,
  ['color'],
);

const disabledCheckboxListInputDomListElementButtonContentStyle: ouiStyle.InStyle = {
  ...checkboxListInputDomListElementButtonContentStyle,
  color: ouiStyle.Constants.Colors.disabled,
};

const disabledCheckboxListInputDomListElementButtonContentCss = ouiStyle.makeCssAndExtract(
  disabledCheckboxListInputDomListElementButtonContentStyle,
  ['color'],
);

const checkedCheckboxListInputDomListElementButtonContentStyle: ouiStyle.InStyle = {
  ...checkboxListInputDomListElementButtonContentStyle,
  color: ouiStyle.Constants.Colors.primary,
};

const checkedCheckboxListInputDomListElementButtonContentCss = ouiStyle.makeCssAndExtract(
  checkedCheckboxListInputDomListElementButtonContentStyle,
  ['color'],
);

const checkboxListInputDomListElementButtonContentVariants: Record<
  InputVariantsNames,
  Partial<Pick<ouiStyle.Style, 'color'>>
> = {
  opened: {},
  closed: {},
  base: baseCheckboxListInputDomListElementButtonContentCss,
  hasContent: {},
  noContent: {},
  checked: checkedCheckboxListInputDomListElementButtonContentCss,
  unChecked: {},
  focus: focusCheckboxListInputDomListElementButtonContentCss,
  noFocus: {},
  untouched: {},
  valid: {},
  error: errorCheckboxListInputDomListElementButtonContentCss,
  disabled: disabledCheckboxListInputDomListElementButtonContentCss,
  enabled: {},
  last: {},
  notLast: {},
  before: {},
  inside: {},
  after: {},
  noSelection: {},
  notCorrected: {},
  correct: {},
  incorrect: {},
  correctSelected: {},
  correctNotSelected: {},
  incorrectSelected: {},
  incorrectNotSelected: {},
};

const listInputLabelContentHookVariants: InputVariantHooksArray = [
  useBaseVariant,
  useCheckedVariant,
  useHasFocusVariant,
  useValidationVariant,
  useDisabledVariant,
];

const SvgCheckboxContent = (props: { style: any }) => {
  const { style: transitionStyle } = props;
  const style = useVariantSpring(
    checkboxListInputDomListElementButtonContentVariants,
    listInputLabelContentHookVariants,
  );
  return (
    <animated.svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      css={checkboxListInputDomListElementButtonContentCss}
      style={{ ...style, ...transitionStyle }}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <Check />
    </animated.svg>
  );
};
export function CheckboxListInputDomListElementButtonContent() {
  const checked = useChecked();
  const checkedString = checked ? checked.toString() : 'unknown';
  const transition = useScaleEnterLeave(checkedString);
  return transition((style, item) => {
    if (item === 'true') {
      return <SvgCheckboxContent key={item} style={style} />;
    }

    return null;
  });
}
