import * as React from 'react';
import { dataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import ouiDom from '@goldwasserexchange/oui-dom';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans, msg } from '@lingui/macro';
import { MessageDescriptor } from '@lingui/core';
import { useLingui } from '@lingui/react';
import { Space } from '../../../../../../Space';

const LinkWithDescriptor = (props: React.PropsWithChildren<{
  hrefMessageDescriptor: MessageDescriptor,
  target?: React.HTMLAttributeAnchorTarget,
}>) => {
  const {
    hrefMessageDescriptor,
    target,
    children,
  } = props;
  const {
    _,
  } = useLingui();
  const href = _(hrefMessageDescriptor);
  return (
    <ouiDom.A.A
      href={href}
      target={target}
    >
      {children}
    </ouiDom.A.A>
  );
};

const resultTexts: Partial<Record<dataStructure.durability.results.ResultTextNames, () => ((string | JSX.Element)[] | null)>> = {
  onlineGlobalPlus1: () => [
    <Trans>
      A défaut de préférences en matière de durabilité, Goldwasser Exchange appliquera dans le cadre de son service de gestion discrétionnaire sa propre politique en matière de durabilité. Cette politique est détaillée dans
      <Space />
      <LinkWithDescriptor
        hrefMessageDescriptor={msg`https://d35udc6x0xtv0z.cloudfront.net/documents/global_plus_fr.pdf`}
        target="_blank"
      >
        la fiche technique de Global+
      </LinkWithDescriptor>
      <Space />
      sous la rubrique «Durabilité». Le client reconnait avoir pris connaissance et accepté cette politique.
    </Trans>,
  ],
  onlineGlobalPlus2: () => [
    <Trans>
      Compte tenu de la préférence (non spécifique) exprimée par le client pour les produits «durables», Goldwasser Exchange appliquera dans le cadre de son service de gestion discrétionnaire sa propre politique en matière de durabilité. Cette politique est détaillée dans
      <Space />
      <LinkWithDescriptor
        hrefMessageDescriptor={msg`https://d35udc6x0xtv0z.cloudfront.net/documents/global_plus_fr.pdf`}
        target="_blank"
      >
        la fiche technique de Global+
      </LinkWithDescriptor>
      <Space />
      sous la rubrique «Durabilité». Le client accepte reconnais avoir pris connaissance et accepté cette politique.
    </Trans>,
  ],
  onlineGlobalPlus3: () => [
    <Trans>
      En raison du manque de données disponibles sur le marché concernant la « durabilité » des entreprises et produits et en raison de l’incertitude réglementaire qui entoure certains éléments à mettre en œuvre, Goldwasser Exchange n’est pas en mesure de tenir compte des préférences spécifiques exprimées par le Client dans le cadre de son service de gestion discrétionnaire. Sauf contrordre, Goldwasser Exchange appliquera dans le cadre de ce service sa propre politique en matière de durabilité. Cette politique est détaillée dans
      <Space />
      <LinkWithDescriptor
        hrefMessageDescriptor={msg`https://d35udc6x0xtv0z.cloudfront.net/documents/global_plus_fr.pdf`}
        target="_blank"
      >
        la fiche technique de Global+
      </LinkWithDescriptor>
      <Space />
      sous la rubrique « Durabilité ». Le client reconnait avoir pris connaissance et accepté cette politique.
    </Trans>,
  ],
};

export const DurabilityResults = () => (
  <dataStructure.durability.results.ResultTexts
    resultTextsOverride={resultTexts}
  >
    <TopModel name="ResultParagraph">
      {ouiDom.P.DomPContainer}
    </TopModel>
  </dataStructure.durability.results.ResultTexts>
);
