var _a;
import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { STypeGrp } from '../../../types';
import { useLingui } from '@lingui/react';
export var financialProductsLabels = (_a = {},
    _a[STypeGrp.OBLIGATIONS] = {
        ID: STypeGrp.OBLIGATIONS,
        label: function (i18n) { return i18n._('Obligations'); },
    },
    _a[STypeGrp.OBLIGATIONS_SUB] = {
        ID: STypeGrp.OBLIGATIONS_SUB,
        label: function (i18n) { return i18n._('Obligations subordonnées'); },
    },
    _a[STypeGrp.OBLIGATIONS_PERP] = {
        ID: STypeGrp.OBLIGATIONS_PERP,
        label: function (i18n) { return i18n._('Obligations perpétuelles'); },
    },
    _a[STypeGrp.TRACKERS] = {
        ID: STypeGrp.TRACKERS,
        label: function (i18n) { return i18n._('Trackers'); },
    },
    _a[STypeGrp.ACTIONS] = {
        ID: STypeGrp.ACTIONS,
        label: function (i18n) { return i18n._('Actions'); },
    },
    _a[STypeGrp.FONDS] = {
        ID: STypeGrp.FONDS,
        label: function (i18n) { return i18n._('Fonds d\'investissement'); },
    },
    _a[STypeGrp.TRACKERS_MAT] = {
        ID: STypeGrp.TRACKERS_MAT,
        label: function (i18n) { return i18n._('Trackers sur les matières premières'); },
    },
    _a);
export var useLabel = function (financialProduct) {
    var i18n = useLingui().i18n;
    var translated = financialProductsLabels[financialProduct].label(i18n);
    return translated;
};
export var Label = function (props) {
    var financialProduct = props.financialProduct;
    var translated = useLabel(financialProduct);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return React.createElement(React.Fragment, null, translated);
};
export var LabelOnPrimaryDimension = function () {
    var financialProduct = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
    return (React.createElement(Label, { financialProduct: financialProduct }));
};
