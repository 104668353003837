import { isFuture, isPast } from 'date-fns';
import { parseActorDate } from '../../../shared/utils/parseDates';
import { BondList } from '../../bonds/listOrFavorites/type';
import { BondResponse } from '../../bonds/bond/type';
import { BondSameIssuerResponse } from '../../bonds/sameIssuer/type';

export const isPromoFn = (response: BondResponse | BondList | BondSameIssuerResponse | undefined): boolean => {
  if (!response) {
    return false;
  }
  const isPromo = response['S_ACC.YN_PROMO'];
  const promoStart = response['S_ACC.DPROMO_DE'] ? parseActorDate(response['S_ACC.DPROMO_DE']) : undefined;
  const promoEnd = response['S_ACC.DPROMO_A'] ? parseActorDate(response['S_ACC.DPROMO_A']) : undefined;
  return isPromo && promoStart && isPast(promoStart) && promoEnd && isFuture(promoEnd);
};
