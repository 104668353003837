import ouiBase from '@goldwasserexchange/oui';
import { useAccountOrderFieldApiByOpbFordId } from '../api';

export const useAccountOrdersArDocDescription = () => {
  const opbFordDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useAccountOrderFieldApiByOpbFordId({
    opbFordDId,
    field: 'AR_DOC.DESCRIPTION',
  });
  return result;
};
