import { GetAccountsPositionsHistoryResponse } from '@goldwasserexchange/actor/rest-services';
import { getFileName } from './getFileName';
import { AccountDocument } from '../../../accounts/documents/type';
import { AccountHistory } from '../../../accounts/history/type';
import { AccountsOrder } from '../../../accounts';

export const getModalName = (data?: AccountHistory | AccountsOrder | AccountDocument | GetAccountsPositionsHistoryResponse) => {
  const fileName = getFileName(data);
  return `modal-document-${fileName}`;
};
