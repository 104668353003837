import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { DocumentModal } from './content';
import Accounts from '../../../../accounts';
import {
  FileIconModalButton,
} from './fileIconModalButton';

export const ArDocModalWithToggle = (props: {
  service: Accounts.History | Accounts.Documents | Accounts.PositionsHistory | Accounts.Orders,
  hasFileName?: boolean,
  isLoading: boolean,
  arDocId?: number,
  size?: string | number | undefined,
  transform?: ouiStyle.InStyle['transform'],
}) => {
  const {
    service,
    hasFileName,
    isLoading,
    arDocId,
    size,
    transform,
  } = props;
  return hasFileName || isLoading ? (
    <>
      <FileIconModalButton
        hasFileName={hasFileName}
        service={service}
        size={size}
        transform={transform}
      />
      {hasFileName ? (
        <DocumentModal
          service={service}
          arDocId={arDocId}
        />
      ) : null}
    </>
  ) : null;
};
