var _a;
import * as React from 'react';
import { STypeGrp } from '../../types';
import { useLingui } from '@lingui/react';
export var useLabel = function () {
    var i18n = useLingui().i18n;
    var translated = i18n._('Expérience');
    return translated;
};
export var Label = function () {
    var translated = useLabel();
    return translated;
};
export var labels = (_a = {},
    _a[STypeGrp.OBLIGATIONS] = function (i18n) { return i18n._('Avez-vous effectué au cours des deux dernières années au moins trois transactions d\'achat ou de vente d\'obligations pour un montant d\'au moins 5.000 € par transaction ?'); },
    _a[STypeGrp.OBLIGATIONS_SUB] = function (i18n) { return i18n._('Avez-vous effectué au cours des deux dernières années au moins trois transactions d\'achat ou de vente d\'obligations subordonnées pour un montant d\'au moins 5.000 € par transaction ?'); },
    _a[STypeGrp.OBLIGATIONS_PERP] = function (i18n) { return i18n._('Avez-vous effectué au cours des deux dernières années au moins trois transactions d\'achat ou de vente d\'obligations perpétuelles pour un montant d\'au moins 5.000 € par transaction ?'); },
    _a[STypeGrp.ACTIONS] = function (i18n) { return i18n._('Avez-vous effectué au cours des deux dernières années au moins trois transactions d\'achat ou de vente d\'actions pour un montant d\'au moins 5.000 € par transaction ?'); },
    _a[STypeGrp.FONDS] = function (i18n) { return i18n._('Avez-vous effectué au cours des deux dernières années au moins trois transactions d\'achat ou de vente de fonds pour un montant d\'au moins 5.000 € par transaction ?'); },
    _a[STypeGrp.TRACKERS] = function (i18n) { return i18n._('Avez-vous effectué au cours des deux dernières années au moins trois transactions d\'achat ou de vente de trackers pour un montant d\'au moins 5.000 € par transaction ?'); },
    _a[STypeGrp.TRACKERS_MAT] = function (i18n) { return i18n._('Avez-vous effectué au cours des deux dernières années au moins trois transactions d\'achat ou de vente de trackers sur matières premières pour un montant d\'au moins 5.000 € par transaction ?'); },
    _a);
export var useQuestionLabel = function (financialProduct) {
    var i18n = useLingui().i18n;
    var translated = labels[financialProduct](i18n);
    return translated;
};
export var QuestionLabel = function (props) {
    var financialProduct = props.financialProduct;
    var translated = useQuestionLabel(financialProduct);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return React.createElement(React.Fragment, null, translated);
};
