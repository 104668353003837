import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { Accounts } from '../../../enum';
import { ArDocModalWithToggle } from '../../../../shared';
import { useAccountOrdersArDocHasFileName, useAccountOrdersArDocId } from '../../hooks';

export const AccountsOrdersArDocFileName = (props: {
  size?: string | number | undefined,
  transform?: ouiStyle.InStyle['transform'],
}) => {
  const {
    size,
    transform,
  } = props;
  const {
    data: hasFileName,
    isLoading,
  } = useAccountOrdersArDocHasFileName();
  const { data: arDocId } = useAccountOrdersArDocId();
  return (
    <ArDocModalWithToggle
      service={Accounts.Orders}
      hasFileName={hasFileName}
      isLoading={isLoading}
      arDocId={arDocId}
      size={size}
      transform={transform}
    />
  );
};
