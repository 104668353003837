import { questionnaireDependenciesByStatus } from './data';
import { financialProductsLabels } from '../../../financialProducts/fields/labels';
import { useLingui } from '@lingui/react';
export var getFinancialProductDependenciesByStatus = function (financialProduct) { return questionnaireDependenciesByStatus[financialProduct] || []; };
export var useProductSelectionSort = function (list) {
    var i18n = useLingui().i18n;
    return list.sort(function (prev, next) {
        if ((getFinancialProductDependenciesByStatus(prev) || []).includes(next)) {
            return 1;
        }
        if ((getFinancialProductDependenciesByStatus(next) || []).includes(prev)) {
            return -1;
        }
        var prevLabel = financialProductsLabels[prev].label(i18n);
        var nextLabel = financialProductsLabels[next].label(i18n);
        return prevLabel.localeCompare(nextLabel);
    });
};
