/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import voca from 'voca';
import { identity, isNil } from 'ramda';
import { useHookstate, StateMethods } from '@hookstate/core';
import { useMemo } from 'react';
import { MessageDescriptor } from '@lingui/core';
import { useLingui } from '@lingui/react';

const fieldValueTextStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.block,
  margin: 'none',
  widthLines: 'full',
  textOverflow: 'ellipsis',
  whiteSpace: ouiStyle.Constants.WhiteSpaceProperty.noWrap,
};

export type FieldValueTextProps = {
  fieldState: StateMethods<any>,
  defaultValue?: string,
  transformValueFn?: (fieldValue, _: (descriptor: MessageDescriptor) => string) => string | JSX.Element | null,
  style?: any,
  lines?: ouiStyle.InStyle['lines'],
  whiteSpace?: ouiStyle.InStyle['whiteSpace'],
}

export const isString = (str: any): str is string => typeof str === 'string';

export const FieldValueText = (props: FieldValueTextProps): JSX.Element => {
  const {
    fieldState,
    defaultValue = null,
    transformValueFn = identity,
    lines = fieldValueTextStyle.lines,
    whiteSpace = fieldValueTextStyle.whiteSpace,
    style,
  } = props;
  const {
    _,
  } = useLingui();
  const value = useHookstate(fieldState).get();
  const css = useMemo(() => ({
    ...ouiStyle.makeCss({
      ...fieldValueTextStyle,
      lines,
      whiteSpace,
    }),
    marginBlockStart: 0,
    marginBlockEnd: 0,
    marginInlineStart: 0,
    marginInlineEnd: 0,
  }), [lines]);
  return (
    <p
      css={css}
      style={style}
    >
      {isNil(value) || (isString(value) ? voca.isEmpty(value) : false) ? defaultValue : transformValueFn(value, _)}
    </p>
  );
};
