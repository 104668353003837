import { AccountType } from '@goldwasserexchange/oblis-frontend-utils';
import { MessageDescriptor } from '@lingui/core';
import { goldTitle } from './gold';
import { premiumTitle } from './premium';
import { globalPlusTitle } from './globalPlus';

export const accountTypeTitles: Record<AccountType, (_: (descriptor: MessageDescriptor) => string) => string> = {
  [AccountType.RTO]: goldTitle,
  [AccountType.CONSEIL]: premiumTitle,
  [AccountType.GESTION]: globalPlusTitle,
};
