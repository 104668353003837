import {
  SearchPlaceIndexForSuggestionsCommand,
} from '@aws-sdk/client-location';
import { getLocationClient } from './getLocationClient';

export const trySearchPlaceIndexForSuggestionsFetcher = async (props: {
  placeIndexName: string,
  searchString: string,
  locale: string,
}) => {
  const {
    placeIndexName,
    searchString,
    locale,
  } = props;
  const locationClient = await getLocationClient();
  const command = new SearchPlaceIndexForSuggestionsCommand({
    Text: searchString,
    IndexName: placeIndexName,
    Language: locale,
    // Prefer results that are closer to Brussels, Belgium
    BiasPosition: [4.3572, 50.8476],
  });
  try {
    const response = await locationClient.send(command);
    return response.Results;
  } catch (err) {
    return undefined;
  }
};
