/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import { Trans, msg } from '@lingui/macro';
import { useAtomValue } from 'jotai';
import React from 'react';
import { useLingui } from '@lingui/react';
import { MediaContext } from '../MediaContext';
import { GEPhoneNumber } from '../PhoneNumber';
import { ValidSections, pathnameUrlLanguageAtom } from '../../history';
import { CookiePanelLink } from './cookiePanelLink';

const footerStyle: ouiStyle.InStyle = {
  fontSize: 'xm',
  color: ouiStyle.Constants.Colors.inverted,
  paddingVertical: 'double',
  paddingHorizontal: 'simple',
  backgroundImage: `linear-gradient(180deg, ${ouiStyle.Constants.Colors.primaryDarker}, ${ouiStyle.Constants.Colors.primary})`,
};

const footerCss = ouiStyle.makeCss(footerStyle);

const containerStyle: ouiStyle.InStyle = {
  container: ouiStyle.Constants.Container.md,
  paddingVertical: 'double',
};

const containerCss = ouiStyle.makeCss(containerStyle);

const contactUsStyle: ouiStyle.InStyleWithMediaQueries = {
  display: ouiStyle.Constants.DisplayProp.flex,
  flexDirection: [ouiStyle.Constants.InFlexDirectionProp.col, undefined, undefined, ouiStyle.Constants.InFlexDirectionProp.row, undefined, undefined],
  justifyContent: ouiStyle.Constants.JustifyContentProp.center,
  paddingBottom: ['simple', undefined, undefined, 'none', undefined, undefined],
};

const contactUsCss = ouiStyle.makeCss(contactUsStyle);

const separatorStyle: ouiStyle.InStyle = {
  borderBottomWidth: 'simple',
  borderBottomStyle: 'solid',
  borderBottomColor: ouiStyle.Constants.Colors.fadeOnPrimary,
};

const separatorCss = ouiStyle.makeCss(separatorStyle);

const Separator = () => (
  <ouiDom.P.P
    paddingHorizontal="simple"
    paddingVertical="none"
    color={ouiStyle.Constants.Colors.fadeDarker}
  >
    -
  </ouiDom.P.P>
);

const linkBoxStyle: ouiStyle.InStyleWithMediaQueries = {
  display: ouiStyle.Constants.DisplayProp.flex,
  container: ouiStyle.Constants.Container.md,
  justifyContent: ouiStyle.Constants.JustifyContentProp.center,
  alignItems: ouiStyle.Constants.AlignItemsProp.center,
  flexDirection: [ouiStyle.Constants.InFlexDirectionProp.col, undefined, undefined, ouiStyle.Constants.InFlexDirectionProp.row, undefined, undefined],
  flexWrap: 'wrap',
  paddingVertical: 'simple',
};

const linkBoxCss = ouiStyle.makeCss(linkBoxStyle);

const hrefMessageDescriptor = msg`https://d35udc6x0xtv0z.cloudfront.net/documents/ConditionsUsage.pdf`;

export const Footer = () => {
  const {
    _,
  } = useLingui();
  const { upMd } = React.useContext(MediaContext);
  const language = useAtomValue(pathnameUrlLanguageAtom);
  const toContact = `/${language}/${ValidSections.CONTACT}`;
  const toLegal = `/${language}/${ValidSections.LEGAL}`;
  const href = _(hrefMessageDescriptor);
  return (
    <footer
      css={footerCss}
    >
      <div
        css={containerCss}
      >
        <section
          css={ouiStyle.mediaQuery(contactUsCss)}
        >
          <ouiDom.A.A
            to={toContact}
            color={footerStyle.color}
            fontSize={footerStyle.fontSize}
            paddingRight={['simple', undefined, undefined, 'double', undefined, undefined]}
            paddingLeft={['simple', undefined, undefined, 'none', undefined, undefined]}
            textAlign="center"
          >
            <Trans>
              Contactez-nous
            </Trans>
          </ouiDom.A.A>
          <GEPhoneNumber
            color={footerStyle.color}
            fontSize={footerStyle.fontSize}
            paddingLeft={['simple', undefined, undefined, 'double', undefined, undefined]}
            paddingRight={['simple', undefined, undefined, 'none', undefined, undefined]}
            textAlign="center"
          />
        </section>
        <hr
          css={separatorCss}
        />
        <section
          css={ouiStyle.mediaQuery(linkBoxCss)}
        >
          <ouiDom.A.A
            href={href}
            target="_blank"
            color={ouiStyle.Constants.Colors.fadeDarker}
            paddingTop="none"
            paddingBottom={['half', undefined, undefined, 'none', undefined, undefined]}
            fontWeight={ouiStyle.Constants.FontWeightProp.bold}
            textTransform="uppercase"
            textAlign="center"
          >
            <Trans>
              Conditions d'usage du site
            </Trans>
          </ouiDom.A.A>
          {
            upMd
              ? <Separator />
              : null
          }
          <ouiDom.A.A
            to={toLegal}
            color={ouiStyle.Constants.Colors.fadeDarker}
            paddingVertical={['half', undefined, undefined, 'none', undefined, undefined]}
            fontWeight={ouiStyle.Constants.FontWeightProp.bold}
            textTransform="uppercase"
            textAlign="center"
          >
            <Trans>
              Informations légales
            </Trans>
          </ouiDom.A.A>
          {
            upMd
              ? <Separator />
              : null
          }
          <CookiePanelLink />
        </section>

      </div>
    </footer>
  );
};
