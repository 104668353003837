import * as React from 'react';
import { TopModelMerge, TopModelNameTransformProvider } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import { pDefaultDomTopModelsObject } from './topmodels';
export var P = function (props) {
    var display = props.display, container = props.container, color = props.color, backgroundColor = props.backgroundColor, lineFactor = props.lineFactor, widthLines = props.widthLines, minusWidth = props.minusWidth, textAlign = props.textAlign, fontSize = props.fontSize, fontWeight = props.fontWeight, whiteSpace = props.whiteSpace, textOverflow = props.textOverflow, padding = props.padding, paddingHorizontal = props.paddingHorizontal, paddingVertical = props.paddingVertical, paddingTop = props.paddingTop, paddingLeft = props.paddingLeft, paddingBottom = props.paddingBottom, paddingRight = props.paddingRight, iconContainer = props.iconContainer, baselinePadding = props.baselinePadding, childPaddingTop = props.childPaddingTop, childPaddingLeft = props.childPaddingLeft, childPaddingBottom = props.childPaddingBottom, childPaddingRight = props.childPaddingRight, minLines = props.minLines, minIcon = props.minIcon, minFontSize = props.minFontSize, minPaddingTop = props.minPaddingTop, minPaddingBottom = props.minPaddingBottom, minChildPaddingTop = props.minChildPaddingTop, minChildPaddingBottom = props.minChildPaddingBottom, minParentBorderTopWidth = props.minParentBorderTopWidth, minParentBorderBottomWidth = props.minParentBorderBottomWidth, textTransform = props.textTransform, overflow = props.overflow, alignSelf = props.alignSelf, lineClamp = props.lineClamp, children = props.children;
    return (React.createElement(TopModelNameTransformProvider, { transformFn: ouiBase.P.pPrefixer },
        React.createElement(TopModelMerge, { topModels: ouiBase.P.pDefaultTopModelsObject },
            React.createElement(TopModelMerge, { topModels: pDefaultDomTopModelsObject },
                React.createElement(ouiBase.P.PStructure, { display: display, container: container, backgroundColor: backgroundColor, lineFactor: lineFactor, widthLines: widthLines, minusWidth: minusWidth, textAlign: textAlign, fontSize: fontSize, fontWeight: fontWeight, whiteSpace: whiteSpace, textOverflow: textOverflow, padding: padding, paddingHorizontal: paddingHorizontal, paddingVertical: paddingVertical, paddingTop: paddingTop, paddingLeft: paddingLeft, paddingBottom: paddingBottom, paddingRight: paddingRight, iconContainer: iconContainer, baselinePadding: baselinePadding, childPaddingTop: childPaddingTop, childPaddingLeft: childPaddingLeft, childPaddingBottom: childPaddingBottom, childPaddingRight: childPaddingRight, color: color, minLines: minLines, minIcon: minIcon, minFontSize: minFontSize, minPaddingTop: minPaddingTop, minPaddingBottom: minPaddingBottom, minChildPaddingTop: minChildPaddingTop, minChildPaddingBottom: minChildPaddingBottom, minParentBorderTopWidth: minParentBorderTopWidth, minParentBorderBottomWidth: minParentBorderBottomWidth, textTransform: textTransform, overflow: overflow, alignSelf: alignSelf, lineClamp: lineClamp }, children)))));
};
