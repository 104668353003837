import { MessageDescriptor } from '@lingui/core';
import { Trans, msg } from '@lingui/macro';
import * as React from 'react';

export const goldTwoLineTitleContent = (
  <Trans>
    Compte
    <br />
    Gold
  </Trans>
);

export const goldTitle = (_: (descriptor: MessageDescriptor) => string) => _(msg`compte Gold`);
