import { useCallback } from 'react';
import { Order } from '@goldwasserexchange/oblis-frontend-utils';
import { transformAskOrMidPriceFn, transformBidOrMidPriceFn } from '../../../../bonds';
import { useSecuritiesSecurityTransformApi } from '../api';

const makeMarketPrice = (direction: Order['direction']) => {
  if (direction === 'SELL') {
    return transformBidOrMidPriceFn;
  }
  return transformAskOrMidPriceFn;
};

export const useSecuritiesSecurityMarketPrice = (direction: Order['direction']) => {
  const tranformFn = useCallback(makeMarketPrice(direction), [direction]);
  const response = useSecuritiesSecurityTransformApi(tranformFn);
  return response;
};
