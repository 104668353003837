import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';
import 'sanitize.css/reduce-motion.css';
import 'sanitize.css/system-ui.css';
import 'sanitize.css/ui-monospace.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { init } from './ga';

// Initialize GA
init();

const container = document.getElementById('root');

const root = createRoot(container!);
// React render
root.render(
  <App />,
);
