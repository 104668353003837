import * as React from 'react';
import Accounts from '../../../../../accounts';
import { AccountHistoryDocumentModalContainer, AccountPositionsHistoryDocumentModalContainer } from './historyContainer';
import { AccountDocumentDocumentModalContainer } from './documentContainer';
import { AccountOrdersDocumentModalContainer } from './ordersContainer';

export const DocumentModalContainer = (props: React.PropsWithChildren<{
  service: Accounts.History | Accounts.Documents | Accounts.PositionsHistory | Accounts.Orders,
}>) => {
  const {
    service,
    children,
  } = props;
  if (service === Accounts.History) {
    return (
      <AccountHistoryDocumentModalContainer>
        {children}
      </AccountHistoryDocumentModalContainer>
    );
  }
  if (service === Accounts.Documents) {
    return (
      <AccountDocumentDocumentModalContainer>
        {children}
      </AccountDocumentDocumentModalContainer>
    );
  }
  if (service === Accounts.PositionsHistory) {
    return (
      <AccountPositionsHistoryDocumentModalContainer>
        {children}
      </AccountPositionsHistoryDocumentModalContainer>
    );
  }
  if (service === Accounts.Orders) {
    return (
      <AccountOrdersDocumentModalContainer>
        {children}
      </AccountOrdersDocumentModalContainer>
    );
  }
  return null;
};
