import * as React from 'react';
import { Modal } from '../../../../../../../../Components/Modal';
import { useAccountOrdersArDocModalName } from '../../../../../accounts';

export const AccountOrdersDocumentModalContainer = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const { data } = useAccountOrdersArDocModalName();
  return data ? (
    <Modal modal={data}>
      {children}
    </Modal>
  ) : null;
};
