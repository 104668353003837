import ouiStyle from '@goldwasserexchange/oui-style';
import chroma from 'chroma-js';

export type CreditRating =
  | 'AAA'
  | 'AA+'
  | 'AA'
  | 'AA-'
  | 'A+'
  | 'A'
  | 'A-'
  | 'BBB+'
  | 'BBB'
  | 'BBB-'
  | 'BB+'
  | 'BB'
  | 'BB-'
  | 'B+'
  | 'B'
  | 'B-'
  | 'CCC+'
  | 'CCC'
  | 'CCC-'
  | 'CC'
  | 'C'
  | 'D'
  | 'NR'

export const creditRatings: CreditRating[] = [
  'AAA',
  'AA+',
  'AA',
  'AA-',
  'A+',
  'A',
  'A-',
  'BBB+',
  'BBB',
  'BBB-',
  'BB+',
  'BB',
  'BB-',
  'B+',
  'B',
  'B-',
  'CCC+',
  'CCC',
  'CCC-',
  'CC',
  'C',
  'D',
  'NR',
];

export const investmentGradeCreditRatings: CreditRating[] = [
  'BBB-',
  'BBB',
  'BBB+',
  'A-',
  'A',
  'A+',
  'AA-',
  'AA',
  'AA+',
  'AAA',
];

export const isInvesmentGrade = (creditRating: CreditRating) => investmentGradeCreditRatings.includes(creditRating);

export const investmentGradeScale = chroma.scale(['#3597D6', '044982']).domain([0, investmentGradeCreditRatings.length - 1]).correctLightness();

export const highYieldCreditRatings: CreditRating[] = [
  'D',
  'C',
  'CC',
  'CCC-',
  'CCC',
  'CCC+',
  'B-',
  'B',
  'B+',
  'BB-',
  'BB',
  'BB+',
];

export const isHighYield = (creditRating: CreditRating) => highYieldCreditRatings.includes(creditRating);

export const highYieldScale = chroma.scale(['#FB8928', 'FBEB60']).domain([0, highYieldCreditRatings.length - 1]).correctLightness();

export const creditRatingColorScales: Record<'investmentGrade' | 'highYield', chroma.Scale<chroma.Color>> = {
  investmentGrade: investmentGradeScale,
  highYield: highYieldScale,
};

export const getColorFromRating = (creditRating: CreditRating): ouiStyle.Constants.Colors => {
  if (isInvesmentGrade(creditRating)) {
    return investmentGradeScale(investmentGradeCreditRatings.indexOf(creditRating)).hex() as ouiStyle.Constants.Colors;
  }
  if (isHighYield(creditRating)) {
    return highYieldScale(highYieldCreditRatings.indexOf(creditRating)).hex() as ouiStyle.Constants.Colors;
  }
  return ouiStyle.Constants.Colors.inverted;
};

export const getOppositeColorFromRating = (creditRating: CreditRating): ouiStyle.Constants.Colors => {
  const backgroundColor = getColorFromRating(creditRating);
  return chroma.deltaE(backgroundColor, ouiStyle.Constants.Colors.accent) > 50
    ? ouiStyle.Constants.Colors.accent
    : ouiStyle.Constants.Colors.inverted;
};

export const creditRatingsCategories: Record<'investmentGrade' | 'highYield', CreditRating[]> = {
  investmentGrade: investmentGradeCreditRatings,
  highYield: highYieldCreditRatings,
};
