import {
  differenceInMilliseconds,
  format as dateFnsFormat,
  Locale,
} from 'date-fns';
import { isNil } from 'ramda';
import { useCallback, useMemo } from 'react';
import { MessageDescriptor } from '@lingui/core';
import { useLingui } from '@lingui/react';
import { parseActorDate } from '../../../../../shared/utils/parseDates';
import { useFilterByCCurrencyCode } from '../../../../shared';
import { BondSameIssuerResponse } from '../../type';
import { BondSameIssuerFilterPredicate, useBondsSameIssuerTransformApi } from '../api';
import { getPolynomialRegression } from '../../../../../../../Components/polynomialRegression';
import { useNumberFormatter } from '../../../../../../../Components/NumberDisplay';
import { useGetDateFnsLocale } from '../../../../../../../Components/getDateLocale';
import { STypeGrpIds } from '../../../../../shared/utils/sTypeGrpIds';
import { DataPoint, OutputDataPoint } from '../../../../../../../Components/polynomialRegression/types';
import { makeGetSubordinationRank } from '../../../bond/utils';
import { useCognitoUserValidAccounts } from '../../../../../../../Components/Sections/online/selectedAccount';

type GraphElement = DataPoint & {
  id: number,
  title: string,
  subordinationRank?: string,
  oblEndYear: string,
};

const makeTransformFunction = (props: {
  intRateFormatter: (value: number) => string,
  locale: Locale,
  _: (descriptor: MessageDescriptor) => string,
}) => (securityBondSameIssuer: BondSameIssuerResponse[] | undefined): (OutputDataPoint & { title: string, id: number})[] => {
  if (isNil(securityBondSameIssuer) || securityBondSameIssuer.length < 4) {
    return [];
  }
  const { intRateFormatter, locale, _ } = props;

  const graphElements: GraphElement[] = [];
  for (const value of securityBondSameIssuer) {
    if (!value['S_ISSUER.NAME_1'] || !value['S_ACC.INT_RATE'] || !value['S_ACC.OBL_END_DATE']) {
      continue;
    }

    const cCurrencyCode = value['C_CURRENCY.CODE'];
    const sIssuerName1 = value['S_ISSUER.NAME_1'];
    const sAccIntRate = intRateFormatter(value['S_ACC.INT_RATE']);
    const sAccOblEndDate = dateFnsFormat(parseActorDate(value['S_ACC.OBL_END_DATE']), 'dd/MM/yyyy', { locale });
    const oblEndYear = dateFnsFormat(parseActorDate(value['S_ACC.OBL_END_DATE']), 'yyyy', { locale });
    const now = new Date();
    const sTypeGrpId = value['S_TYPE_GRP.ID'];
    const subordinationRank = makeGetSubordinationRank(_)(sTypeGrpId);
    graphElements.push({
      id: value['S_ACC.ID'],
      title: `${cCurrencyCode} ${sIssuerName1} ${sAccIntRate} ${sAccOblEndDate}`,
      subordinationRank,
      x: differenceInMilliseconds(parseActorDate(value['S_ACC.OBL_END_DATE']), now),
      y: value['S_MARKET.YIELD'],
      oblEndYear,
    });
  }
  return getPolynomialRegression(graphElements as DataPoint[], 5) as (OutputDataPoint & { title: string, id: number})[];
};

const intRateFormat = {
  format: {
    style: 'percent' as const,
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  },
  divider: 100,
};

const makeFilterByCCurrencyAndSTypeGrpId = (currencyFilterPredicate: BondSameIssuerFilterPredicate): BondSameIssuerFilterPredicate => (bondSameIssuer) => {
  const isCurrency = currencyFilterPredicate(bondSameIssuer);
  if (!isCurrency) {
    return false;
  }
  if (bondSameIssuer['S_MARKET.YIELD'] == null) {
    return false;
  }
  const sTypeGrpId = bondSameIssuer['S_TYPE_GRP.ID'];
  return sTypeGrpId !== STypeGrpIds.OBLIGATIONS_PERP;
};

const oneYearInMiliseconds = 1000 * 60 * 60 * 24 * 365;

const loadingData: (OutputDataPoint & { title: string, id: number})[] = getPolynomialRegression([
  {
    x: oneYearInMiliseconds,
    y: 2,
    title: '',
    id: '',
    oblEndYear: '',
  },
  {
    x: oneYearInMiliseconds * 5,
    y: 3,
    title: '',
    id: '',
    oblEndYear: '',
  },
  {
    x: oneYearInMiliseconds * 10,
    y: 6,
    title: '',
    id: '',
    oblEndYear: '',
  },
  {
    x: oneYearInMiliseconds * 20,
    y: 16,
    title: '',
    id: '',
    oblEndYear: '',
  },
] as unknown as DataPoint[], 5) as (OutputDataPoint & { title: string, id: number})[];

export const useBondsSameIssuerYieldMaturityGraph = (currencyCode: BondSameIssuerResponse['C_CURRENCY.CODE'], run?: boolean) => {
  const intRateFormatter = useNumberFormatter(intRateFormat);
  const locale = useGetDateFnsLocale();
  const {
    _,
  } = useLingui();
  const transformFunction = useCallback(makeTransformFunction({ intRateFormatter, locale, _ }), [
    intRateFormatter,
    locale,
    _,
  ]);
  const currencyFilterPredicate = useFilterByCCurrencyCode(currencyCode);
  const filterPredicate = useCallback(makeFilterByCCurrencyAndSTypeGrpId(currencyFilterPredicate), [currencyFilterPredicate]);
  const response = useBondsSameIssuerTransformApi({
    filterPredicate,
    transformFunction,
    run,
  });
  const accounts = useCognitoUserValidAccounts();
  const withLoading = useMemo(
    () => {
      if (response.isLoading && accounts.length === 0) {
        return {
          ...response,
          data: loadingData,
        };
      }
      return response;
    },
    [response, accounts.length],
  );
  return withLoading;
};
