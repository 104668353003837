import { MessageDescriptor } from '@lingui/core';
import { Trans, msg } from '@lingui/macro';
import * as React from 'react';

export const premiumTwoLineTitleContent = (
  <Trans>
    Compte
    <br />
    Premium
  </Trans>
);

export const premiumTitle = (_: (descriptor: MessageDescriptor) => string) => _(msg`compte Premium`);
