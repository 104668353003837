import ouiBase from '@goldwasserexchange/oui';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useCallback } from 'react';
import { useAccountOrderFieldTransfomApiByOpbFordId } from '../api';
import { AccountsOrder } from '../../type';

export const mcOpbLimiteMessageDescriptions = {
  5305: msg`Marché`,
  5302: msg`Limite`,
};

const useTranslateMcOpbLimite = () => {
  const {
    _,
  } = useLingui();
  const translate = useCallback((limiteId: AccountsOrder['OPB_FORD.MC_OPB_LIMITE'] | undefined) => {
    if (limiteId == null) {
      return undefined;
    }
    const message = mcOpbLimiteMessageDescriptions[limiteId];
    return message ? _(message) : undefined;
  }, [_]);
  return translate;
};

export const useAccountOrderOpbFordMcOpbLimite = () => {
  const opbFordDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const transformFunction = useTranslateMcOpbLimite();
  const response = useAccountOrderFieldTransfomApiByOpbFordId({
    opbFordDId,
    field: 'OPB_FORD.MC_OPB_LIMITE',
    transformFunction,
  });
  return response;
};
