import { times } from 'ramda';
import { AccountDocument } from '../../type';
import { useAccountDocumentsTransformApi } from '../api';
import memoizee from 'memoizee';

const getLength = (documents: AccountDocument[] | undefined) => {
  if (!documents) {
    return 0;
  }
  const {
    TOTAL_ROWS,
  } = documents[0] ?? { TOTAL_ROWS: 0 };
  return TOTAL_ROWS;
};

export const useAccountDocumentsLength = () => {
  const response = useAccountDocumentsTransformApi({
    transformFunction: getLength,
  });
  return response;
};

const PAGE_SIZE = 20;

const getPageQuantity = (accountDocuments: AccountDocument[] | undefined) => {
  const length = getLength(accountDocuments);
  return Math.ceil(length / PAGE_SIZE);
};

export const useAccountDocumentsQuantity = () => {
  const response = useAccountDocumentsTransformApi({
    transformFunction: getPageQuantity,
  });
  return response;
};

const makePages = (pageQuantity: number) => times((i) => i, pageQuantity);

export const memoizedMakePages = memoizee(makePages);

const getPages = (accountDocuments: AccountDocument[] | undefined) => {
  const pageQuantity = getPageQuantity(accountDocuments);
  return memoizedMakePages(pageQuantity);
};

export const useAccountDocumentsPages = () => {
  const response = useAccountDocumentsTransformApi({
    transformFunction: getPages,
  });
  return response;
};
