import ouiBase from '@goldwasserexchange/oui';
import { useAccountOrderTransformApiByOpbFordId } from '../api';
import { getId } from '../../../../shared';

export const useAccountOrdersArDocId = () => {
  const opbFordDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useAccountOrderTransformApiByOpbFordId({
    opbFordDId,
    transformFunction: getId,
  });
  return response;
};
