import { Locale, format } from 'date-fns';
import { fr, nl } from 'date-fns/locale';
import { useLinguiLocale } from '../useLinguiLocale';

const locales = {
  fr,
  nl,
};

export const useGetDateFnsLocale = (): Locale => {
  const locale = useLinguiLocale();
  return locales[locale] ?? locales.fr;
};

export const getDateFnsFormatter = (locale: Locale) => (date: Parameters<typeof format>[0], formatStr: Parameters<typeof format>[1]) => format(date, formatStr, {
  locale,
});

export const useGetDateFnsFormatter = () => {
  const locale = useLinguiLocale();
  const formatter = getDateFnsFormatter(locales[locale] ?? locales.fr);
  return formatter;
};
