import * as React from 'react';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';
import { useSecuritiesSecurityFullName } from '../../hooks';

export const SecuritiesSecurityFullname = (props: {
  withIsin?: boolean,
}) => {
  const {
    withIsin = false,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useSecuritiesSecurityFullName(props);
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      loadingValue={withIsin === false ? '--- -------- ---- ------ -- --------' : '--- -------- ---- ------ -- -------- (------------)'}
    />
  );
};
