import * as React from 'react';
import { useLingui } from '@lingui/react';
import { relationDescriptionMessages, usePersonAccountstNatureLrpOrTProcTypeShortcut } from '../../hooks';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';

export const PersonsAccountsRelationDescription = () => {
  const {
    data,
    isLoading,
    error,
  } = usePersonAccountstNatureLrpOrTProcTypeShortcut();
  const { _ } = useLingui();
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      loadingValue={_(relationDescriptionMessages.UNKNOWN)}
    />
  );
};
