import { atom } from 'jotai';
import { locationSearchParsedAtom } from '../../../../atoms';

export const accountDocumentPageAtom = atom((get) => {
  const parsedSearch = get(locationSearchParsedAtom);
  const {
    page: paramPage = '1',
  } = parsedSearch;
  const page = paramPage && !Array.isArray(paramPage) ? parseInt(paramPage, 10) : 0;
  if (Number.isNaN(page)) {
    return 0;
  }
  return page;
});
