import * as React from 'react';
import { isAfter, isBefore, parseISO } from 'date-fns';
import { BannerContainer, BannerContent } from './components';
import { useLocalizedFirstSanityBanner } from '../../sanity';
import { ErrorBoundary } from '../ErrorBoundary';

export const Banner = () => {
  const {
    data,
  } = useLocalizedFirstSanityBanner();
  if (data == null || (data.from != null && isAfter(parseISO(data.from), Date.now())) || isBefore(parseISO(data.to), Date.now())) {
    return null;
  }
  return (
    <ErrorBoundary renderNull>
      <BannerContainer
        color={data.color}
        backgroundColor={data.backgroundColor}
      >
        <BannerContent
          content={data.content}
        />
      </BannerContainer>
    </ErrorBoundary>
  );
};
