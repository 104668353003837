/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import { animated } from 'react-spring';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import {
  InputVariantsNames,
  useRadioHtmlId,
  useBaseVariant,
  useDisabledVariant,
  useValidationVariant,
  useHasFocusVariant,
  InputVariantHooksArray,
} from '../../../../../../../OUI/Inputs';
import {
  focusLabelStyleAdditionalProperties,
  noFocusLabelStyleAdditionalProperties,
  errorLabelStyleAdditionalProperties,
  disabledLabelStyleAdditionalProperties,
} from '../../../../shared';
import { useVariantSpring } from '../../../../../utils';

export const defaultRadioListInputListElementLabelContainerStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.block,
  fontSize: 's',
  paddingVertical: 'simple',
  flex: 'one',
};

export function useMakedefaultRadioListInputListElementLabelContainerInStyle(props: {
  lines: ouiStyle.InStyle['lines'],
  paddingVertical?: ouiStyle.InStyle['paddingVertical'],
}) {
  const {
    lines,
    paddingVertical,
  } = props;
  const inStyle = React.useMemo(
    (): ouiStyle.InStyle => ({
      ...defaultRadioListInputListElementLabelContainerStyle,
      lines,
      paddingVertical: paddingVertical ?? defaultRadioListInputListElementLabelContainerStyle.paddingVertical,
    }),
    [lines, paddingVertical],
  );
  return inStyle;
}

function useMakedefaultRadioListInputListElementLabelContainerCssFromLines(props: {
  lines: ouiStyle.InStyle['lines'],
  paddingVertical?: ouiStyle.InStyle['paddingVertical'],
}) {
  const {
    lines,
    paddingVertical,
  } = props;
  const inStyle = useMakedefaultRadioListInputListElementLabelContainerInStyle({
    lines,
    paddingVertical,
  });
  const css = React.useMemo(() => ouiStyle.mediaQuery(ouiStyle.makeCss(inStyle)), [inStyle]);
  return css;
}

export function useMakedefaultRadioListInputListElementLabelContainerCss(props: {
  paddingVertical?: ouiStyle.InStyle['paddingVertical'],
}) {
  const {
    paddingVertical,
  } = props;
  const lines = ouiBase.List.useListInputElementLines();
  const css = useMakedefaultRadioListInputListElementLabelContainerCssFromLines({
    lines,
    paddingVertical,
  });
  return css;
}

function useDefaultRadioListInputListElementLabelVariants(props: {
  paddingVertical: ouiStyle.InStyle['paddingVertical'],
}): Record<
    InputVariantsNames,
    Partial<Pick<ouiStyle.Style, 'fontWeight' | 'color'>>
  > {
  const {
    paddingVertical,
  } = props;
  const lines = ouiBase.List.useListInputElementLines();
  const baseLabelstyleInStyle = useMakedefaultRadioListInputListElementLabelContainerInStyle({
    lines,
    paddingVertical,
  });
  return React.useMemo(
    () => ({
      opened: {},
      closed: {},
      base: ouiStyle.makeCssAndExtract(baseLabelstyleInStyle, ['color']),
      hasContent: {},
      noContent: {},
      focus: ouiStyle.makeCssAndExtract(
        { ...baseLabelstyleInStyle, ...focusLabelStyleAdditionalProperties },
        ['fontWeight'],
      ),
      noFocus: ouiStyle.makeCssAndExtract(
        { ...baseLabelstyleInStyle, ...noFocusLabelStyleAdditionalProperties },
        ['fontWeight'],
      ),
      untouched: {},
      valid: {},
      error: ouiStyle.makeCssAndExtract(
        { ...baseLabelstyleInStyle, ...errorLabelStyleAdditionalProperties },
        ['color'],
      ),
      disabled: ouiStyle.makeCssAndExtract(
        { ...baseLabelstyleInStyle, ...disabledLabelStyleAdditionalProperties },
        ['color'],
      ),
      enabled: {},
      checked: {},
      unChecked: {},
      last: {},
      notLast: {},
      before: {},
      inside: {},
      after: {},
      noSelection: {},
      notCorrected: {},
      correct: {},
      incorrect: {},
      correctSelected: {},
      correctNotSelected: {},
      incorrectSelected: {},
      incorrectNotSelected: {},
    }),
    [baseLabelstyleInStyle],
  );
}

const defaultRadioListInputListElementLabelContainerInputHooksArray: InputVariantHooksArray = [
  useBaseVariant,
  useHasFocusVariant,
  useValidationVariant,
  useDisabledVariant,
];

export function DefaultRadioListInputListElementLabelContainer(
  props: React.PropsWithChildren<{
    paddingVertical?: ouiStyle.InStyle['paddingVertical'],
  }>,
) {
  const { paddingVertical, children } = props;
  const htmlFor = useRadioHtmlId();
  const css = useMakedefaultRadioListInputListElementLabelContainerCss({
    paddingVertical,
  });
  const labelVariants = useDefaultRadioListInputListElementLabelVariants({
    paddingVertical,
  });
  const spring = useVariantSpring(
    labelVariants,
    defaultRadioListInputListElementLabelContainerInputHooksArray,
  );
  return (
    <animated.label htmlFor={htmlFor} css={css} style={spring}>
      {children}
    </animated.label>
  );
}
