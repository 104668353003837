/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import { animated } from 'react-spring';
import ouiStyle from '@goldwasserexchange/oui-style';
import {
  useOnClick,
  OnClickUnsetContextProvider,
  useOnFocus,
  useOnBlur,
  useOnMouseEnter,
  useOnMouseLeave,
} from '../../../../../../../OUI/utils';
import { inputIconInnerContainerStyle } from '../style';
import {
  InputVariantsNames,
  InputVariantHooksArray,
  useBaseVariant,
  useHasFocusVariant,
  useDisabledVariant,
} from '../../../../../../../OUI/Inputs';
import { useVariantSpring } from '../../../../../utils';
import { usePopperReference } from '../../../../../../../OUI/utils/context/popper/reference';

const textInputHelpIconContainerStyle: ouiStyle.InStyle = {
  ...inputIconInnerContainerStyle,
  color: ouiStyle.Constants.Colors.primary,
};

const textInputHelpIconContainerCss = ouiStyle.makeCss(textInputHelpIconContainerStyle);

const baseTextInputHelpIconContainerCss = ouiStyle.makeCssAndExtract(
  textInputHelpIconContainerStyle,
  ['color'],
);

const focusTextInputHelpIconContainerStyle: ouiStyle.InStyle = {
  ...textInputHelpIconContainerStyle,
  color: ouiStyle.Constants.Colors.accent,
};

const focusTextInputHelpIconContainerCss = ouiStyle.makeCssAndExtract(
  focusTextInputHelpIconContainerStyle,
  ['color'],
);

const disabledTextInputHelpIconContainerStyle: ouiStyle.InStyle = {
  ...textInputHelpIconContainerStyle,
  color: ouiStyle.Constants.Colors.disabled,
};

const disabledTextInputHelpIconContainerCss = ouiStyle.makeCssAndExtract(
  disabledTextInputHelpIconContainerStyle,
  ['color'],
);

const textInputHelpIconContainerVariants: Record<
  InputVariantsNames,
  Partial<Pick<ouiStyle.Style, 'color'>>
> = {
  opened: {},
  closed: {},
  base: baseTextInputHelpIconContainerCss,
  hasContent: {},
  noContent: {},
  focus: focusTextInputHelpIconContainerCss,
  noFocus: {},
  untouched: {},
  valid: {},
  error: {},
  disabled: disabledTextInputHelpIconContainerCss,
  enabled: {},
  checked: {},
  unChecked: {},
  last: {},
  notLast: {},
  before: {},
  inside: {},
  after: {},
  noSelection: {},
  notCorrected: {},
  correct: {},
  incorrect: {},
  correctSelected: {},
  correctNotSelected: {},
  incorrectSelected: {},
  incorrectNotSelected: {},
};

const listInputLabelContainerHookVariants: InputVariantHooksArray = [
  useBaseVariant,
  useHasFocusVariant,
  useDisabledVariant,
];

export function DomInputHelpIconContainer(
  props: React.PropsWithChildren<{ style: any }>,
) {
  const { style: transitionStyle, children } = props;
  const ref = usePopperReference() as React.MutableRefObject<HTMLButtonElement | null>;
  const onClick = useOnClick();
  const onFocus = useOnFocus();
  const onBlur = useOnBlur();
  const onMouseEnter = useOnMouseEnter();
  const onMouseLeave = useOnMouseLeave();
  const style = useVariantSpring(
    textInputHelpIconContainerVariants,
    listInputLabelContainerHookVariants,
  );
  return (
    <animated.button
      type="button"
      ref={ref}
      css={textInputHelpIconContainerCss}
      style={{ ...transitionStyle, ...style }}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <OnClickUnsetContextProvider>
        {children}
      </OnClickUnsetContextProvider>
    </animated.button>
  );
}
