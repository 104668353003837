import { SecuritiesSecurity } from '../../type';
import { useSecuritiesSecurityFieldApi, useSecuritiesSecurityFieldTransformFnApi } from '../api';
import { McGrossOpb } from '../utils';

export const useSecuritiesSecuritySTypeMcGrossOpb = () => {
  const response = useSecuritiesSecurityFieldApi('S_TYPE.MC_GROSS_OPB');
  return response;
};

export const mcGrossOpbIsPercent = (mcGrossOpb: SecuritiesSecurity['S_TYPE.MC_GROSS_OPB'] | undefined) => mcGrossOpb === McGrossOpb.BF_DIV_100 || mcGrossOpb === McGrossOpb.MULT_100;

export const useSecuritiesSecuritySTypeMcGrossOpbIsPercent = () => {
  const response = useSecuritiesSecurityFieldTransformFnApi({
    field: 'S_TYPE.MC_GROSS_OPB',
    transformFn: mcGrossOpbIsPercent,
  });
  return response;
};

export const getMcGrossOpbDivider = (mcGrossOpb: SecuritiesSecurity['S_TYPE.MC_GROSS_OPB'] | undefined) => {
  const isPercent = mcGrossOpbIsPercent(mcGrossOpb);
  return isPercent ? 100 : 1;
};

export const useSecuritiesSecuritySTypeMcGrossOpbDivider = () => {
  const response = useSecuritiesSecurityFieldTransformFnApi({
    field: 'S_TYPE.MC_GROSS_OPB',
    transformFn: getMcGrossOpbDivider,
  });
  return response;
};
