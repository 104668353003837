import useSWR from 'swr';
import { useLinguiLocale } from '../useLinguiLocale';

export type ArticleCategory = {
  id?: string,
  name: string,
  title: string,
};

const articleCategoryListFetcher = async (tag: string) => {
  const categoryList: { default: ArticleCategory[] } = await import(`./articles/${tag}.ts`);
  const validCategoryList = categoryList.default;
  return validCategoryList;
};

export const useCmsCategory = (tag: string | null) => {
  const locale = useLinguiLocale();
  const response = useSWR(tag ? `${tag}/${locale}` : null, articleCategoryListFetcher);
  return response;
};

export const useCmsTitle = (tag: string | null, article: string) => {
  const {
    data,
  } = useCmsCategory(tag);
  if (data == null || data.length === 0) {
    return undefined;
  }
  const label = data?.find((item) => item.title === article)?.title;
  return label;
};

const articleFetcher = async (url: string) => {
  const article = await import(`./articles/${url}.md`);
  return article.default;
};

export const useCmsArticle = (name: string | null) => {
  const locale = useLinguiLocale();
  const response = useSWR<string>(
    name != null ? `${name}/${locale}` : null,
    articleFetcher,
  );
  return response;
};

const imageFetcher = async (name: string) => {
  const image = await import(`./articles/${name}/image.jpg`);
  return image.default;
};

export const useCmsImage = (name: string | null) => {
  const response = useSWR(
    name,
    imageFetcher,
  );
  return response;
};
