import Big from 'big.js';
import { getDecimalPlaces } from './getDecimalPlaces';
import { getRoundingMode } from './getRoundingMode';

export const roundBigToNearest = (nearest: Big, number: Big, roundingMode?: 'round' | 'down' | 'up') => {
  const dp = getDecimalPlaces(nearest.toNumber());
  const rm = getRoundingMode(roundingMode);
  const num = number
    .div(nearest)
    .round(undefined, rm)
    .times(nearest)
    .round(dp);
  return num;
};

export const roundToNearest = (nearest: number, number: number, roundingMode?: 'round' | 'down' | 'up') => {
  const bigNearest = Big(nearest);
  const bigNumber = Big(number);
  return roundBigToNearest(bigNearest, bigNumber, roundingMode).toNumber();
};

export const makeRoundToNearest = (roundingMode?: 'round' | 'down' | 'up') => (nearest: number, number: number) => roundToNearest(nearest, number, roundingMode);
