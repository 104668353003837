import ouiBase from '@goldwasserexchange/oui';
import { useAccountPositionFieldApiByGesEstimD } from '../api';

export const useAccountPositionsSTypeGrpId = () => {
  const gesEstimDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useAccountPositionFieldApiByGesEstimD({
    gesEstimDId,
    field: 'S_TYPE_GRP.ID',
  });
  return result;
};
