/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import { animated } from 'react-spring';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useOnClick } from '../../../../../../../../OUI/utils';
import {
  InputVariantsNames,
  InputVariantHooksArray,
  useBaseVariant,
  useHasFocusVariant,
  useValidationVariant,
  useDisabledVariant,
} from '../../../../../../../../OUI/Inputs';
import { useVariantSpring } from '../../../../../../utils';

const defaultDomSelectInputListButtonContainerStyle: ouiStyle.InStyle = {
  flex: 'none',
  lines: 1,
  widthLines: 1,
  paddingTop: 'simple',
  paddingBottom: 'simple',
  paddingLeft: 'simple',
  paddingRight: 'simple',
  iconContainer: true,
  color: ouiStyle.Constants.Colors.accent,
  backgroundColor: ouiStyle.Constants.Colors.inverted,
  borderVerticalColor: ouiStyle.Constants.Colors.primary,
  borderVerticalWidth: 'simple',
  borderVerticalStyle: 'solid',
  borderRightColor: ouiStyle.Constants.Colors.primary,
  borderRightWidth: 'simple',
  borderRightStyle: 'solid',
};

const defaultDomSelectInputListButtonContainerCss = ouiStyle.makeCss(
  defaultDomSelectInputListButtonContainerStyle,
);

const baseDomSelectInputListButtonContainerCss = ouiStyle.makeCssAndExtract(
  defaultDomSelectInputListButtonContainerStyle,
  [
    'borderTopColor',
    'borderRightColor',
    'borderBottomColor',
    'borderLeftColor',
    'color',
  ],
);

const focusDomSelectInputListButtonContainerStyle: ouiStyle.InStyle = {
  ...defaultDomSelectInputListButtonContainerStyle,
  borderVerticalColor: ouiStyle.Constants.Colors.accent,
  borderRightColor: ouiStyle.Constants.Colors.accent,
  color: ouiStyle.Constants.Colors.primary,
};

const focusDomSelectInputListButtonContainerCss = ouiStyle.makeCssAndExtract(
  focusDomSelectInputListButtonContainerStyle,
  [
    'borderTopColor',
    'borderRightColor',
    'borderBottomColor',
    'borderLeftColor',
    'color',
  ],
);

const errorDomSelectInputListButtonContainerStyle: ouiStyle.InStyle = {
  ...defaultDomSelectInputListButtonContainerStyle,
  borderVerticalColor: ouiStyle.Constants.Colors.danger,
  borderRightColor: ouiStyle.Constants.Colors.danger,
  color: ouiStyle.Constants.Colors.danger,
};

const errorDomSelectInputListButtonContainerCss = ouiStyle.makeCssAndExtract(
  errorDomSelectInputListButtonContainerStyle,
  [
    'borderTopColor',
    'borderRightColor',
    'borderBottomColor',
    'borderLeftColor',
    'color',
  ],
);

const disabledDomSelectInputListButtonContainerStyle: ouiStyle.InStyle = {
  ...defaultDomSelectInputListButtonContainerStyle,
  borderVerticalColor: ouiStyle.Constants.Colors.disabled,
  borderRightColor: ouiStyle.Constants.Colors.disabled,
  color: ouiStyle.Constants.Colors.disabled,
};

const disabledDomSelectInputListButtonContainerCss = ouiStyle.makeCssAndExtract(
  disabledDomSelectInputListButtonContainerStyle,
  [
    'borderTopColor',
    'borderRightColor',
    'borderBottomColor',
    'borderLeftColor',
    'color',
  ],
);

const DomSelectInputListButtonContainerVariants: Record<
  InputVariantsNames,
  Partial<
    Pick<
      ouiStyle.Style,
| 'borderTopColor'
| 'borderRightColor'
| 'borderBottomColor'
| 'borderLeftColor'
| 'color'
    >
  >
> = {
  opened: {},
  closed: {},
  base: baseDomSelectInputListButtonContainerCss,
  hasContent: {},
  noContent: {},
  focus: focusDomSelectInputListButtonContainerCss,
  noFocus: {},
  untouched: {},
  valid: {},
  error: errorDomSelectInputListButtonContainerCss,
  disabled: disabledDomSelectInputListButtonContainerCss,
  enabled: {},
  checked: {},
  unChecked: {},
  last: {},
  notLast: {},
  before: {},
  inside: {},
  after: {},
  noSelection: {},
  notCorrected: {},
  correct: {},
  incorrect: {},
  correctSelected: {},
  correctNotSelected: {},
  incorrectSelected: {},
  incorrectNotSelected: {},
};

const domSelectInputListButtonContainerHookVariants: InputVariantHooksArray = [
  useBaseVariant,
  useHasFocusVariant,
  useValidationVariant,
  useDisabledVariant,
];

export function DefaultDomSelectInputListButtonContainer(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const onClick = useOnClick();
  const style = useVariantSpring(
    DomSelectInputListButtonContainerVariants,
    domSelectInputListButtonContainerHookVariants,
  );
  return (
    <animated.button
      css={defaultDomSelectInputListButtonContainerCss}
      style={style}
      onClick={onClick}
      tabIndex={-1}
      type="button"
    >
      {children}
    </animated.button>
  );
}
