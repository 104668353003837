import React from 'react';
import { useLayout } from './helpers';

export const Slot = (props: {
  // eslint-disable-next-line react/no-unused-prop-types
  slot: string,
  children: React.ReactNode | ((...args: any[]) => React.ReactNode),
}) => {
  const { children } = props;
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children as React.ReactNode}</>
  );
};

Slot.displayName = 'Slot';

export const Layout = ({ children }) => {
  const {
    header, asideBefore, main, asideAfter, footer,
  } = useLayout(children);
  return (
    <>
      {header}
      {asideBefore}
      {main}
      {asideAfter}
      {footer}
    </>
  );
};

Layout.displayName = 'Layout';
