import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { MessageDescriptor } from '@lingui/core';
import { useCallback } from 'react';
import { useBondsBondFieldApi, useBondsBondFieldTransformFnApi } from '../api';

export const useBondsBondSAccGreen = () => {
  const response = useBondsBondFieldApi('S_ACC.GREEN');
  return response;
};

const transformSAccGreenBoolean = (sAccGreen: string | undefined) => {
  if (sAccGreen == null) {
    return null;
  }
  return `${sAccGreen}` === '1';
};

export const useBondsBondSAccGreenBoolean = () => {
  const response = useBondsBondFieldTransformFnApi({
    field: 'S_ACC.GREEN',
    transformFn: transformSAccGreenBoolean,
  });
  return response;
};

const makeTransformSAccGreenText = (_: (descriptor: MessageDescriptor) => string) => (sAccGreen: string | undefined) => {
  if (sAccGreen == null) {
    return null;
  }
  return `${sAccGreen}` === '1' ? _(msg`Oui`) : _(msg`Non`);
};

export const useBondsBondSAccGreenText = () => {
  const {
    _,
  } = useLingui();
  const transformSAccGreenText = useCallback(makeTransformSAccGreenText(_), [_]);
  const response = useBondsBondFieldTransformFnApi({
    field: 'S_ACC.GREEN',
    transformFn: transformSAccGreenText,
  });
  return response;
};
