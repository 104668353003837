import speakingurl from 'speakingurl';
import { Locale } from 'date-fns';
import { BondResponse } from '../bond/type';
import { BondSameIssuerResponse } from '../sameIssuer/type';
import { makeBondFullNameTransformFn } from './bondFullName';
import { BondList } from '../listOrFavorites';
import { AccountPositionTitres, AccountsOrder } from '../../accounts';
import { SecuritiesSecurity } from '../../securities';

export const makeBondSlugTransformFn = (props: {
  intRateFormatter: (value: number) => string,
  locale: Locale,
  maxSIssuerName1Length?: number,
}) => {
  const {
    locale,
  } = props;
  const fullNameTransform = makeBondFullNameTransformFn(props);
  return (bond: BondResponse | BondList | BondSameIssuerResponse | AccountsOrder | AccountPositionTitres | SecuritiesSecurity | undefined) => {
    if (bond == null) {
      return '';
    }
    const sAccId = bond['S_ACC.ID'];
    const sIssuerId = bond['S_ISSUER.ID'];
    const fullName = fullNameTransform(bond)
      .replaceAll('%', ' percent')
      .replaceAll('∞', 'perpetual')
      .replaceAll(',', ' point ');
    const fullNameSlug = speakingurl(fullName, { symbols: true, lang: locale.code });
    return `${fullNameSlug}${sIssuerId != null ? `_${sIssuerId}` : ''}_${sAccId}`;
  };
};
