import * as React from 'react';
import
Accounts,
{
  AccountsHistoryHisMvtCmtConsult,
  AccountsDocumentsArDocDescription,
  AccountsPositionsHistoryHisMvtCmtConsult,
  AccountsOrdersArDocDescription,
} from '../../../../../accounts';

export const DocumentModalTitle = (props: { service: Accounts.History | Accounts.Orders | Accounts.Documents | Accounts.PositionsHistory }) => {
  const {
    service,
  } = props;
  if (service === Accounts.History) {
    return <AccountsHistoryHisMvtCmtConsult />;
  }
  if (service === Accounts.Documents) {
    return <AccountsDocumentsArDocDescription />;
  }
  if (service === Accounts.PositionsHistory) {
    return <AccountsPositionsHistoryHisMvtCmtConsult />;
  }
  if (service === Accounts.Orders) {
    return <AccountsOrdersArDocDescription />;
  }
  return null;
};
