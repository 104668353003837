import ouiDom from '@goldwasserexchange/oui-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { DateDisplay } from '../../../../../../../Components/DateDisplay';
import { NumberDisplay } from '../../../../../../../Components/NumberDisplay';
import { SQuoteChartLabelContainer } from './container';

const getColor = (y: number | null, priceBuy: number | null) => {
  if (y == null || priceBuy == null) {
    return ouiStyle.Constants.Colors.primary;
  }
  return y >= priceBuy ? ouiStyle.Constants.Colors.success : ouiStyle.Constants.Colors.danger;
};

export const SQuoteChartLabel = (props) => {
  const {
    label,
    payload,
    priceBuy,
  } = props;
  const {
    payload: {
      y,
    },
  } = payload[0] ?? {
    payload: { y: null, stroke: ouiStyle.Constants.Colors.primary },
  };
  const color = getColor(y, priceBuy);
  const yPercentage = priceBuy ? (y - priceBuy) / priceBuy : 0;
  return label && y ? (
    <SQuoteChartLabelContainer
      borderColor={color}
    >
      <ouiDom.P.P
        paddingVertical="none"
      >
        <DateDisplay
          date={label}
          format="dd/MM/yyyy"
        />
      </ouiDom.P.P>
      <ouiDom.P.P
        paddingVertical="none"
      >
        <NumberDisplay
          value={y}
          format={{
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: y >= 0.01 ? 2 : 4,
          }}
        />
        { priceBuy ? (
          <>
            {' '}
            (
            <ouiDom.TextSnippet.TextSnippet
              color={color}
            >
              <NumberDisplay
                value={yPercentage}
                format={{
                  style: 'percent',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: yPercentage >= 0.01 ? 2 : 4,
                }}
                forcedSigned
              />
            </ouiDom.TextSnippet.TextSnippet>
            )
          </>
        ) : null }
      </ouiDom.P.P>
    </SQuoteChartLabelContainer>
  ) : null;
};
