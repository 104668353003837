/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import { animated } from 'react-spring';
import ouiStyle from '@goldwasserexchange/oui-style';
import {
  InputVariantsNames,
  InputVariantHooksArray,
  useBaseVariant,
  useHasFocusVariant,
  useValidationVariant,
  useDisabledVariant,
} from '../../../../../../../OUI/Inputs';
import { useVariantSpring } from '../../../../../utils';
import { useOnClick } from '../../../../../../../OUI/utils';

const checkboxListInputDomListElementButtonContainerStyle: ouiStyle.InStyle = {
  lines: 1,
  widthLines: 1,
  borderStyle: 'solid',
  borderWidth: 'simple',
  borderColor: ouiStyle.Constants.Colors.primary,
  fontSize: 's',
  padding: 'none',
  margin: 'none',
  icon: true,
};

const checkboxListInputDomListElementButtonContainerCss = ouiStyle.makeCss(
  checkboxListInputDomListElementButtonContainerStyle,
);

const baseCheckboxListInputDomListElementButtonContainerCss = ouiStyle.makeCssAndExtract(
  checkboxListInputDomListElementButtonContainerStyle,
  ['borderTopColor', 'borderRightColor', 'borderBottomColor', 'borderLeftColor'],
);

const focusCheckboxListInputDomListElementButtonContainerStyle: ouiStyle.InStyle = {
  ...checkboxListInputDomListElementButtonContainerStyle,
  borderColor: ouiStyle.Constants.Colors.accent,
};

const focusCheckboxListInputDomListElementButtonContainerCss = ouiStyle.makeCssAndExtract(
  focusCheckboxListInputDomListElementButtonContainerStyle,
  ['borderTopColor', 'borderRightColor', 'borderBottomColor', 'borderLeftColor'],
);

const errorCheckboxListInputDomListElementButtonContainerStyle: ouiStyle.InStyle = {
  ...checkboxListInputDomListElementButtonContainerStyle,
  borderColor: ouiStyle.Constants.Colors.danger,
};

const errorCheckboxListInputDomListElementButtonContainerCss = ouiStyle.makeCssAndExtract(
  errorCheckboxListInputDomListElementButtonContainerStyle,
  ['borderTopColor', 'borderRightColor', 'borderBottomColor', 'borderLeftColor'],
);

const disabledCheckboxListInputDomListElementButtonContainerStyle: ouiStyle.InStyle = {
  ...checkboxListInputDomListElementButtonContainerStyle,
  borderColor: ouiStyle.Constants.Colors.disabled,
};

const disabledCheckboxListInputDomListElementButtonContainerCss = ouiStyle.makeCssAndExtract(
  disabledCheckboxListInputDomListElementButtonContainerStyle,
  ['borderTopColor', 'borderRightColor', 'borderBottomColor', 'borderLeftColor'],
);

const checkboxListInputDomListElementButtonContainerVariants: Record<
  InputVariantsNames,
  Partial<
    Pick<
      ouiStyle.Style,
| 'borderTopColor'
| 'borderRightColor'
| 'borderBottomColor'
| 'borderLeftColor'
    >
  >
> = {
  opened: {},
  closed: {},
  base: baseCheckboxListInputDomListElementButtonContainerCss,
  hasContent: {},
  noContent: {},
  focus: focusCheckboxListInputDomListElementButtonContainerCss,
  noFocus: {},
  untouched: {},
  valid: {},
  error: errorCheckboxListInputDomListElementButtonContainerCss,
  disabled: disabledCheckboxListInputDomListElementButtonContainerCss,
  enabled: {},
  checked: {},
  unChecked: {},
  last: {},
  notLast: {},
  before: {},
  inside: {},
  after: {},
  noSelection: {},
  notCorrected: {},
  correct: {},
  incorrect: {},
  correctSelected: {},
  correctNotSelected: {},
  incorrectSelected: {},
  incorrectNotSelected: {},
};

const listInputLabelContainerHookVariants: InputVariantHooksArray = [
  useBaseVariant,
  useHasFocusVariant,
  useValidationVariant,
  useDisabledVariant,
];

export function CheckboxListInputDomListElementButtonContainer(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const propsStyle = useVariantSpring(
    checkboxListInputDomListElementButtonContainerVariants,
    listInputLabelContainerHookVariants,
  );
  const onClick = useOnClick();
  return (
    <animated.button
      css={checkboxListInputDomListElementButtonContainerCss}
      style={propsStyle}
      onClick={onClick}
      tabIndex={-1}
      type="button"
    >
      {children}
    </animated.button>
  );
}
