import { clamp } from 'ramda';
import { AccountCashflow } from '../type';

export const computeWithoutTaxCashflowPrice = (cashflowLine: AccountCashflow) => {
  const qt = cashflowLine['GES_ESTIM_D.QT'];
  const price = cashflowLine['CPS_HIS.PRICE'];
  return qt * price;
};

export const computeTaxRate = (cashflowLine: AccountCashflow) => {
  const isOrdinaire = cashflowLine['T_REL.MC_CPSFISC'] === 'Ordinaire';
  const rate = isOrdinaire ? 1 - (cashflowLine['S_ACC_BE.RATE_PREC_CPS'] / 100) : 1;
  return rate;
};

export const computeTaxedCashflowPrice = (cashflowLine: AccountCashflow) => {
  const withoutTaxPrice = computeWithoutTaxCashflowPrice(cashflowLine);
  const rate = computeTaxRate(cashflowLine);
  const taxedCashflow = withoutTaxPrice * rate;
  return taxedCashflow;
};

export const computeCashflowUnclampedCommission = (cashflowLine: AccountCashflow, precomputedTaxedCashflow?: number) => {
  const rateCom = cashflowLine['CPS_TAC_PCT.RATE_COM'] / 1000;
  const taxedCashflow = precomputedTaxedCashflow || computeTaxedCashflowPrice(cashflowLine);
  const commission = taxedCashflow * rateCom;
  return commission;
};

export const computeCashflowCommission = (cashflowLine: AccountCashflow, precomputedTaxedCashflow?: number) => {
  const exRateGes = cashflowLine['EXRATE_HIS.EXRATE_GES'];
  const comMin = (cashflowLine['CPS_TAC_PC.COM_MIN'] ?? 0) * exRateGes;
  const comMax = (cashflowLine['CPS_TAC_PC.COM_MAX'] ?? Infinity) * exRateGes;
  const commission = computeCashflowUnclampedCommission(cashflowLine, precomputedTaxedCashflow);
  const clampedCommission = clamp(
    comMin,
    comMax,
    commission,
  );
  return clampedCommission;
};

export const computeCashflowAfterTaxAndCom = (cashflowLine: AccountCashflow | null | undefined) => {
  if (cashflowLine) {
    const taxedCashflow = computeTaxedCashflowPrice(cashflowLine);
    const commission = computeCashflowCommission(cashflowLine, taxedCashflow);
    const cashflowAfterTaxAndCom = taxedCashflow - commission;
    return Math.max(cashflowAfterTaxAndCom, 0);
  }
  return 0;
};

export const computeCashflowAfterTaxAndComTotal = (cashflowLines: AccountCashflow[]) => cashflowLines.reduce((acc, line) => acc + computeCashflowAfterTaxAndCom(line), 0);

export const computeCashflowAfterTaxAndComInAccountCurrency = (cashflowLine: AccountCashflow | null | undefined) => {
  if (cashflowLine) {
    const cashflowAfterTaxAndCom = computeCashflowAfterTaxAndCom(cashflowLine);
    const exRateGes = cashflowLine['EXRATE_HIS.EXRATE_GES'];
    const cashflowAftrTaxAndComInAccountCurrency = cashflowAfterTaxAndCom * (1 / exRateGes);
    return cashflowAftrTaxAndComInAccountCurrency;
  }
  return 0;
};

export const computeCashflowAfterTaxAndComInAccountCurrencyTotal = (cashflowLines: AccountCashflow[] | null | undefined) => (cashflowLines ? cashflowLines.reduce((acc, line) => acc + computeCashflowAfterTaxAndComInAccountCurrency(line), 0) : 0);
