import { roundToNearest } from '../../../../../../utils/roundToNearest';
import { AccountRates } from '../type';

export const DEFAULT_SIMULATION_COUNTER_VALUE = 10_000;

const computeMinCountervalue = (courtMin: number, rateCourt: number) => roundToNearest(5000, courtMin / rateCourt, 'up');

export const computeCountervalue = (rates: AccountRates | undefined) => {
  if (!rates) {
    return DEFAULT_SIMULATION_COUNTER_VALUE;
  }
  return Math.max(
    computeMinCountervalue(rates['T_ACC.BONDS_COURT_MIN'], rates['T_ACC.BONDS_RATE_COURT']),
    computeMinCountervalue(rates['T_ACC.STOCKS_COURT_MIN'], rates['T_ACC.STOCKS_RATE_COURT']),
  );
};
