/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import { animated } from 'react-spring';
import ouiStyle from '@goldwasserexchange/oui-style';
import {
  InputVariantsNames,
  InputVariantHooksArray,
  useBaseVariant,
  useHasFocusVariant,
  useValidationVariant,
  useDisabledVariant,
} from '../../../../../../../OUI/Inputs';
import { useVariantSpring } from '../../../../../utils';
import { useOnClick } from '../../../../../../../OUI/utils';

const radioListInputDomListElementButtonContainerStyle: ouiStyle.InStyle = {
  position: 'relative',
  lines: 1,
  widthLines: 1,
  borderStyle: 'solid',
  borderWidth: 'simple',
  borderColor: ouiStyle.Constants.Colors.primary,
  borderRadius: 'full',
  overflow: 'hidden',
  fontSize: 's',
  padding: 'none',
  margin: 'none',
  icon: true,
};

const radioListInputDomListElementButtonContainerCss = ouiStyle.makeCss(
  radioListInputDomListElementButtonContainerStyle,
);

const baseRadioListInputDomListElementButtonContainerCss = ouiStyle.makeCssAndExtract(
  radioListInputDomListElementButtonContainerStyle,
  ['borderTopColor', 'borderRightColor', 'borderBottomColor', 'borderLeftColor'],
);

const focusRadioListInputDomListElementButtonContainerStyle: ouiStyle.InStyle = {
  ...radioListInputDomListElementButtonContainerStyle,
  borderColor: ouiStyle.Constants.Colors.accent,
};

const focusRadioListInputDomListElementButtonContainerCss = ouiStyle.makeCssAndExtract(
  focusRadioListInputDomListElementButtonContainerStyle,
  ['borderTopColor', 'borderRightColor', 'borderBottomColor', 'borderLeftColor'],
);

const errorRadioListInputDomListElementButtonContainerStyle: ouiStyle.InStyle = {
  ...radioListInputDomListElementButtonContainerStyle,
  borderColor: ouiStyle.Constants.Colors.danger,
};

const errorRadioListInputDomListElementButtonContainerCss = ouiStyle.makeCssAndExtract(
  errorRadioListInputDomListElementButtonContainerStyle,
  ['borderTopColor', 'borderRightColor', 'borderBottomColor', 'borderLeftColor'],
);

const disabledRadioListInputDomListElementButtonContainerStyle: ouiStyle.InStyle = {
  ...radioListInputDomListElementButtonContainerStyle,
  borderColor: ouiStyle.Constants.Colors.disabled,
};

const disabledRadioListInputDomListElementButtonContainerCss = ouiStyle.makeCssAndExtract(
  disabledRadioListInputDomListElementButtonContainerStyle,
  ['borderTopColor', 'borderRightColor', 'borderBottomColor', 'borderLeftColor'],
);

const radioListInputDomListElementButtonContainerVariants: Record<
  InputVariantsNames,
  Partial<
    Pick<
      ouiStyle.Style,
| 'borderTopColor'
| 'borderRightColor'
| 'borderBottomColor'
| 'borderLeftColor'
    >
  >
> = {
  opened: {},
  closed: {},
  base: baseRadioListInputDomListElementButtonContainerCss,
  hasContent: {},
  noContent: {},
  focus: focusRadioListInputDomListElementButtonContainerCss,
  noFocus: {},
  untouched: {},
  valid: {},
  error: errorRadioListInputDomListElementButtonContainerCss,
  disabled: disabledRadioListInputDomListElementButtonContainerCss,
  enabled: {},
  checked: {},
  unChecked: {},
  last: {},
  notLast: {},
  before: {},
  inside: {},
  after: {},
  noSelection: {},
  notCorrected: {},
  correct: {},
  incorrect: {},
  correctSelected: {},
  correctNotSelected: {},
  incorrectSelected: {},
  incorrectNotSelected: {},
};

const listInputLabelContainerHookVariants: InputVariantHooksArray = [
  useBaseVariant,
  useHasFocusVariant,
  useValidationVariant,
  useDisabledVariant,
];

export function RadioListInputDomListElementButtonContainer(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const style = useVariantSpring(
    radioListInputDomListElementButtonContainerVariants,
    listInputLabelContainerHookVariants,
  );
  const onClick = useOnClick();
  return (
    <animated.button
      css={radioListInputDomListElementButtonContainerCss}
      style={style}
      onClick={onClick}
      tabIndex={-1}
      type="button"
    >
      {children}
    </animated.button>
  );
}
