import { SecuritiesSecurity } from '../../type';
import { useSecuritiesSecurityTransformApi } from '../api';
import { mcGrossOpbIsPercent } from '../sType';

export const getCoupure = (securitiesSecurity: SecuritiesSecurity) => {
  const isPercent = mcGrossOpbIsPercent(securitiesSecurity['S_TYPE.MC_GROSS_OPB']);
  const quotMul = securitiesSecurity['S_MARKET.QUOT_MULT'];
  const coupure = isPercent ? Math.max(1000, quotMul ?? 0) : quotMul;
  return coupure;
};

export const getCoupureWithUndefined = (securitiesSecurity: SecuritiesSecurity | undefined) => {
  if (securitiesSecurity == null) {
    return undefined;
  }
  return getCoupure(securitiesSecurity);
};

export const useSecuritiesSecuritySMarketCoupure = () => {
  const response = useSecuritiesSecurityTransformApi(getCoupureWithUndefined);
  return response;
};
