import * as React from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useAccountPositionGesEstimDPrice, useAccountPositionGesEstimDPriceIconName } from '../../hooks';
import { ActorApiNumberDisplay } from '../../../../../../components/display/number/component';

export const AccountsPositionsGesEstimDPrice = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountPositionGesEstimDPrice();
  return (
    <ActorApiNumberDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      format={{
        minimumFractionDigits: 2,
        maximumFractionDigits: data == null || data >= 0.01 ? 2 : 4,
      }}
    />
  );
};

export const AccountsPositionsGesEstimDPriceIcon = (props: {
  size?: number | string,
}) => {
  const { size } = props;
  const {
    data,
  } = useAccountPositionGesEstimDPriceIconName();
  if (data === 'negative') {
    return (
      <ChevronDown
        color={ouiStyle.Constants.Colors.danger}
        size={size}
      />
    );
  }
  if (data === 'positive') {
    return (
      <ChevronUp
        color={ouiStyle.Constants.Colors.success}
        size={size}
      />
    );
  }
  return null;
};
