/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { animated } from 'react-spring';
import {
  InputVariantHooksArray,
  InputVariantsNames,
  useBaseVariant,
  useDisabledVariant,
  useHasFocusVariant,
  useOpenVariants,
  useValidationVariant,
} from '../../../../../../../../../OUI/Inputs';
import { useVariantSpring } from '../../../../../../../utils';
import { defaultListInputListElementContainerStyle } from '../../../../../../shared/components/list/components/element/containerStyle';

const defaultDomSelectInputListSeachInputPlaceHolderStyle: ouiStyle.InStyle = {
  ...defaultListInputListElementContainerStyle,
  parentBorderTopStyle: 'solid',
  parentBorderTopWidth: 'simple',
  borderBottomWidth: 'none',
  borderBottomColor: ouiStyle.Constants.Colors.primary,
  parentBorderBottomStyle: 'solid',
  parentBorderBottomWidth: 'double',
  lines: 1,
};

const defaultDomSelectInputListSearchInputPlaceHolderCss = ouiStyle.makeCss(
  defaultDomSelectInputListSeachInputPlaceHolderStyle,
);

const baseDomSelectInputListSearchInputPlaceHolderCss = ouiStyle.makeCssAndExtract(
  defaultDomSelectInputListSeachInputPlaceHolderStyle,
  [
    'borderBottomWidth',
    'borderBottomStyle',
    'borderBottomColor',
    'paddingTop',
    'paddingBottom',
    'height',
  ],
);

const openDomSelectInputListSearchInputStyle: ouiStyle.InStyle = {
  ...defaultDomSelectInputListSeachInputPlaceHolderStyle,
  parentBorderBottomStyle: 'none',
  parentBorderBottomWidth: 'none',
  borderBottomWidth: 'simple',
  borderBottomStyle: 'solid',
};

const openDomSelectInputListSearchInputPlaceHolderCss = ouiStyle.makeCssAndExtract(
  openDomSelectInputListSearchInputStyle,
  [
    'borderBottomWidth',
    'borderBottomStyle',
    'paddingTop',
    'paddingBottom',
    'height',
  ],
);

const closedDomSelectInputListSearchInputPlaceHolderStyle: ouiStyle.InStyle = {
  ...defaultDomSelectInputListSeachInputPlaceHolderStyle,
  parentBorderBottomStyle: 'solid',
  parentBorderBottomWidth: 'double',
  borderBottomWidth: 'none',
  borderBottomStyle: 'none',
};

const closedDomSelectInputListSearchInputPlaceHolderCss = ouiStyle.makeCssAndExtract(
  closedDomSelectInputListSearchInputPlaceHolderStyle,
  [
    'borderBottomWidth',
    'borderBottomStyle',
    'paddingTop',
    'paddingBottom',
    'height',
  ],
);

const focusDomSelectInputListSearchInputPlaceHolderStyle: ouiStyle.InStyle = {
  ...defaultDomSelectInputListSeachInputPlaceHolderStyle,
  borderBottomColor: ouiStyle.Constants.Colors.accent,
};

const focusDomSelectInputListSearchInputPlaceHolderCss = ouiStyle.makeCssAndExtract(
  focusDomSelectInputListSearchInputPlaceHolderStyle,
  ['borderBottomColor'],
);

const errorDomSelectInputListSearchInputPlaceHolderStyle: ouiStyle.InStyle = {
  ...defaultDomSelectInputListSeachInputPlaceHolderStyle,
  borderBottomColor: ouiStyle.Constants.Colors.danger,
};

const errorDomSelectInputListSearchInputPlaceHolderCss = ouiStyle.makeCssAndExtract(
  errorDomSelectInputListSearchInputPlaceHolderStyle,
  ['borderBottomColor'],
);

const disabledDomSelectInputListSearchInputPlaceHolderStyle: ouiStyle.InStyle = {
  ...defaultDomSelectInputListSeachInputPlaceHolderStyle,
  borderBottomColor: ouiStyle.Constants.Colors.disabled,
};

const disabledDomSelectInputListSearchInputPlaceHolderCss = ouiStyle.makeCssAndExtract(
  disabledDomSelectInputListSearchInputPlaceHolderStyle,
  ['borderBottomColor'],
);

const DefaultDomSelectInputListSearchInputPlaceHolderVariants: Record<
  InputVariantsNames,
  Partial<
    Pick<
      ouiStyle.Style,
| 'height'
| 'paddingTop'
| 'paddingBottom'
| 'borderBottomStyle'
| 'borderBottomWidth'
| 'borderBottomColor'
    >
  >
> = {
  base: baseDomSelectInputListSearchInputPlaceHolderCss,
  opened: openDomSelectInputListSearchInputPlaceHolderCss,
  closed: closedDomSelectInputListSearchInputPlaceHolderCss,
  hasContent: {},
  noContent: {},
  focus: focusDomSelectInputListSearchInputPlaceHolderCss,
  noFocus: {},
  untouched: {},
  valid: {},
  error: errorDomSelectInputListSearchInputPlaceHolderCss,
  disabled: disabledDomSelectInputListSearchInputPlaceHolderCss,
  enabled: {},
  checked: {},
  unChecked: {},
  last: {},
  notLast: {},
  before: {},
  inside: {},
  after: {},
  noSelection: {},
  notCorrected: {},
  correct: {},
  incorrect: {},
  correctSelected: {},
  correctNotSelected: {},
  incorrectSelected: {},
  incorrectNotSelected: {},
};

export const defaultDomSelectInputListSearchInputPlaceHolderHookVariants: InputVariantHooksArray = [
  useBaseVariant,
  useOpenVariants,
  useHasFocusVariant,
  useValidationVariant,
  useDisabledVariant,
];

export function DefaultDomSelectInputListSearchInputPlaceHolder(
  props: React.PropsWithChildren<{}>,
) {
  const { children } = props;
  const style = useVariantSpring(
    DefaultDomSelectInputListSearchInputPlaceHolderVariants,
    defaultDomSelectInputListSearchInputPlaceHolderHookVariants,
  );
  return (
    <animated.div
      css={defaultDomSelectInputListSearchInputPlaceHolderCss}
      style={style}
    >
      {children}
    </animated.div>
  );
}
