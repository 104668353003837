import qs from 'query-string';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import { SearchAndFilterBondParams } from './filter';
import { bondSearchParamsAtom, cleanBondParams } from './atoms';
import { BondSort } from './sort';
import { locationPathnameAtom } from '../../../../../../history';

export const stringifyCleanBondParams = (params: Partial<SearchAndFilterBondParams & BondSort>) => qs.stringify(cleanBondParams(params));

export const cleanBondParamsUrl = (pathName: string, params: Partial<SearchAndFilterBondParams & BondSort>) => `${pathName}?${stringifyCleanBondParams(params)}`;

export type OverrideParams = Partial<SearchAndFilterBondParams & BondSort> | ((params: SearchAndFilterBondParams & BondSort) => Partial<SearchAndFilterBondParams & BondSort>);

export const useMakeCleanBondParamsUrl = () => {
  const pathname = useAtomValue(locationPathnameAtom);
  const qsParams = useAtomValue(bondSearchParamsAtom);
  const getUrl = useCallback(
    (overrideParams: OverrideParams) => {
      if (typeof overrideParams === 'function') {
        return cleanBondParamsUrl(
          pathname,
          overrideParams(qsParams),
        );
      }
      return cleanBondParamsUrl(
        pathname,
        {
          ...qsParams,
          ...overrideParams,
        },
      );
    },
    [pathname, qsParams],
  );
  return getUrl;
};

export const useNavigateCleanBondParamsUrl = () => {
  const getUrl = useMakeCleanBondParamsUrl();
  const history = ouiDom.A.useHistory();
  const navigate = useCallback((type: 'push' | 'replace', overrideParams: OverrideParams) => {
    const url = getUrl(overrideParams);
    if (type === 'replace') {
      history.replace(url);
    } else {
      history.push(url);
    }
  }, [getUrl, history]);
  return navigate;
};

export const useNavigateSearch = () => {
  const navigate = useNavigateCleanBondParamsUrl();
  return useCallback((searchTerm: string | undefined) => {
    navigate('replace', {
      SEARCH_TERM: searchTerm,
      page: 0,
    });
  }, [navigate]);
};
