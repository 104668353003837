import { useGetDateFnsLocale } from '../../../../../../../Components/getDateLocale';
import { useNumberFormatter } from '../../../../../../../Components/NumberDisplay';
import { makeBondSlugTransformFn } from '../../../../bonds/shared/makeBondSlug';
import { useSecuritiesSecurityTransformApi } from '../api';

const intRateFormat = {
  format: {
    style: 'percent' as const,
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  },
  divider: 100,
};

export const useSecuritiesSecurityBondSlug = (props?: {
  maxSIssuerName1Length?: number,
}) => {
  const {
    maxSIssuerName1Length,
  } = props ?? {};
  const intRateFormatter = useNumberFormatter(intRateFormat);
  const locale = useGetDateFnsLocale();
  const transformFn = makeBondSlugTransformFn({
    intRateFormatter,
    locale,
    maxSIssuerName1Length,
  });
  const response = useSecuritiesSecurityTransformApi(transformFn);
  return response;
};
