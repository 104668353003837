import useSWR, { KeyedMutator } from 'swr';
import qs from 'query-string';
import { useFetchWithAuthWithSend } from '../../../shared/utils/fetchWithAuth';
import { ACTOR_DEDUPING_INTERVAL } from '../../../shared';
import { useMemo } from 'react';
import { SecuritiesSearchResponse } from '../type';
import { Atom, useAtomValue } from 'jotai';
import { makeApiUrl } from '../../../../../../aws';
import { getServiceUrl } from '../../../../shared';
import { Securities } from '../../enum';

const useMakeSecuritySearchUrl = (searchTermAtom: Atom<string>) => {
  const searchTerm = useAtomValue(searchTermAtom);
  const params = {
    SEARCH_TERM: `%${searchTerm.toUpperCase().split(' ').join('%')}%`,
  };
  const url = searchTerm !== ''
    ? `${makeApiUrl('actor')}/${getServiceUrl(Securities.List)}?${qs.stringify(params)}`
    : null;
  return url;
};

type BaseUseSecuritiesSearchApiResponse = {
  isLoading: boolean,
  error?: any,
  mutate: KeyedMutator<SecuritiesSearchResponse[]>,
  isValidating: boolean,
}

type UseSecuritiesSearchApiResponse = {
  data: undefined | SecuritiesSearchResponse[],
} & BaseUseSecuritiesSearchApiResponse;

const useSecuritySearchBaseApi = (searchTermAtom: Atom<string>) => {
  const url = useMakeSecuritySearchUrl(searchTermAtom);
  const fetcher = useFetchWithAuthWithSend(true);
  const result = useSWR<SecuritiesSearchResponse[], any>(
    url,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  return result;
};

type FilterPredicate = (securitiesSearch: SecuritiesSearchResponse) => boolean;

type SortPredicate = (prevSecuritiesSearch: SecuritiesSearchResponse, nextSecuritiesSearch: SecuritiesSearchResponse) => number;

type UseSecuritiesSearchApiProps = {
  filterPredicate?: FilterPredicate,
  sortPredicate?: SortPredicate,
  searchTermAtom: Atom<string>,
};

export const useSecuritiesSearchApi = (props: UseSecuritiesSearchApiProps): UseSecuritiesSearchApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
    searchTermAtom,
  } = props;
  const result = useSecuritySearchBaseApi(searchTermAtom);
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    let { data } = result;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.filter((securitySearch) => filterPredicate(securitySearch));
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformArrayFunction<T> = (SecuritiesSearch: SecuritiesSearchResponse[] | undefined) => T

type UseSecuritiesSearchApiTransformProps<T> = {
  transformFunction: TransformArrayFunction<T>,
} & UseSecuritiesSearchApiProps

type UseSecuritiesSearchApiTransformResponse<T> = {
  data: T | undefined,
} & BaseUseSecuritiesSearchApiResponse;

export function useSecuritiesSearchTransformApi<T>(props: UseSecuritiesSearchApiTransformProps<T>): UseSecuritiesSearchApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
    searchTermAtom,
  } = props;
  const response = useSecuritiesSearchApi({
    filterPredicate,
    sortPredicate,
    searchTermAtom,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: (!isLoading && !error) ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseSecuritySearchApiResponse = {
  data: SecuritiesSearchResponse | undefined,
} & BaseUseSecuritiesSearchApiResponse;

export const useSecuritySearchApi = (props: UseSecuritiesSearchApiProps): UseSecuritySearchApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
    searchTermAtom,
  } = props;
  const result = useSecuritySearchBaseApi(searchTermAtom);
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    // eslint-disable-next-line prefer-destructuring
    let data: SecuritiesSearchResponse[] | SecuritiesSearchResponse | undefined = result.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.find((securitySearch) => filterPredicate(securitySearch));
      }
      if (Array.isArray(data)) {
        // eslint-disable-next-line prefer-destructuring
        data = data[0];
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformFunction<T> = (SecuritySearch: SecuritiesSearchResponse | undefined) => T

type UseSecuritySearchApiTransformProps<T> = {
  transformFunction: TransformFunction<T>,
} & UseSecuritiesSearchApiProps;

export function useSecuritySearchTransformApi<T>(props: UseSecuritySearchApiTransformProps<T>): UseSecuritiesSearchApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
    searchTermAtom,
  } = props;
  const response = useSecuritySearchApi({
    filterPredicate,
    sortPredicate,
    searchTermAtom,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: !isLoading && !error ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseSecuritySearchFieldApiResponse<Field extends keyof SecuritiesSearchResponse> = {
  data: SecuritiesSearchResponse[Field] | undefined,
} & BaseUseSecuritiesSearchApiResponse;

type UseSecuritySearchFieldApiProps<Field extends keyof SecuritiesSearchResponse> = {
  field: Field,
} & UseSecuritiesSearchApiProps

export function useSecuritySearchFieldApi<Field extends keyof SecuritiesSearchResponse>(props: UseSecuritySearchFieldApiProps<Field>): UseSecuritySearchFieldApiResponse<Field> {
  const {
    filterPredicate,
    sortPredicate,
    searchTermAtom,
    field,
  } = props;
  const response = useSecuritySearchApi({
    filterPredicate,
    sortPredicate,
    searchTermAtom,
  });
  const result = useMemo(() => {
    const {
      data,
    } = response;
    return {
      ...response,
      data: data ? data[field] : undefined,
    };
  }, [response, field]);
  return result;
}

type UseSecuritySearchFieldTransformApiProps<T, Field extends keyof SecuritiesSearchResponse> = UseSecuritySearchFieldApiProps<Field> & {
  transformFunction: (field: SecuritiesSearchResponse[Field]) => T,
}

type UseSecuritySearchFieldTransformApiResponse<T> = {
  data: T | undefined,
} & BaseUseSecuritiesSearchApiResponse;

export function useSecuritySearchFieldTransformApi<T, Field extends keyof SecuritiesSearchResponse>(props: UseSecuritySearchFieldTransformApiProps<T, Field>): UseSecuritySearchFieldTransformApiResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
    searchTermAtom,
    field,
  } = props;
  const response = useSecuritySearchFieldApi({
    filterPredicate,
    sortPredicate,
    field,
    searchTermAtom,
  });
  const result = useMemo(() => {
    const {
      data,
    } = response;
    return {
      ...response,
      data: data ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}
