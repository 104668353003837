import ouiBase from '@goldwasserexchange/oui';
import { useAccountOrderTransformApiByOpbFordId } from '../api';
import { getFileNameExtensionFromArDoc } from '../../../../shared';

export const useAccountOrdersArDocFileNameExtension = () => {
  const opbFordDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useAccountOrderTransformApiByOpbFordId({
    opbFordDId,
    transformFunction: getFileNameExtensionFromArDoc,
  });
  return response;
};
