import { SecuritiesSecurity } from '../../securities';
import { BondResponse } from '../bond/type';

export const transformAskOrMidPriceFn = (bondsBond: BondResponse | SecuritiesSecurity | undefined) => {
  if (bondsBond == null) {
    return undefined;
  }
  const priceMax = bondsBond['S_QUOTE.PRICE_MAX'];
  const priceClose = bondsBond['S_QUOTE.PRICE_CLOSE'];
  if (priceMax == null && priceClose == null) {
    return undefined;
  }
  if (priceMax != null) {
    return priceMax;
  }
  return priceClose;
};
