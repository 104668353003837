import { GetAccountsPositionsHistoryResponse } from '@goldwasserexchange/actor/rest-services';
import { getFileName } from './getFileName';
import { getFileNameExtension } from '../../../../../../Components/fileTypeIcon';
import { AccountDocument } from '../../../accounts/documents/type';
import { AccountHistory } from '../../../accounts/history/type';
import { AccountsOrder } from '../../../accounts/orders/type';

export const getFileNameExtensionFromArDoc = (data: AccountHistory | AccountDocument | AccountsOrder | GetAccountsPositionsHistoryResponse | undefined) => {
  const fileName = getFileName(data);
  const fileNameExtension = getFileNameExtension(fileName);
  return fileNameExtension;
};
