import Big from 'big.js';
import { roundBigToNearest } from '../../../../../../../utils/roundToNearest';
import { SecuritiesSecurity } from '../../type';
import { useSecuritiesSecurityTransformApi } from '../api';
import { McGrossOpb } from '../utils';

const nearestPct = Big('0.05');
const difference = Big('1.5');
const nearest = Big('0.01');
const minFactor = Big('0.9');
const maxFactor = Big('1.1');

const getSecuritiesSecurityMinMaxPrice = (securitiesSecurity: SecuritiesSecurity | undefined) => {
  if (securitiesSecurity == null) {
    return null;
  }
  const mcGrossOpb = securitiesSecurity['S_TYPE.MC_GROSS_OPB'];
  const priceClose = securitiesSecurity['S_QUOTE.PRICE_CLOSE'];
  if (priceClose == null || mcGrossOpb == null) {
    return null;
  }
  const bigPriceClose = Big(`${priceClose}`);
  if (mcGrossOpb === McGrossOpb.BF_DIV_100 || mcGrossOpb === McGrossOpb.MULT_100) {
    return {
      min: roundBigToNearest(nearestPct, bigPriceClose.minus(difference), 'down').toNumber(),
      max: roundBigToNearest(nearestPct, bigPriceClose.plus(difference), 'up').toNumber(),
    };
  }
  return {
    min: roundBigToNearest(nearest, bigPriceClose.times(minFactor), 'down').toNumber(),
    max: roundBigToNearest(nearest, bigPriceClose.times(maxFactor), 'up').toNumber(),
  };
};

export const useSecuritiesSecurityMinMaxPrice = () => {
  const response = useSecuritiesSecurityTransformApi(getSecuritiesSecurityMinMaxPrice);
  return response;
};
