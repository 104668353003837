import { STypeGrp } from '@goldwasserexchange/oblis-frontend-utils';
import { SecuritiesSecurity } from '../../type';
import { useSecuritiesSecurityTransformApi } from '../api';
import { getMcGrossOpbDivider } from '../sType';

const bonds = [STypeGrp.OBLIGATIONS, STypeGrp.OBLIGATIONS_PERP, STypeGrp.OBLIGATIONS_SUB];

const isBond = (security: SecuritiesSecurity) => bonds.includes(`${security['S_TYPE_GRP.ID']}` as STypeGrp);

export const maxQuantityTransformFnBase = (security: SecuritiesSecurity) => {
  const exRate = security?.['EXRATE_HIS.EXRATE_GES'];
  const quotMul = security?.['S_MARKET.QUOT_MULT'];
  const price = security['S_QUOTE.PRICE_CLOSE'];
  const divider = getMcGrossOpbDivider(security['S_TYPE.MC_GROSS_OPB']);
  const coupure = isBond(security) === true ? Math.max(1000, quotMul) : quotMul;
  const maxEur = 500_000 / ((price ?? divider) / divider);
  const maxCurrency = maxEur / (exRate ?? 1);
  const rangeMax = Math.ceil(maxCurrency / coupure) * coupure;
  return rangeMax;
};

export const maxQuantityTransformFn = (bond: SecuritiesSecurity | undefined) => {
  if (bond == null) {
    return null;
  }
  return maxQuantityTransformFnBase(bond);
};

export const useSecuritiesSecurityMaxQuantity = () => {
  const response = useSecuritiesSecurityTransformApi(maxQuantityTransformFn);
  return response;
};
