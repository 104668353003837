import ouiBase from '@goldwasserexchange/oui';
import { getModalName } from '../../../../shared';
import { useAccountOrderTransformApiByOpbFordId } from '../api';

export const useAccountOrdersArDocModalName = () => {
  const opbFordDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useAccountOrderTransformApiByOpbFordId({
    opbFordDId,
    transformFunction: getModalName,
  });
  return response;
};
