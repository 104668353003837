import { createStore } from 'jotai';
import { createBrowserHistory, createPath } from 'history';
import {
  routing,
  redirect,
} from './router';
import { historyAtomListener } from './rootAtom';
import { headListener } from './head';
import { makeCookieFirstLoadedListener } from './cookieFirst';
import { safeHistory, shouldScrollOnPathChange } from './utils';
import { locationPathnameAtom } from './atoms';

let initialLoad = true;

export const createHistory = () => {
  const sourceHistory = safeHistory(createBrowserHistory());
  const jotaiStore = createStore();
  makeCookieFirstLoadedListener(jotaiStore)();
  const initialSourcePath = createPath(sourceHistory.location);
  const initializedUpdate = redirect(sourceHistory);
  routing(initializedUpdate);
  historyAtomListener(jotaiStore)(initializedUpdate);
  initialLoad = false;
  headListener(initializedUpdate);
  const initialRedirectPath = createPath(initializedUpdate.location);
  if (initialSourcePath !== initialRedirectPath) {
    sourceHistory.replace(initialRedirectPath, initializedUpdate.location.state);
  }
  sourceHistory.listen((currentUpdate) => {
    const sourcePath = createPath(currentUpdate.location);
    const previousPathname = jotaiStore.get(locationPathnameAtom);
    const update = redirect(currentUpdate);
    routing(update);
    historyAtomListener(jotaiStore)(update);
    const pathname = jotaiStore.get(locationPathnameAtom);
    const shouldScroll = shouldScrollOnPathChange({
      initialLoad,
      action: currentUpdate.action,
      previousPathname,
      pathname,
    });
    if (shouldScroll) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    headListener(update);
    const redirectPath = createPath(update.location);
    if (sourcePath !== redirectPath) {
      sourceHistory.replace(redirectPath, update.location.state);
    }
  });

  return {
    sourceHistory,
    jotaiStore,
  };
};

export const {
  sourceHistory: history,
  jotaiStore: store,
} = createHistory();
