import { SecuritiesSecurity } from '../../type';
import { useSecuritiesSecurityTransformApi } from '../api';
import { mcGrossOpbIsPercent } from '../sType';

const getUnit = (securitiesSecurity: SecuritiesSecurity | undefined) => {
  if (securitiesSecurity == null) {
    return undefined;
  }
  if (mcGrossOpbIsPercent(securitiesSecurity['S_TYPE.MC_GROSS_OPB'])) {
    return '%';
  }
  return securitiesSecurity['C_CURRENCY.CODE'];
};

export const useSecuritiesSecurityGetPriceUnit = () => {
  const response = useSecuritiesSecurityTransformApi(getUnit);
  return response;
};
