import { useCallback } from 'react';
import { lmlGet } from '@goldwasserexchange/utils';
import { msg } from '@lingui/macro';
import { MessageDescriptor } from '@lingui/core';
import { useLingui } from '@lingui/react';
import { AccountPosition } from '../../type';
import { getIdkZLang } from '../../../../../../../Components/LmlText';
import { useAccountPositionsTransformApi } from '../api';
import { S_GTYPE_ID_CASH } from '../../../../positions';

const makeSumPctByGype = (language: string, _: (descriptor: MessageDescriptor) => string) => (positions: AccountPosition[] | undefined): {
  x: string,
  y: number,
}[] => {
  if (!positions) {
    return [];
  }
  const idk = getIdkZLang(language);
  return Object.values(positions.reduce((acc, position) => {
    const gesEstimDMcEstimType = position['GES_ESTIM_D.MC_ESTIMTYPE'];
    const gesEstimDPcPortInt = position['GES_ESTIM_D.PC_PORT_INT'];
    if (gesEstimDMcEstimType === 'TITRES') {
      const sGTypeId = position['S_GTYPE.ID'];
      if (!sGTypeId) {
        throw new Error('S_GTYPE.ID shouldn\'nt be null or undefined when GES_ESTIM_D.MC_ESTIMTYPE is \'TITRES\'');
      }
      const group = {
        x: lmlGet(idk, position['S_GTYPE.LML_DESCRIPTION']),
        y: (acc[sGTypeId] || { y: 0 }).y + gesEstimDPcPortInt,
      };
      return {
        ...acc,
        [sGTypeId]: group,
      };
    }
    if (gesEstimDMcEstimType === 'ESPECES') {
      const group = {
        x: _(msg`Liquidités`),
        y: (acc[S_GTYPE_ID_CASH] || { y: 0 }).y + gesEstimDPcPortInt,
      };
      return {
        ...acc,
        [S_GTYPE_ID_CASH]: group,
      };
    }
    throw new Error(`GES_ESTIM_D.MC_ESTIMTYPE should be 'TITRES' or 'ESPECES' and is '${gesEstimDMcEstimType}'`);
  }, {}));
};

export const useAccountPositionsSumPctByGType = (language: string) => {
  const {
    _,
  } = useLingui();
  const sumPctByGType = useCallback(makeSumPctByGype(language, _), [language]);
  const sumPctByGTypeApiResponse = useAccountPositionsTransformApi({
    transformFunction: sumPctByGType,
  });
  return sumPctByGTypeApiResponse;
};
