import { msg } from '@lingui/macro';
import { MessageDescriptor } from '@lingui/core';
import { STypeGrpIds } from '../../../../shared/utils/sTypeGrpIds';
import { BondResponse } from '../type';

export const makeGetSubordinationRank = (_: (descriptor: MessageDescriptor) => string) => (sTypeGrpId: BondResponse['S_TYPE_GRP.ID'] | undefined) => {
  if (sTypeGrpId == null) {
    return undefined;
  }
  switch (sTypeGrpId) {
    case STypeGrpIds.OBLIGATIONS_PERP: return _(msg`Perpétuel`);
    case STypeGrpIds.OBLIGATIONS_SUB: return _(msg`Subordonné`);
    default: return _(msg`Non subordonné`);
  }
};
