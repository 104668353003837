import Big from 'big.js';

export const getRoundingMode = (roundingMode?: 'round' | 'down' | 'up') => {
  if (roundingMode === 'down') {
    return Big.roundDown;
  }
  if (roundingMode === 'up') {
    return Big.roundUp;
  }
  return undefined;
};
