import { Bond } from '@goldwasserexchange/common/lib/bond';
import { useMemo } from 'react';
import { parseActorDate } from '../../../../shared/utils/parseDates';
import { useBondsBondApi } from '../../bond/hooks/api';
import { transformSAccJrsFreqToBondFrequency } from '../../bond/utils/transformSAccJrsFreqToBondFrequency';
import { useBondsCallApi } from '../../calls/hooks/api';
import { nextCallsFilterPredicate } from '../../calls/utils/nextCall';

export const useBondsYtc = () => {
  const {
    data: bond,
    isLoading: bondIsLoading,
    error: bondError,
  } = useBondsBondApi();
  const {
    data: call,
    isLoading: callIsLoading,
    error: callError,
  } = useBondsCallApi({
    filterPredicate: nextCallsFilterPredicate,
  });
  const response = useMemo(() => {
    if (bondIsLoading || callIsLoading) {
      return {
        data: undefined,
        isLoading: true,
        error: undefined,
      };
    }
    if (bondError || callError) {
      return {
        data: undefined,
        isLoading: false,
        error: [bondError, callError],
      };
    }
    if (bond == null || call == null) {
      return {
        data: undefined,
        isLoading: false,
        error: undefined,
      };
    }
    const {
      'S_ACC.INT_RATE': rate,
      'S_ACC_JRS.FREQ': sAccJrsFreq,
      'S_QUOTE.PRICE_CLOSE': price,
      'S_ACC.ISSUE_DATE': issue,
      'S_ACC.OBL_FIRST_CPS': firstCoupon,
    } = bond;
    if (rate == null || issue == null) {
      return {
        data: undefined,
        isLoading: false,
        error: undefined,
      };
    }
    const {
      'EVT_ENTRY_S.EVT_DATE': maturity,
      'EVT_ENTRY_S.CMT_HIST': redemptionString,
    } = call;
    const frequency = transformSAccJrsFreqToBondFrequency(sAccJrsFreq);
    const redemption = parseFloat(redemptionString);
    const bondCalculator = new Bond({
      rate: rate / 100,
      maturity: parseActorDate(maturity),
      redemption,
      frequency,
      issue: parseActorDate(issue),
      firstCoupon: firstCoupon ? parseActorDate(firstCoupon) : undefined,
    });
    let ytc: number | undefined;
    let error: any;
    try {
      ytc = price ? bondCalculator.yield(price) : undefined;
    } catch (err) {
      ytc = undefined;
      error = err;
    }
    return {
      data: ytc,
      isLoading: false,
      error,
    };
  }, [
    bond,
    bondIsLoading,
    bondError,
    call,
    callIsLoading,
    callError,
  ]);
  return response;
};
