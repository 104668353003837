import ouiBase from '@goldwasserexchange/oui';
import { arDocHasFileName } from '../../../../shared';
import { useAccountOrderTransformApiByOpbFordId } from '../api';

export const useAccountOrdersArDocHasFileName = () => {
  const opbFordDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useAccountOrderTransformApiByOpbFordId({
    opbFordDId,
    transformFunction: arDocHasFileName,
  });
  return response;
};
