import { useCallback } from 'react';
import { MessageDescriptor } from '@lingui/core';
import { useLingui } from '@lingui/react';
import { useLinguiLocale } from '../../../../../../../Components/useLinguiLocale';
import { useLmlGet } from '../../../../../shared';
import { BondResponse } from '../../type';
import { makeGetSubordinationRank } from '../../utils';
import { useBondsBondFieldApi, useBondsBondTransformApi } from '../api';

export const useBondsBondGesFcRank1 = () => {
  const response = useBondsBondFieldApi('GES_FC_RANK.LML_DESCRIPTION');
  return response;
};

const makeGetRank = (lmlGet: (lml: string) => string, _: (descriptor: MessageDescriptor) => string) => (bondsBond: BondResponse | undefined) => {
  if (!bondsBond) {
    return undefined;
  }
  const gesFcRankLmlDescription = bondsBond['GES_FC_RANK.LML_DESCRIPTION'];
  if (!gesFcRankLmlDescription) {
    const sTypeGrpId = bondsBond['S_TYPE_GRP.ID'];
    return makeGetSubordinationRank(_)(sTypeGrpId);
  }
  return lmlGet(gesFcRankLmlDescription);
};

export const useBondsBondGesFcRankLmlDescription = () => {
  const locale = useLinguiLocale();
  const lmlGet = useLmlGet(locale);
  const {
    _,
  } = useLingui();
  const getRank = useCallback(makeGetRank(lmlGet, _), [lmlGet, _]);
  const response = useBondsBondTransformApi(getRank);
  return response;
};
