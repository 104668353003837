import useSWR, { KeyedMutator } from 'swr';
import { useMemo } from 'react';
import memoizee from 'memoizee';
import { Accounts } from '../../enum';
import { useAccounOrdersStringifiedParams } from './defaultParams';
import { makeApiUrl } from '../../../../../../aws';
import { getServiceUrl } from '../../../../shared';
import { AccountsOrder } from '../type';
import { useFetchWithAuthWithSend } from '../../../shared/utils/fetchWithAuth';
import { isIdLoading } from '../../../shared';

export const useMakeAccountsOrdersUrl = () => {
  const stringifiedParams = useAccounOrdersStringifiedParams();
  const url = stringifiedParams !== null ? `${makeApiUrl('actor')}/${getServiceUrl(Accounts.Orders)}${stringifiedParams}` : null;
  return url;
};

type BaseUseAccountOrdersApiResponse = {
  isLoading: boolean,
  error?: any,
  mutate: KeyedMutator<AccountsOrder[]>,
  isValidating: boolean,
}

type UseAccountOrdersLinesApiResponse = {
  data: undefined | AccountsOrder[],
} & BaseUseAccountOrdersApiResponse;

type FilterPredicate = (accountOrder: AccountsOrder) => boolean;

type SortPredicate = (prevAccountOrder: AccountsOrder, nextAccountOrder: AccountsOrder) => number;

type UseAccountOrdersApiProps = {
  filterPredicate?: FilterPredicate,
  sortPredicate?: SortPredicate,
};

const useAccountsOrderFetcher = (run = true) => {
  const url = useMakeAccountsOrdersUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountsOrder[], any>(
    run === true ? url : null,
    fetcher,
    {
      refreshInterval: 10_000,
    },
  );
  return result;
};

export const useAccountOrdersLinesApi = (props: UseAccountOrdersApiProps): UseAccountOrdersLinesApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const result = useAccountsOrderFetcher();
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    let { data } = result;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.filter((orderLine) => filterPredicate(orderLine));
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformArrayFunction<T> = (accountOrderLines: AccountsOrder[] | undefined) => T

type UseAccountOrderLinesApiTransformProps<T> = {
  transformFunction: TransformArrayFunction<T>,
} & UseAccountOrdersApiProps

type UseAccountOrderApiTransformResponse<T> = {
  data: T | undefined,
} & BaseUseAccountOrdersApiResponse;

export function useAccountOrderLinesTransformApi<T>(props: UseAccountOrderLinesApiTransformProps<T>): UseAccountOrderApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useAccountOrdersLinesApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: (!isLoading && !error) ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseAccountOrderApiResponse = {
  data: AccountsOrder | undefined,
} & BaseUseAccountOrdersApiResponse;

export const useAccountOrderApi = (props: UseAccountOrdersApiProps): UseAccountOrderApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const result = useAccountsOrderFetcher();
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    // eslint-disable-next-line prefer-destructuring
    let data: AccountsOrder[] | AccountsOrder | undefined = result.data;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.find((history) => filterPredicate(history));
      }
      if (Array.isArray(data)) {
        // eslint-disable-next-line prefer-destructuring
        data = data[0];
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};

type TransformFunction<T> = (accountOrder: AccountsOrder | undefined) => T

type UseAccountOrderApiTransformProps<T> = {
  transformFunction: TransformFunction<T>,
} & UseAccountOrdersApiProps;

export function useAccountOrderTransformApi<T>(props: UseAccountOrderApiTransformProps<T>): UseAccountOrderApiTransformResponse<T> {
  const {
    filterPredicate,
    sortPredicate,
    transformFunction,
  } = props;
  const response = useAccountOrderApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
      isLoading,
      error,
    } = response;
    return {
      ...response,
      data: !isLoading && !error ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

const isOpbFordId = (opbFordDId: string) => (orderLine: AccountsOrder) => `${orderLine['OPB_FORD.ID']}` === opbFordDId;

const findByOpbFordId = (data: AccountsOrder[], hisMvtId: string): AccountsOrder | undefined => data
  .find(isOpbFordId(hisMvtId));

export const memoFindByOpbFordId: typeof findByOpbFordId = memoizee(findByOpbFordId);

type UseAccountOrderApiTransformByOpbFordIdProps<T> = {
  transformFunction: TransformFunction<T>,
  opbFordDId: string,
};

export function useAccountOrderTransformApiByOpbFordId<T>(props: UseAccountOrderApiTransformByOpbFordIdProps<T>): UseAccountOrderApiTransformResponse<T> {
  const {
    transformFunction,
    opbFordDId,
  } = props;
  const opbFordDIdLoading = isIdLoading(opbFordDId);
  const response = useAccountsOrderFetcher(!opbFordDIdLoading);
  const result = useMemo(() => {
    const isLoading = !response.data && !response.error;

    let data: AccountsOrder[] | AccountsOrder | undefined = response.data as AccountsOrder[] | undefined;
    if (data) {
      data = memoFindByOpbFordId(data, opbFordDId);
    }
    return {
      ...response,
      isLoading,
      data: data ? transformFunction(data) : undefined,
    };
  }, [response, transformFunction]);
  return result;
}

type UseAccountOrderFieldApiResponse<Field extends keyof AccountsOrder> = {
  data: AccountsOrder[Field] | undefined,
} & BaseUseAccountOrdersApiResponse;

type UseAccountOrderFieldApiProps<Field extends keyof AccountsOrder> = {
  field: Field,
} & UseAccountOrdersApiProps

export function useAccountOrderFieldApi<Field extends keyof AccountsOrder>(props: UseAccountOrderFieldApiProps<Field>): UseAccountOrderFieldApiResponse<Field> {
  const {
    filterPredicate,
    sortPredicate,
    field,
  } = props;
  const response = useAccountOrderApi({
    filterPredicate,
    sortPredicate,
  });
  const result = useMemo(() => {
    const {
      data,
    } = response;
    return {
      ...response,
      data: data ? data[field] : undefined,
    };
  }, [response, field]);
  return result;
}

type UseAccountOrderApiFieldByOpbFordIdProps<Field extends keyof AccountsOrder> = {
  field: Field,
  sortPredicate?: SortPredicate,
  opbFordDId: string,
};

export function useAccountOrderFieldApiByOpbFordId<Field extends keyof AccountsOrder>(props: UseAccountOrderApiFieldByOpbFordIdProps<Field>): UseAccountOrderFieldApiResponse<Field> {
  const {
    field,
    opbFordDId,
  } = props;
  const opbFordDIdLoading = isIdLoading(opbFordDId);
  const response = useAccountsOrderFetcher(!opbFordDIdLoading);
  const result = useMemo(() => {
    const isLoading = !response.data && !response.error;

    let data: AccountsOrder[] | AccountsOrder | undefined = response.data as AccountsOrder[] | undefined;
    if (data) {
      data = memoFindByOpbFordId(data, opbFordDId);
    }
    return {
      ...response,
      isLoading,
      data: data ? data[field] : undefined,
    };
  }, [response, field, opbFordDId]);
  return result;
}

type TransformFieldFunction<Field extends keyof AccountsOrder, T> = (accountOrder: AccountsOrder[Field]) => T

type UseAccountOrderApiFieldTransformByOpbFordIdProps<Field extends keyof AccountsOrder, T> = {
  field: Field,
  sortPredicate?: SortPredicate,
  transformFunction: TransformFieldFunction<Field, T>,
  opbFordDId: string,
};

export function useAccountOrderFieldTransfomApiByOpbFordId<Field extends keyof AccountsOrder, T>(props: UseAccountOrderApiFieldTransformByOpbFordIdProps<Field, T>): UseAccountOrderApiTransformResponse<T> {
  const {
    field,
    opbFordDId,
    transformFunction,
  } = props;
  const opbFordDIdLoading = isIdLoading(opbFordDId);
  const response = useAccountsOrderFetcher(!opbFordDIdLoading);
  const result = useMemo(() => {
    const isLoading = !response.data && !response.error;

    let data: AccountsOrder[] | AccountsOrder | undefined = response.data as AccountsOrder[] | undefined;
    if (data) {
      data = memoFindByOpbFordId(data, opbFordDId);
    }
    return {
      ...response,
      isLoading,
      data: data ? transformFunction(data[field]) : undefined,
    };
  }, [response, field, transformFunction, opbFordDId]);
  return result;
}
