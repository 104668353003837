export var initialConseilGestionValues = function () { return ({
    trackerAndETFCurrencyRiskAnswer: undefined,
    trackerAndETFCurrencyRiskIsCorrect: undefined,
    trackerAndETFCurrencyRiskWasRetried: false,
    trackerAndETFCurrencyRiskIsCorrected: false,
    trackerAndETFDefinitionAnswer: undefined,
    trackerAndETFDefinitionIsCorrect: undefined,
    trackerAndETFDefinitionWasRetried: false,
    trackerAndETFDefinitionIsCorrected: false,
    trackerAndETFSaleAnswer: undefined,
    trackerAndETFSaleIsCorrect: undefined,
    trackerAndETFSaleWasRetried: false,
    trackerAndETFSaleIsCorrected: false,
    trackerAndETFSyntheticAnswer: undefined,
    trackerAndETFSyntheticIsCorrect: undefined,
    trackerAndETFSyntheticWasRetried: false,
    trackerAndETFSyntheticIsCorrected: false,
    trackerAndETFSyntheticRiskAnswer: undefined,
    trackerAndETFSyntheticRiskIsCorrect: undefined,
    trackerAndETFSyntheticRiskWasRetried: false,
    trackerAndETFSyntheticRiskIsCorrected: false,
}); };
