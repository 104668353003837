import {
  AnyEventObject, assign, createMachine, EventObject, StateNodeConfig,
} from 'xstate';
import { Auth, AuthErrorStrings } from '@aws-amplify/auth';
import { Untracked } from '@hookstate/untracked';
import { AwsRum } from 'aws-rum-web';
import { dataStructure, UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { isEmpty, isNil } from 'ramda';
import { format } from 'date-fns';
import { State } from '@hookstate/core';
import { PostAccountsTransfersBody } from '@goldwasserexchange/actor/rest-services';
import type { CodeDeliveryDetails } from 'amazon-cognito-identity-js';
import { ValidSections } from '../../../history';
import { validateAt, validateAts } from '../../Form/validations/validate';
import { CognitoUserWithChallengeName } from '../type';
import { fetchWithAuth } from '../../../actor/api/Services/shared/utils/fetchWithAuth';
import { makeApiUrl } from '../../../aws';

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    awsRum: AwsRum | undefined,
  }
}
const authPath: keyof Pick<UncastedDataStructure, 'auth'> = 'auth';

const submitType: keyof Pick<UncastedDataStructure, 'submitType'> = 'submitType';

const mfaCodePath: keyof Pick<UncastedDataStructure['auth'], 'mfaCode'> = 'mfaCode';

const fullMfaCodePath = `${authPath}.${mfaCodePath}`;

type Config = {
  userPoolId: string,
  userPoolClientId: string,
  identityPoolId: string,
  identityPoolUnauthenticatedRoleArn: string,
  userBucketName: string,
  singleTableName: string,
  placeIndexName: string,
  appMonitorId: string,
};

export type AuthMachineContext = {
  errorName: null | string,
  errorMessage: null | AuthErrorStrings | string,
  user: null | (CognitoUserWithChallengeName),
  shouldRememberDevice: boolean,
  config: Config | null,
  noRedirect: boolean,
  codeDeliveryDetails: null | CodeDeliveryDetails,
};

const initialContext: AuthMachineContext = {
  errorName: null,
  errorMessage: null,
  user: null,
  shouldRememberDevice: true,
  config: null,
  noRedirect: false,
  codeDeliveryDetails: null,
};

const challengeMachine: (withRemember: boolean) => StateNodeConfig<AuthMachineContext, any, AnyEventObject> = (withRemember: boolean) => ({
  initial: 'initial',
  states: {
    initial: {
      always: [
        {
          target: 'MFAInput',
          cond: 'userContextHasMFAChallenge',
        },
        {
          target: 'NEW_PASSWORD_REQUIRED',
          cond: 'userContextHasNewPasswordChallenge',
        },
        {
          target: 'MFA_SETUP',
          cond: 'userContextHasMFASetupChallenge',
        },
      ],
    },
    MFAInput: {
      initial: 'initializing',
      states: {
        initializing: {
          invoke: {
            id: 'initialisingInputMFACode',
            src: {
              type: 'setValue',
              key: submitType,
              value: 'inputMFACode',
            },
            onDone: 'inputMFACode',
          },
        },
        inputMFACode: {
          on: {
            SUBMIT: {
              target: 'submitting',
              actions: ['destroyErrorMessage', 'saveShouldRememberDevice'],
            },
          },
        },
        submitting: {
          initial: 'confirmSignIn',
          states: {
            confirmSignIn: {
              invoke: {
                id: 'confirmSignIn',
                src: 'confirmSignIn',
                onDone: [
                  ...(withRemember
                    ? [
                      {
                        target: 'rememberDevice',
                        actions: ['assignUserData'],
                        cond: 'shouldRememberDevice',
                      },
                      {
                        target: 'forgetDevice',
                        actions: ['assignUserData'],
                      },
                    ]
                    : [{
                      target: 'destroyMFACode',
                    }]),
                ],
                onError: {
                  target: 'error',
                  actions: ['assignErrorMessage'],
                },
              },
            },
            forgetDevice: {
              invoke: {
                id: 'forgetDevice',
                src: 'forgetDevice',
                onDone: {
                  target: 'destroyMFACode',
                },
                onError: {
                  target: 'destroyMFACode',
                },
              },
            },
            rememberDevice: {
              invoke: {
                id: 'rememberDevice',
                src: 'rememberDevice',
                onDone: {
                  target: 'destroyMFACode',
                },
                onError: {
                  target: 'destroyMFACode',
                },
              },
            },
            error: {
              type: 'final' as const,
            },
            destroyMFACode: {
              invoke: {
                id: 'destroyMFACode',
                src: {
                  type: 'destroyMFACode',
                },
                onDone: 'unTouchMFACode',
              },
            },
            unTouchMFACode: {
              invoke: {
                id: 'unTouchMFACode',
                src: {
                  type: 'unTouchMFACode',
                },
                onDone: 'done',
              },
            },
            done: {
              entry: 'destroyErrorMessage',
              type: 'final' as const,
            },
          },
          onDone: [{
            target: 'inputMFACode',
            cond: 'hasErrorMessage',
          }, {
            target: 'done',
          }],
        },
        done: {
          type: 'final' as const,
        },
      },
      onDone: {
        target: 'done',
      },
    },
    NEW_PASSWORD_REQUIRED: {},
    MFA_SETUP: {},
    done: {
      type: 'final' as const,
    },
  },
});

const submitTypePath: keyof Pick<UncastedDataStructure, 'submitType'> = 'submitType';

const signInMachine: (withRemember: boolean) => StateNodeConfig<AuthMachineContext, any, AnyEventObject> = (withRemember: boolean) => ({
  exit: 'destroyErrorMessage',
  initial: 'initialisingUserNameAndPassword',
  states: {
    initialisingUserNameAndPassword: {
      invoke: {
        id: 'initialisingUserNameAndPassword',
        src: {
          type: 'setValue',
          key: submitType,
          value: 'userNamePasswordSignin',
        },
        onDone: 'inputingUserNameAndPassword',
      },
    },
    inputingUserNameAndPassword: {
      on: {
        SUBMIT: {
          target: 'authenticate',
          actions: 'destroyErrorMessage',
        },
      },
    },
    authenticate: {
      invoke: {
        id: 'signIn',
        src: 'signIn',
        onDone: [
          {
            target: 'challenge',
            cond: 'userEventhasChallenge',
            actions: 'assignUserData',
          },
          {
            target: 'unSetSignInSubmitType',
            actions: 'assignUserData',
          },
        ],
        onError: [{
          target: 'resendConfirmationCode',
          cond: 'isUserNotConfirmedException',
          actions: 'destroyErrorMessage',
        }, {
          target: 'inputingUserNameAndPassword',
          actions: 'assignErrorMessage',
        }],
      },
    },
    challenge: {
      ...challengeMachine(withRemember),
      onDone: 'unSetSignInSubmitType',
      on: {
        BACK: {
          target: 'initialisingUserNameAndPassword',
          actions: 'destroyErrorMessage',
        },
      },
    },
    unSetSignInSubmitType: {
      invoke: {
        id: 'unSetSignInSubmitType',
        src: 'unSetSignInSubmitType',
        onDone: 'done',
      },
    },
    resendConfirmationCode: {
      invoke: {
        src: 'resendConfirmationCode',
        onDone: {
          target: 'confirmSignUp',
          actions: ['destroyErrorMessage'],
        },
        onError: {
          target: 'inputingUserNameAndPassword',
        },
      },
    },
    confirmSignUp: {
      initial: 'initialiseConfirm',
      states: {
        initialiseConfirm: {
          invoke: {
            id: 'initialiseConfirm',
            src: {
              type: 'setValue',
              key: submitTypePath,
              value: 'onboardingSignUpConfirm',
            },
            onDone: {
              target: 'inputSignUpConfirmationCode',
              actions: 'unsetConfirmSignupError',
            },
          },
        },
        inputSignUpConfirmationCode: {
          on: {
            SUBMIT: 'confirmSignup',
            BACK: 'done',
          },
        },
        confirmSignup: {
          invoke: {
            src: 'confirmSignup',
            onDone: {
              target: 'destroyMFACode',
              actions: 'unsetConfirmSignupError',
            },
            onError: {
              target: 'inputSignUpConfirmationCode',
              actions: 'assignConfirmSignupError',
            },
          },
        },
        destroyMFACode: {
          invoke: {
            id: 'destroyMFACode',
            src: {
              type: 'destroyMFACode',
            },
            onDone: 'unTouchMFACode',
          },
        },
        unTouchMFACode: {
          invoke: {
            id: 'unTouchMFACode',
            src: {
              type: 'unTouchMFACode',
            },
            onDone: 'initialiseAutoLogin',
          },
        },
        initialiseAutoLogin: {
          invoke: {
            id: 'initialiseAutoLogin',
            src: {
              type: 'setValue',
              key: submitTypePath,
              value: 'userNamePasswordSignin',
            },
            onDone: {
              target: 'done',
            },
          },
        },
        done: {
          type: 'final' as const,
        },
      },
      onDone: 'authenticate',
    },
    done: {
      type: 'final' as const,
    },
  },
});

const envVarIsNotNil = (envVar: string | undefined | null): envVar is string => !(isEmpty(envVar) || isNil(envVar));

const isCorrectConfig = (config?: Partial<Config>): config is Config => {
  if (typeof config !== 'object') {
    return false;
  }
  const {
    userPoolId,
    userPoolClientId,
    identityPoolId,
    identityPoolUnauthenticatedRoleArn,
    userBucketName,
    singleTableName,
    placeIndexName,
    appMonitorId,
  } = config;
  return envVarIsNotNil(userPoolId)
  && envVarIsNotNil(userPoolClientId)
  && envVarIsNotNil(identityPoolId)
  && envVarIsNotNil(identityPoolUnauthenticatedRoleArn)
  && envVarIsNotNil(userBucketName)
  && envVarIsNotNil(singleTableName)
  && envVarIsNotNil(placeIndexName)
  && envVarIsNotNil(appMonitorId);
};

const getConfig = async () => {
  const userPoolId = process.env.REACT_APP_USER_POOL_ID;
  const userPoolClientId = process.env.REACT_APP_USER_POOL_CLIENT_ID;
  const identityPoolId = process.env.REACT_APP_IDENTITY_POOL_ID;
  const identityPoolUnauthenticatedRoleArn = process.env.REACT_APP_IDENTITY_POOL_UNAUTHENTICATED_ROLE_ARN;
  const userBucketName = process.env.REACT_APP_USER_BUCKET_NAME;
  const singleTableName = process.env.REACT_APP_SINGLE_TABLE_NAME;
  const placeIndexName = process.env.REACT_APP_PLACE_INDEX_NAME;
  const appMonitorId = process.env.REACT_APP_APP_MONITOR_ID;
  let config = {
    userPoolId,
    userPoolClientId,
    identityPoolId,
    identityPoolUnauthenticatedRoleArn,
    userBucketName,
    singleTableName,
    placeIndexName,
    appMonitorId,
  };
  if (isCorrectConfig(config)) {
    return config;
  }
  const result = await fetch('/config.json');
  if (!result.ok) {
    throw new Error('config fetch not ok');
  }
  config = await result.json();
  if (!isCorrectConfig(config)) {
    throw new TypeError('no amplify config');
  }
  return config;
};

const fullUsernamePath: `${keyof Pick<UncastedDataStructure, 'auth'>}.${keyof Pick<UncastedDataStructure['auth'], 'username'>}` = 'auth.username';

const fullPasswordPath: `${keyof Pick<UncastedDataStructure, 'auth'>}.${keyof Pick<UncastedDataStructure['auth'], 'password'>}` = 'auth.password';

export const authMachine = (valueState: State<UncastedDataStructure>, validator, touched, history) => createMachine({
  id: 'auth',
  initial: 'initial',
  context: initialContext,
  states: {
    initial: {
      invoke: {
        id: 'getConfig',
        src: {
          type: 'getConfig',
        },
        onDone: {
          target: 'configure',
          actions: ['assignConfig'],
        },
        onError: 'awaitConfigureRetry',
      },
    },
    configure: {
      invoke: {
        id: 'configure',
        src: {
          type: 'configure',
        },
        onDone: 'isCurrentUserAuthenticated',
        onError: 'awaitConfigureRetry',
      },
    },
    awaitConfigureRetry: {
      after: {
        500: 'initial',
      },
    },
    isCurrentUserAuthenticated: {
      invoke: {
        id: 'isCurrentUserAuthenticated',
        src: 'isCurrentUserAuthenticated',
        onDone: {
          target: 'connected',
          actions: 'assignUserData',
        },
        onError: {
          target: 'disconnected',
          actions: 'destroyUserData',
        },
      },
    },
    disconnected: {
      exit: 'destroyErrorMessage',
      initial: 'initial',
      states: {
        initial: {
          on: {
            '': [{
              target: 'signup',
              cond: 'isLocationSignup',
            }, {
              target: 'initialisingSubmitType',
            }],
          },
        },
        initialisingSubmitType: {
          invoke: {
            id: 'initialisingSubmitType',
            src: {
              type: 'setValue',
              key: submitType,
              value: '',
              onDone: 'setDisconnected',
            },
          },
        },
        setDisconnected: {
          invoke: {
            id: 'setDisconnected',
            src: {
              type: 'setDisconnected',
            },
          },
        },
        signin: {
          ...signInMachine(true),
          onDone: 'checkUser',
        },
        signup: {
          exit: 'destroyErrorMessage',
          initial: 'initialisingSignUp',
          states: {
            initialisingSignUp: {
              invoke: {
                id: 'initialisingSignUp',
                src: {
                  type: 'setValue',
                  key: submitType,
                  value: 'signUp',
                },
                onDone: 'input',
              },
            },
            input: {
              on: {
                SUBMIT: {
                  target: 'submitting',
                  actions: ['destroyErrorMessage'],
                },
              },
            },
            submitting: {
              invoke: {
                id: 'signUp',
                src: 'signUp',
                onDone: {
                  target: 'authenticate',
                  actions: ['assingSignUpUserData'],
                },
                onError: {
                  target: 'input',
                  actions: ['assignErrorMessage'],
                },
              },
            },
            authenticate: {
              invoke: {
                id: 'signIn',
                src: 'signIn',
                onDone: [
                  {
                    target: 'challenge',
                    cond: 'userEventhasChallenge',
                    actions: 'assignUserData',
                  },
                  {
                    target: 'cleanSignup',
                    actions: 'assignUserData',
                  },
                ],
                onError: {
                  target: 'input',
                  actions: 'assignErrorMessage',
                },
              },
            },
            challenge: {
              ...challengeMachine(false),
              onDone: 'cleanSignup',
            },
            cleanSignup: {
              invoke: {
                id: 'cleanSignup',
                src: 'cleanSignup',
                onDone: {
                  target: 'unsetSignupSubmitType',
                },
              },
            },
            unsetSignupSubmitType: {
              invoke: {
                id: 'unsetSignupSubmitType',
                src: {
                  type: 'setValue',
                  key: submitType,
                  value: '',
                },
                onDone: 'done',
              },
            },
            done: {
              type: 'final' as const,
            },
          },
          on: {
            EXIT_SIGN_UP: '.cleanSignup',
          },
          onDone: 'checkUser',
        },
        forgotPassword: {
          exit: 'destroyErrorMessage',
          initial: 'initialisingForgotPasswordInputUserName',
          states: {
            initialisingForgotPasswordInputUserName: {
              invoke: {
                id: 'initialisingForgotPasswordInputUserName',
                src: {
                  type: 'setValue',
                  key: submitType,
                  value: 'forgotPasswordInputUserName',
                },
                onDone: 'inputUserName',
              },
            },
            inputUserName: {
              on: {
                BACK: {
                  target: 'resetForgotPassword',
                },
                SUBMIT: {
                  target: 'submittingUserName',
                  actions: ['destroyErrorMessage'],
                },
              },
            },
            submittingUserName: {
              invoke: {
                id: 'forgotPasswordSubmitUserName',
                src: 'forgotPasswordSubmitUserName',
                onDone: {
                  target: 'initialisingForgotPasswordCodeAndNewPassword',
                  actions: 'assignCodeDelivery',
                },
                onError: [
                  {
                    target: 'resendConfirmationCode',
                    cond: 'cannotReset',
                  },
                  {
                    target: 'inputUserName',
                    actions: ['assignErrorMessage'],
                  },
                ],
              },
            },
            resendConfirmationCode: {
              invoke: {
                src: 'resendConfirmationCode',
                onDone: {
                  target: 'confirmSignUp',
                  actions: ['destroyErrorMessage'],
                },
                onError: [
                  {
                    // we use this target in the forgot email flow because if we tried the resend confirmation code and it failed it means that we don't have a confirmed email
                    target: 'inputUserName',
                    cond: 'hasNoConfirmedEmailForForgotPassword',
                    actions: ['assignMissingEmailErrorMessage'],
                  },
                  {
                    target: 'inputUserName',
                    actions: ['assignErrorMessage'],
                  },
                ],
              },
            },
            confirmSignUp: {
              initial: 'initialiseConfirm',
              states: {
                initialiseConfirm: {
                  invoke: {
                    id: 'initialiseConfirm',
                    src: {
                      type: 'setValue',
                      key: submitTypePath,
                      value: 'onboardingSignUpConfirm',
                    },
                    onDone: {
                      target: 'inputSignUpConfirmationCode',
                      actions: 'unsetConfirmSignupError',
                    },
                  },
                },
                inputSignUpConfirmationCode: {
                  on: {
                    SUBMIT: 'confirmSignup',
                    BACK: 'done',
                  },
                },
                confirmSignup: {
                  invoke: {
                    src: 'confirmSignup',
                    onDone: {
                      target: 'destroyMFACode',
                      actions: 'unsetConfirmSignupError',
                    },
                    onError: {
                      target: 'inputSignUpConfirmationCode',
                      actions: 'assignConfirmSignupError',
                    },
                  },
                },
                destroyMFACode: {
                  invoke: {
                    id: 'destroyMFACode',
                    src: {
                      type: 'destroyMFACode',
                    },
                    onDone: 'unTouchMFACode',
                  },
                },
                unTouchMFACode: {
                  invoke: {
                    id: 'unTouchMFACode',
                    src: {
                      type: 'unTouchMFACode',
                    },
                    onDone: 'initialiseAutoLogin',
                  },
                },
                initialiseAutoLogin: {
                  invoke: {
                    id: 'initialiseAutoLogin',
                    src: {
                      type: 'setValue',
                      key: submitTypePath,
                      value: 'userNamePasswordSignin',
                    },
                    onDone: {
                      target: 'done',
                    },
                  },
                },
                done: {
                  type: 'final' as const,
                },
              },
              onDone: 'submittingUserName',
            },
            initialisingForgotPasswordCodeAndNewPassword: {
              invoke: {
                id: 'initialisingForgotPasswordCodeAndNewPassword',
                src: {
                  type: 'setValue',
                  key: submitType,
                  value: 'forgotPasswordInputCodeAndNewPassword',
                },
                onDone: 'inputCodeAndNewPassword',
              },
            },
            inputCodeAndNewPassword: {
              on: {
                BACK: {
                  target: 'inputUserName',
                  actions: 'destroyErrorMessage',
                },
                SUBMIT: {
                  target: 'submittingCodeAndNewPassword',
                  actions: 'destroyErrorMessage',
                },
              },
            },
            submittingCodeAndNewPassword: {
              invoke: {
                id: 'forgotPasswordSubmitCodeAndNewPassword',
                src: 'forgotPasswordSubmitCodeAndNewPassword',
                onDone: {
                  target: 'unsetForgotPasswordSubmitType',
                  actions: ['assingUserName', 'destroyCodeDeliveryDetails'],
                },
                onError: {
                  target: 'inputCodeAndNewPassword',
                  actions: 'assignErrorMessage',
                },
              },
            },
            resetForgotPassword: {
              invoke: {
                id: 'resetForgotPassword',
                src: 'resetForgotPassword',
                onDone: 'done',
                onError: 'done',
              },
            },
            unsetForgotPasswordSubmitType: {
              invoke: {
                id: 'unsetForgotPasswordSubmitType',
                src: {
                  type: 'setValue',
                  key: submitType,
                  value: '',
                },
                onDone: 'done',
              },
            },
            done: {
              type: 'final' as const,
            },
          },
          onDone: {
            target: '#auth.disconnected.signin',
          },
        },
        checkUser: {
          invoke: {
            id: 'isCurrentUserAuthenticated',
            src: 'isCurrentUserAuthenticated',
            onDone: {
              target: 'done',
              actions: 'assignUserData',
            },
            onError: {
              target: 'initial',
            },
          },
        },
        done: {
          type: 'final' as const,
        },
      },
      on: {
        SIGN_IN: '.signin',
        SIGN_UP: '.signup',
        FORGOT_PASSWORD: '.forgotPassword',
        CHECK: '.checkUser',
      },
      onDone: 'connected',
    },
    connected: {
      initial: 'checkUser',
      states: {
        checkUser: {
          invoke: {
            id: 'isCurrentUserAuthenticated',
            src: 'isCurrentUserAuthenticated',
            onDone: {
              target: 'setUsernameFromCognitoUser',
            },
            onError: {
              target: 'signout',
            },
          },
        },
        setUsernameFromCognitoUser: {
          invoke: {
            id: 'setUsernameFromCognitoUser',
            src: 'setUsernameFromCognitoUser',
            onDone: 'initial',
            onError: 'signout',
          },
        },
        initial: {
          on: {
            CHANGE_PASSWORD: 'changePassword',
            TRANSFER: 'transfer',
            SIGN_OUT: 'signout',
            REAUTH: {
              target: 'reAuth',
            },
            DISCONNECT: {
              target: 'signout',
              actions: ['destroyUserData', 'setNoRedirect'],
            },
          },
        },
        reAuth: {
          ...signInMachine(true),
          onDone: 'checkUser',
          on: {
            SIGN_OUT: {
              target: 'signout',
              actions: 'setNoRedirect',
            },
            FORGOT_PASSWORD: {
              target: '#auth.disconnected.forgotPassword',
            },
          },
        },
        signout: {
          invoke: {
            id: 'signOut',
            src: 'signOut',
            onDone: [{
              target: 'redirectToHome',
              cond: 'isLocationOnlineOrProfileAndRedirect',
              actions: 'destroyUserData',
            }, {
              target: 'done',
              actions: ['destroyUserData', 'setRedirect'],
            }],
            onError: {
              target: 'redirectToHome',
              cond: 'isLocationOnlineOrProfileAndRedirect',
              actions: 'destroyUserData',
            },
          },
        },
        redirectToHome: {
          invoke: {
            id: 'redirectToHome',
            src: 'redirectToHome',
            onDone: {
              target: 'done',
            },
            onError: {
              target: 'done',
            },
          },
        },
        changePassword: {
          initial: 'initialisingChangePassword',
          states: {
            initialisingChangePassword: {
              invoke: {
                id: 'initialisingForgotPasswordCodeAndNewPassword',
                src: {
                  type: 'setValue',
                  key: submitType,
                  value: 'changePassword',
                },
                onDone: 'input',
              },
            },
            input: {
              on: {
                SUBMIT: {
                  target: 'submitting',
                  actions: 'destroyErrorMessage',
                },
              },
            },
            submitting: {
              invoke: {
                id: 'changePassword',
                src: 'changePassword',
                onDone: {
                  target: 'unsettingSubmitType',
                },
                onError: {
                  target: 'input',
                  actions: 'assignChangePasswordErrorMessage',
                },
              },
            },
            unsettingSubmitType: {
              invoke: {
                id: 'initialisingForgotPasswordCodeAndNewPassword',
                src: {
                  type: 'setValue',
                  key: submitType,
                  value: '',
                },
                onDone: 'success',
              },
            },
            unsettingSubmitTypeBeforeSignout: {
              invoke: {
                id: 'initialisingForgotPasswordCodeAndNewPassword',
                src: {
                  type: 'setValue',
                  key: submitType,
                  value: '',
                },
                onDone: '#auth.connected.signout',
              },
            },
            success: {
              on: {
                VALIDATE_SUCCESS: 'done',
              },
            },
            done: {
              type: 'final' as const,
            },
          },
          on: {
            EXIT_CHANGE_PASSWORD: '.unsettingSubmitType',
            SIGN_OUT: '.unsettingSubmitTypeBeforeSignout',
          },
          onDone: 'checkUser',
        },
        transfer: {
          initial: 'initialisingTransfer',
          states: {
            initialisingTransfer: {
              invoke: {
                id: 'initialisingTransfer',
                src: {
                  type: 'setValue',
                  key: submitType,
                  value: 'transfer',
                },
                onDone: 'inputTransfer',
              },
            },
            inputTransfer: {
              on: {
                SUBMIT: {
                  target: 'submittingTransfer',
                  actions: 'destroyErrorMessage',
                },
              },
            },
            initialisingRetryTransfer: {
              invoke: {
                id: 'initialisingRetryTransfer',
                src: {
                  type: 'setValue',
                  key: submitType,
                  value: 'transfer',
                },
                onDone: 'submittingTransfer',
              },
            },
            submittingTransfer: {
              invoke: {
                id: 'submitTransfer',
                src: {
                  type: 'submitTransfer',
                },
                onDone: [
                  {
                    target: 'inputTransfer',
                    actions: 'setMinLinesErrorMessage',
                    cond: 'noLines',
                  },
                  {
                    target: 'inputTransfer',
                    actions: 'assignErrorMessage',
                    cond: 'notMinLines',
                  },
                  {
                    target: 'success',
                    actions: 'destroyErrorMessage',
                  }],
                onError: [{
                  target: 'transferSignIn',
                  cond: 'is401Error',
                  actions: 'destroyErrorMessage',
                }, {
                  target: '#auth.connected.done',
                  cond: 'isCognitoError',
                  actions: ['destroyErrorMessage', 'destroyUserData'],
                }, {
                  target: 'inputTransfer',
                  actions: 'assignErrorMessage',
                }],
              },
            },
            transferSignIn: {
              ...signInMachine(false),
              onDone: 'initialisingRetryTransfer',
            },
            success: {
              on: {
                NEW_TRANSFER: 'newTransfer',
                EXIT_TRANSFER: 'resetTransfer',
              },
            },
            newTransfer: {
              invoke: {
                id: 'resetTransfer',
                src: 'resetTransfer',
                onDone: 'initialisingTransfer',
              },
            },
            resetTransfer: {
              invoke: {
                id: 'resetTransfer',
                src: 'resetTransfer',
                onDone: 'unsettingTransfer',
              },
            },
            unsettingTransfer: {
              invoke: {
                id: 'unsettingTransfer',
                src: {
                  type: 'setValue',
                  key: submitType,
                  value: '',
                },
                onDone: 'done',
              },
            },
            unsettingTransferBeforeSignout: {
              invoke: {
                id: 'unsettingTransfer',
                src: {
                  type: 'setValue',
                  key: submitType,
                  value: '',
                },
                onDone: '#auth.connected.signout',
              },
            },
            done: {
              type: 'final' as const,
            },
          },
          on: {
            EXIT_TRANSFER: '.unsettingTransfer',
            SIGN_OUT: '.unsettingTransferBeforeSignout',
            REAUTH: {
              target: '#auth.connected.reAuth',
            },
            DISCONNECT: {
              target: '#auth.connected.signout',
              actions: ['destroyUserData', 'setNoRedirect'],
            },
          },
          onDone: 'checkUser',
        },
        done: {
          type: 'final' as const,
        },
      },
      on: {
        CHECK: '.checkUser',
      },
      onDone: 'disconnected',
    },
  },
}).withConfig({
  guards: {
    isCognitoError: (_, event) => event?.data?.message === 'no user session' || event?.data?.message === 'no user tokens',
    userEventhasChallenge: (_, event) => (
      event.data.challengeName === 'SMS_MFA'
      || event.data.challengeName === 'SOFTWARE_TOKEN_MFA'
      || event.data.challengeName === 'NEW_PASSWORD_REQUIRED'
      || event.data.challengeName === 'MFA_SETUP'
    ),
    userContextHasMFAChallenge: (context) => (
      !!context.user
      && (
        context.user.challengeName === 'SMS_MFA'
        || context.user.challengeName === 'SOFTWARE_TOKEN_MFA'
      )
    ),
    isUserNotConfirmedException: (_, event) => event.data.name === 'UserNotConfirmedException',
    userContextHasNewPasswordChallenge: (context) => !!context.user && context.user.challengeName === 'NEW_PASSWORD_REQUIRED',
    userContextHasMFASetupChallenge: (context) => !!context.user && context.user.challengeName === 'MFA_SETUP',
    // detect the error on the resendConfirmationCode step in the forgot password step that we use as information to know the user is missing a confirmed email
    // we use this error because it means that we tried to check if the user signup should be confirmed and if it's not the case we probably don't have a confirmed email
    hasNoConfirmedEmailForForgotPassword: (_, event) => event.data.name === 'InvalidParameterException' && event.data.message === 'User is already confirmed.',
    cannotReset: (_, event) => event.data.name === 'InvalidParameterException' && event.data.message === 'Cannot reset password for the user as there is no registered/verified email or phone_number',
    hasErrorMessage: (context) => context.errorMessage !== null,
    shouldRememberDevice: (context) => context.shouldRememberDevice,
    is401Error: (_, event) => event?.data?.status === 401,
    noLines: (_, event) => event?.data?.ids?.length === 0 || event?.data?.insertedLines === 0,
    notMinLines: (_, event) => (event.data && (isNil(event.data.ids) && isNil(event.data.insertedLines))),
    isLocationSignup: (_, __) => {
      const { pathname } = window.location;
      const section = pathname.split('/')[2] ?? ValidSections.HOME;
      return section === ValidSections.SIGN_UP;
    },
    isLocationOnlineOrProfileAndRedirect: (context) => {
      const {
        noRedirect,
      } = context;
      if (noRedirect === true) {
        return false;
      }
      const { pathname } = window.location;
      const section = pathname.split('/')[2] ?? ValidSections.HOME;
      return section === ValidSections.ONLINE || section === ValidSections.SECURITY;
    },
  },
  services: {
    setValue: async (_, __, meta) => {
      const {
        src: {
          key,
          value,
        },
      } = meta;
      const state = valueState.nested(key);
      state.set(value);
      await Promise.resolve(true);
    },
    setDisconnected: async () => {
      valueState.auth.connected.set(false);
      return true;
    },
    getConfig,
    configure: async (context) => {
      const {
        config,
      } = context;
      if (!config) {
        throw new Error('no config');
      }
      const {
        userPoolId,
        userPoolClientId,
        identityPoolId,
        identityPoolUnauthenticatedRoleArn,
        appMonitorId,
      } = config;
      try {
        window.awsRum = new AwsRum(appMonitorId, '1.0.0', 'eu-west-1', {
          sessionSampleRate: 1,
          identityPoolId,
          guestRoleArn: identityPoolUnauthenticatedRoleArn,
          endpoint: 'https://dataplane.rum.eu-west-1.amazonaws.com',
          telemetries: ['errors'],
          allowCookies: false,
        });
      } catch (err) {} // eslint-disable-line no-empty
      return Auth.configure({
        Auth: {
          userPoolId,
          region: 'eu-west-1',
          userPoolWebClientId: userPoolClientId,
          identityPoolId,
        },
      });
    },
    isCurrentUserAuthenticated: () => Auth.currentAuthenticatedUser(),
    signIn: async (context) => {
      const {
        config,
      } = context;
      if (!config) {
        throw new Error('no config');
      }
      const {
        userPoolId,
        userPoolClientId,
        identityPoolId,
      } = config;
      Auth.configure({
        Auth: {
          userPoolId,
          region: 'eu-west-1',
          userPoolWebClientId: userPoolClientId,
          identityPoolId,
          authenticationFlowType: 'USER_SRP_AUTH',
        },
      });
      try { // Always try to sign out first before signing in
        await Auth.signOut();
      // eslint-disable-next-line no-empty
      } catch (err) {

      }
      touched.nested(fullUsernamePath).set(true);
      touched.nested(fullPasswordPath).set(true);
      const values = Untracked(valueState).get();
      const [
        userName,
        password,
      ] = await validateAts({
        validator,
        paths: [fullUsernamePath, fullPasswordPath],
        values,
      });
      return Auth.signIn(userName, password);
    },
    setUsernameFromCognitoUser: async (context) => {
      const { user } = context;
      if (user) {
        const username = user.getUsername();
        valueState.auth.username.set(username);
      }
      return true;
    },
    resendConfirmationCode: async () => {
      const values = Untracked(valueState).get();
      touched.nested(fullUsernamePath).set(true);
      const username = await validateAt({ validator, path: fullUsernamePath, values });
      return Auth.resendSignUp(username);
    },
    destroyMFACode: async () => {
      Untracked(valueState.auth.mfaCode).set('');
      return true;
    },
    confirmSignup: async () => {
      const values = Untracked(valueState).get();
      const mfaPath = valueState.auth.mfaCode.path.join('.');
      touched.nested(mfaPath).set(true);
      const [
        username,
        code,
      ] = await Promise.all([
        validateAt({
          validator,
          path: fullUsernamePath,
          values,
        }),
        validateAt({
          validator,
          path: mfaPath,
          values,
        }),
      ]);
      return Auth.confirmSignUp(
        username,
        code,
      );
    },
    confirmSignIn: (context) => {
      const mfaPath = valueState.auth.mfaCode.path.join('.');
      touched.nested(mfaPath).set(true);
      touched.nested(valueState.auth.rememberDevice.path.join('.')).set(true);
      if (context.user?.challengeName === 'SMS_MFA' || context.user?.challengeName === 'SOFTWARE_TOKEN_MFA') {
        const values = Untracked(valueState).get();
        const {
          user,
        } = context;
        const {
          challengeName,
        } = context.user;
        return validateAt({ validator, path: mfaPath, values }).then((code) => Auth.confirmSignIn(
          user,
          code,
          challengeName,
        ));
      }
      return Promise.reject(new Error('not in MFA state'));
    },
    unSetSignInSubmitType: async () => {
      const passwordState = valueState.auth.password;
      passwordState.set('');
      const submitTypeState = valueState.submitType;
      submitTypeState.set('');
      touched.nested(fullPasswordPath).set(false);
      await Promise.resolve(true);
    },
    rememberDevice: () => Auth.rememberDevice(),
    forgetDevice: () => Auth.fetchDevices().catch(() => Promise.resolve(true)),
    unTouchMFACode: async () => {
      touched.nested(fullMfaCodePath).set(false);
      return true;
    },

    signOut: async (_, event) => {
      const global = event?.payload?.global ?? false;
      await Auth.currentAuthenticatedUser();
      const response = await Auth.signOut({ global });
      touched.nested(fullPasswordPath).set(false);
      return response;
    },
    redirectToHome: () => {
      history.push('/');
      return Promise.resolve(true);
    },

    changePassword: (_, __) => {
      const changedPasswordPath = valueState.auth.changedPassword.path.join('.');
      const changedPasswordRepeatPath = valueState.auth.changedPasswordRepeat.path.join('.');
      touched.nested(fullPasswordPath).set(true);
      touched.nested(changedPasswordPath).set(true);
      touched.nested(changedPasswordRepeatPath).set(true);
      return Auth.currentAuthenticatedUser()
        .then((user) => {
          const values = Untracked(valueState).get();
          return validateAts({
            validator,
            paths: [fullPasswordPath, changedPasswordPath, changedPasswordRepeatPath],
            values,
          }).then((castedValue) => Auth.changePassword(user, castedValue[0], castedValue[1]).then((changePasswordResult) => {
            Untracked(valueState.auth.password).set('');
            Untracked(valueState.auth.changedPassword).set('');
            Untracked(valueState.auth.changedPasswordRepeat).set('');
            touched.nested(fullPasswordPath).set(false);
            touched.nested(changedPasswordPath).set(false);
            touched.nested(changedPasswordRepeatPath).set(false);
            return Promise.resolve(changePasswordResult);
          }));
        });
    },

    forgotPasswordSubmitUserName: async (_, __) => {
      const recaptchaPath = valueState.auth.recaptcha.path.join('.');
      touched.nested(fullUsernamePath).set(true);
      touched.nested(recaptchaPath).set(true);
      const values = Untracked(valueState).get();
      const [
        username,
        recaptcha,
      ] = await validateAts({
        validator,
        paths: [fullUsernamePath, recaptchaPath],
        values,
      });
      try { // Always try to sign out first before initiating forgot password
        await Auth.signOut();
      // eslint-disable-next-line no-empty
      } catch (err) {

      }
      const response = await Auth.forgotPassword(username, { recaptcha });
      valueState.auth.recaptcha.set('');
      touched.nested(recaptchaPath).set(false);
      return response;
    },

    forgotPasswordSubmitCodeAndNewPassword: (_, __) => {
      const mfaPath = valueState.auth.mfaCode.path.join('.');
      const newPasswordPath = valueState.auth.newPassword.path.join('.');
      const newPasswordRepeatPath = valueState.auth.newPasswordRepeat.path.join('.');
      touched.nested(mfaPath).set(true);
      touched.nested(newPasswordPath).set(true);
      touched.nested(newPasswordRepeatPath).set(true);
      const values = Untracked(valueState).get();
      return validateAts({
        validator,
        paths: [fullUsernamePath, mfaPath, newPasswordPath, newPasswordRepeatPath],
        values,
      })
        .then((v) => Auth.forgotPasswordSubmit(v[0], v[1], v[2]).then((r) => {
          valueState.auth.mfaCode.set('');
          valueState.auth.newPassword.set('');
          valueState.auth.newPasswordRepeat.set('');
          touched.nested(mfaPath).set(false);
          touched.nested(newPasswordPath).set(false);
          touched.nested(valueState.auth.newPasswordRepeat.path.join('.')).set(false);
          return Promise.resolve(r);
        }));
    },
    resetForgotPassword: () => {
      valueState.auth.mfaCode.set('');
      valueState.auth.newPassword.set('');
      valueState.auth.newPasswordRepeat.set('');
      touched.nested(valueState.auth.mfaCode.path.join('.')).set(false);
      touched.nested(valueState.auth.newPassword.path.join('.')).set(false);
      touched.nested(valueState.auth.newPasswordRepeat.path.join('.')).set(false);
      return Promise.resolve(true);
    },
    signUp: (_, event) => {
      const birthdatePath = valueState.auth.birthdate.path.join('.');
      const passwordRepeatPath = valueState.auth.passwordRepeat.path.join('.');
      const accountPath = valueState.auth.account.path.join('.');
      const recaptchaPath = valueState.auth.recaptcha.path.join('.');
      touched.nested(fullUsernamePath).set(true);
      touched.nested(fullPasswordPath).set(true);
      touched.nested(passwordRepeatPath).set(true);
      touched.nested(birthdatePath).set(true);
      touched.nested(accountPath).set(true);
      touched.nested(recaptchaPath).set(true);
      const values = Untracked(valueState).get();
      return validateAts({
        validator,
        paths: [fullUsernamePath, fullPasswordPath, passwordRepeatPath, birthdatePath, accountPath, recaptchaPath],
        values,
      })
        .then((v) => Auth.signUp({
          username: v[0],
          password: v[1],
          attributes: {
            locale: event.locale,
            phone_number: v[0],
            birthdate: format(v[3], 'yyyy-MM-dd'),
          },
          validationData: {
            account: v[4],
            recaptcha: v[5],
          },
        }));
    },
    cleanSignup: () => {
      const usersState = valueState.onboarding.users;
      const users = Untracked(usersState).get();
      const currentUserIndex = dataStructure.T_ADD.fields.CURRENT.hooks.getCurrentUserIndexFromUsers(users);
      const currentUserState = usersState[currentUserIndex];
      const birthdateState = currentUserState.BIRTH_DATE;
      const birthdatePath = birthdateState.path.join('.');
      valueState.auth.password.set('');
      valueState.auth.passwordRepeat.set('');
      valueState.auth.account.set('');
      valueState.auth.recaptcha.set('');
      touched.nested(fullUsernamePath).set(false);
      touched.nested(fullPasswordPath).set(false);
      touched.nested(valueState.auth.passwordRepeat.path.join('.')).set(false);
      touched.nested(birthdatePath).set(false);
      touched.nested(valueState.auth.account.path.join('.')).set(false);
      touched.nested(valueState.auth.recaptcha.path.join('.')).set(false);
      return Promise.resolve(true);
    },
    submitTransfer: async () => {
      const values = Untracked(valueState).get();
      const tAddIdPath = valueState.tAddId.path.join('.');
      const tAccIdPath = valueState.tAccId.path.join('.');
      const currencyPath = valueState.transfer.currency.path.join('.');
      const ibanPath = valueState.transfer.iban.path.join('.');
      const amountPath = valueState.transfer.amount.path.join('.');
      const communicationPath = valueState.transfer.communication.path.join('.');
      touched.nested(currencyPath).set(true);
      touched.nested(ibanPath).set(true);
      touched.nested(amountPath).set(true);
      touched.nested(communicationPath).set(true);
      const [
        tAddId,
        tAccId,
        currency,
        iban,
        amount,
        communication,
      ] = await validateAts({
        validator,
        paths: [tAddIdPath, tAccIdPath, currencyPath, ibanPath, amountPath, communicationPath],
        values,
      });
      let body = '';
      const transfer: PostAccountsTransfersBody = {
        'T_ADD.ID': tAddId,
        'T_ACC.ID': tAccId,
        'C_CURRENCY.CODE': currency,
        'HIS_PAIE.MONTANT': amount,
        'T_REL_FIN.ID': iban,
        'HIS_PAIE.REF_PART': communication,
      };
      body = JSON.stringify([transfer]);
      return fetchWithAuth(`${makeApiUrl('actor')}/accounts.transfers`, {
        withAccessToken: true,
        method: 'POST',
        body,
      });
    },
    resetTransfer: async () => {
      const currencyPath = valueState.transfer.currency.path.join('.');
      const ibanPath = valueState.transfer.iban.path.join('.');
      const amountPath = valueState.transfer.amount.path.join('.');
      const communicationPath = valueState.transfer.communication.path.join('.');
      valueState.transfer.currency.set('');
      valueState.transfer.iban.set('');
      valueState.transfer.amount.set('');
      valueState.transfer.communication.set('');
      touched.nested(currencyPath).set(false);
      touched.nested(ibanPath).set(false);
      touched.nested(amountPath).set(false);
      touched.nested(communicationPath).set(false);
      await Promise.resolve(true);
    },
  },
  actions: {
    assignCodeDelivery: assign({
      codeDeliveryDetails: (_, event: EventObject & { data: { CodeDeliveryDetails: CodeDeliveryDetails }}) => event.data.CodeDeliveryDetails,
    }),
    destroyCodeDeliveryDetails: assign({
      codeDeliveryDetails: (_) => null,
    }),
    assignUserData: assign({
      user: (_, event) => event.data,
    }),
    assingSignUpUserData: assign({
      user: (_, event) => event.user,
    }),
    destroyUserData: assign({

      user: (_, __) => null,
    }),
    assignConfig: assign({
      config: (_, event) => event.data,
    }),
    assignErrorMessage: assign({
      errorName: (_, event) => event.data.name,
      errorMessage: (_, event) => event.data.message,
    }),
    // this error is used in the forgot password flow to create an error for the missing or unconfirmed email after trying the resend confirmation code step
    assignMissingEmailErrorMessage: assign({
      errorName: (_, event) => event.data.name,
      errorMessage: () => 'Missing email for forgot password' as any,
    }),
    assignChangePasswordErrorMessage: assign({
      errorName: (_, event) => (event.data.message === 'Incorrect username or password.' ? 'Incorrect password' : event.data.name),
      errorMessage: (_, event) => (event.data.message === 'Incorrect username or password.' ? 'Incorrect password.' : event.data.message),
    }),
    setMinLinesErrorMessage: assign({

      errorName: (_, __) => 'minLines',
      errorMessage: (_, event) => event.data.message,
    }),
    destroyErrorMessage: assign({

      errorName: (_, __) => null,

      errorMessage: (_, __) => null,
    }),
    saveShouldRememberDevice: assign({

      shouldRememberDevice: (_, __) => {
        const remember = valueState.auth.rememberDevice.get();
        return remember === '1';
      },
    }),
    setNoRedirect: assign<AuthMachineContext>({
      noRedirect: () => true,
    }),
    setRedirect: assign<AuthMachineContext>({
      noRedirect: () => false,
    }),
  },
});

export type AuthState = ReturnType<typeof authMachine>;
