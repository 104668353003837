import { useCallback } from 'react';
import { useLingui } from '@lingui/react';
import { getDeviceType, useFilterByPrimaryDimensionDeviceId, makeGetDeviceTypeTranslation } from '../utils';
import { usePersonDeviceTransformApi } from './api';

export const usePersonDevicesType = () => {
  const filterPredicate = useFilterByPrimaryDimensionDeviceId();
  const result = usePersonDeviceTransformApi({
    transformFunction: getDeviceType,
    filterPredicate,
  });
  return result;
};

export const usePersonDevicesTypeWithTranslation = () => {
  const filterPredicate = useFilterByPrimaryDimensionDeviceId();
  const {
    _,
  } = useLingui();
  const transformFunction = useCallback(makeGetDeviceTypeTranslation(_), [_]);
  const result = usePersonDeviceTransformApi({
    transformFunction,
    filterPredicate,
  });
  return result;
};
