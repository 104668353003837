import { GetBondsBondResponse } from '@goldwasserexchange/actor/rest-services';
import { useCallback } from 'react';
import { useAuthIsSettle } from '../../../../../../../Components/Auth';
import {
  useBondsBondFieldApi,
  useBondsBondTransformApi,
} from '../api';
import { BondResponse } from '../../type';
import { useCognitoUserValidAccounts } from '../../../../../../../Components/Sections/online/selectedAccount';

export const useBondsBondRedacted = () => {
  const response = useBondsBondFieldApi('redacted');
  return response;
};

const transformFnHasRedacted = (response: BondResponse | undefined) => {
  const redacted = response?.redacted ?? [];
  return redacted.length > 0;
};

export const useBondsBondHasRedacted = () => {
  const response = useBondsBondTransformApi(transformFnHasRedacted);
  return response;
};

export const useBondsBondIsRedacted = (field: keyof GetBondsBondResponse) => {
  const accountsLength = useCognitoUserValidAccounts().length;
  const isAuthMachineSettled = useAuthIsSettle();
  const transformFn = useCallback((response: BondResponse | undefined) => {
    const redacted = response?.redacted ?? [];
    if (redacted.length === 0) {
      return false;
    }
    if ((!isAuthMachineSettled || accountsLength === 0) && field === 'S_ACC.ISIN') {
      return true;
    }
    return redacted.includes(field);
  }, [field, accountsLength, isAuthMachineSettled]);
  const response = useBondsBondTransformApi(transformFn);
  return response;
};

export const useBondsBondIsSomeRedacted = (fields: (keyof GetBondsBondResponse)[]) => {
  const transformFn = useCallback((response: BondResponse | undefined) => {
    const redacted = response?.redacted ?? [];
    if (redacted.length === 0) {
      return false;
    }
    return fields.some((field) => redacted.includes(field));
  }, [fields]);
  const response = useBondsBondTransformApi(transformFn);
  return response;
};
