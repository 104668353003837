import ouiBase from '@goldwasserexchange/oui';
import { useAccountOrderFieldApiByOpbFordId } from '../api';

export const useAccountOrderCCurrencyCode = () => {
  const opbFordDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useAccountOrderFieldApiByOpbFordId({
    opbFordDId,
    field: 'C_CURRENCY_OPB_FORD_D.CODE',
  });
  return response;
};
