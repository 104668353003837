import React from 'react';
import {
  dataStructure, T_NATURE_LRP, UncastedDataStructure, PersonType, TAddType,
} from '@goldwasserexchange/oblis-frontend-utils';
import { msg, Trans } from '@lingui/macro';
import ouiBase from '@goldwasserexchange/oui';
import { useHookstate } from '@hookstate/core';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useLingui } from '@lingui/react';
import { MessageDescriptor } from '@lingui/core';
import { FieldValueText } from '../../Form/utils';
import { usePrimaryKey } from '../../BaseComponents/List';
import { LmlText } from '../../LmlText';
import { tAddMandatarydata } from '../inputs/moralPerson/mandataries';

const lmlTNatureLRP = (values, personType) => function lml(fieldValue): JSX.Element | null {
  if (fieldValue === '1' && personType === PersonType.FOR_MINORS) {
    return <Trans>Titulaire mineur</Trans>;
  }
  const value = values.find(({ node: { ID = '' } = {} }) => `${ID}` === `${fieldValue}`);
  const { node: { LML_DESCRIPTION } } = value ?? { node: {} };
  return LML_DESCRIPTION ? (
    <LmlText>
      {LML_DESCRIPTION}
    </LmlText>
  ) : null;
};

const translateMandataryFunction = (fieldValue, _: (descriptor: MessageDescriptor) => string): JSX.Element => {
  const { value } = tAddMandatarydata[fieldValue];
  return (

    <>{_(value)}</>
  );
};

const getDefaultFirstname = (personTypeState: dataStructure.personType.listType | undefined, type: TAddType | '', _: (descriptor: MessageDescriptor) => string) => {
  switch (type) {
    case TAddType.TITULAR:
      if (personTypeState === PersonType.JOINT) {
        return _(msg`époux/cohabitant`);
      }
      return '';
    case TAddType.PROCURATION:
    case TAddType.MANDATARY:
      return _(msg`mandataire`);
    case TAddType.UBO:
      return _(msg`bénéficiaire effectif`);
    default:
      return '';
  }
};

export const FormikTAddIconCardLegend = (): JSX.Element => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const primaryKey = usePrimaryKey();
  const userState = valueState.onboarding.users[primaryKey];
  const parentName = userState.path.join('.');
  const userTypeState = useHookstate(userState.T_ADD_type);
  const personTypeState = useHookstate(valueState.onboarding.personType);
  const {
    _,
  } = useLingui();
  const defaultFirstname = getDefaultFirstname(personTypeState.get(), userTypeState.get(), _);
  const defaultTNatureLrp = personTypeState.get() !== PersonType.FOR_MINORS
    ? _(msg`Titulaire`)
    : _(msg`Titulaire mineur`);
  return (
    <>
      <FieldValueText
        fieldState={userState.FIRSTNAME}
        defaultValue={defaultFirstname}
        style={{
          textAlign: 'center',
        }}
      />
      <FieldValueText
        fieldState={userState.NAME_1}
        defaultValue=""
        style={{
          textAlign: 'center',
        }}
      />
      <ouiBase.utils.hookstate.FormFilterLogicVisible
        parentName={parentName}
        logic={{
          name: valueState.onboarding.personType.path.join('.'),
          is: PersonType.MORAL,
          options: {
            isNegative: true,
          },
          alternatives: {
            truthy: {
              result: 'visible',
            },
            falsy: {
              result: 'invisible',
            },
          },
        }}
      >
        <FieldValueText
          fieldState={userState.ID_T_NATURE_LRP}
          defaultValue={personTypeState.get() !== PersonType.DIVIDED ? '' : defaultTNatureLrp}
          transformValueFn={lmlTNatureLRP(T_NATURE_LRP.IdLmlDescriptionInput.edges, personTypeState)}
          style={{
            textAlign: 'center',
          }}
          lines={2}
          whiteSpace={ouiStyle.Constants.WhiteSpaceProperty.normal}
        />
      </ouiBase.utils.hookstate.FormFilterLogicVisible>
      <ouiBase.utils.hookstate.FormFilterLogicVisible
        parentName={parentName}
        logic={{
          name: valueState.onboarding.personType.path.join('.'),
          is: PersonType.MORAL,
          alternatives: {
            truthy: {
              result: 'visible',
            },
            falsy: {
              result: 'invisible',
            },
          },
        }}
      >
        <FieldValueText
          fieldState={userState.ID_C_TIT_MORAL}
          defaultValue=""
          transformValueFn={translateMandataryFunction}
          style={{
            textAlign: 'center',
          }}
        />
      </ouiBase.utils.hookstate.FormFilterLogicVisible>
    </>
  );
};
