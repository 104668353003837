import { SecuritiesSecurity } from '../../securities';
import { BondResponse } from '../bond/type';

export const transformBidOrMidPriceFn = (bondsBond: BondResponse | SecuritiesSecurity | undefined) => {
  if (bondsBond == null) {
    return undefined;
  }
  const priceMin = bondsBond['S_QUOTE.PRICE_MIN'];
  const priceClose = bondsBond['S_QUOTE.PRICE_CLOSE'];
  if (priceMin == null && priceClose == null) {
    return undefined;
  }
  if (priceMin != null) {
    return priceMin;
  }
  return priceClose;
};
