import { SecuritiesSecurity } from '../../type';
import { useSecuritiesSecurityTransformApi } from '../api';
import { getEstimator } from './estimate';

const transformFn = (data: SecuritiesSecurity | undefined) => {
  if (data == null) {
    return undefined;
  }
  const estimator = getEstimator(data);
  const cCurrencyCode = data['C_CURRENCY.CODE'];
  return {
    estimator,
    cCurrencyCode,
  };
};

export const useSecuritiesSecurityEstimatorWithBondCurrency = () => {
  const response = useSecuritiesSecurityTransformApi(transformFn);
  return response;
};
