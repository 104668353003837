import { useCallback } from 'react';
import { SecuritiesSecurity } from '../../type';
import { useSecuritiesSecurityTransformApi } from '../api';
import { getMcGrossOpbDivider } from '../sType';

export const makeMinQuantityTransformFnBase = (minEur?: number) => (bond: SecuritiesSecurity) => {
  const exRate = bond['EXRATE_HIS.EXRATE_GES'];
  const qtMin = bond['S_MARKET.QT_MIN'];
  const quotMul = bond['S_MARKET.QUOT_MULT'];
  const price = bond['S_QUOTE.PRICE_CLOSE'];
  const divider = getMcGrossOpbDivider(bond['S_TYPE.MC_GROSS_OPB']);
  const coupure = divider === 100 ? Math.max(1000, quotMul ?? qtMin) : quotMul;
  const minCurrency = ((minEur ?? 10_000) / ((price ?? divider) / (divider))) / (exRate ?? 1);
  const min = Math.max(qtMin ?? 0, minCurrency);
  const rangeMin = Math.floor(min / coupure) * coupure;
  return rangeMin === 0 ? 1 : rangeMin;
};

export const makeMinQuantityTransformFn = (minEur?: number) => {
  const transformFn = makeMinQuantityTransformFnBase(minEur);
  return (bond: SecuritiesSecurity | undefined) => {
    if (bond == null) {
      return null;
    }
    return transformFn(bond);
  };
};

export const useSecuritiesSecurityMinQuantity = (minEur?: number) => {
  const transformFn = useCallback(
    makeMinQuantityTransformFn(minEur),
    [minEur],
  );
  const response = useSecuritiesSecurityTransformApi(transformFn);
  return response;
};
