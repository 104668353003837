/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

const style: ouiStyle.InStyle = {
  padding: 'double',
  margin: 'double',
  textAlign: 'center',
};

export const DirectNavigationButtonContainer = (props: {
  padding?: ouiStyle.InStyle['padding'],
  margin?: ouiStyle.InStyle['margin'],
  children: React.ReactNode,
}) => {
  const {
    padding = style.padding,
    margin = style.margin,
    children,
  } = props;
  const css = React.useMemo(
    () => ouiStyle.makeCss({
      ...style,
      padding,
      margin,
    }),
    [
      padding,
      margin,
    ],
  );
  return (
    <div
      css={css}
    >
      {children}
    </div>
  );
};
