import { useLingui } from '@lingui/react';
export var useLabel = function () {
    var i18n = useLingui().i18n;
    var translated = i18n._('Statut');
    return translated;
};
export var Label = function () {
    var translated = useLabel();
    return translated;
};
