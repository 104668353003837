import { useCallback } from 'react';
import { makeMinQuantityTransformFnBase } from './minQuantity';
import { useSecuritiesSecurityTransformApi } from '../api';
import { SecuritiesSecurity } from '../../type';
import { maxQuantityTransformFnBase } from './maxQuantity';

const makeMinMaxQuantityTransformFn = (props: {minEur?: number, fixedMax?: number }) => {
  const {
    minEur,
    fixedMax,
  } = props;
  const minQuantityTransformFn = makeMinQuantityTransformFnBase(minEur);
  return (bond: SecuritiesSecurity | undefined) => {
    if (bond == null) {
      return undefined;
    }
    return {
      min: minQuantityTransformFn(bond),
      max: fixedMax == null ? maxQuantityTransformFnBase(bond) : fixedMax,
    };
  };
};

export const useSecuritiesSecurityMinMaxQuantity = (props: {minEur?: number, fixedMax?: number }) => {
  const {
    minEur,
    fixedMax,
  } = props;
  const transformFn = useCallback(
    makeMinMaxQuantityTransformFn({ minEur, fixedMax }),
    [
      minEur,
      fixedMax,
    ],
  );
  const response = useSecuritiesSecurityTransformApi(transformFn);
  return response;
};
