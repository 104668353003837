import { IDevice, UAParser } from 'ua-parser-js';
import { msg } from '@lingui/macro';
import {
  Box, Cpu, Icon, Monitor, Smartphone, Tablet, Tv, Watch,
} from 'react-feather';
import { MessageDescriptor } from '@lingui/core';
import { PersonDevices } from '../type';
import { Question } from '../../../../../../Components/QuestionIcon';

type DeviceType = NonNullable<IDevice['type']> | 'computer' | 'unknown';

const getDevice = (line: PersonDevices | undefined | null): IDevice | null => {
  if (line) {
    const parser = new UAParser(line.value);
    return parser.getDevice();
  }
  return null;
};

export const getDeviceType = (line: PersonDevices | undefined | null): DeviceType => {
  const device = getDevice(line);
  if (device) {
    return device.type ?? 'computer';
  }
  return 'unknown';
};

const devices: Record<DeviceType, MessageDescriptor> = {
  console: msg`Console`,
  mobile: msg`Mobile`,
  tablet: msg`Tablette`,
  smarttv: msg`Smart TV`,
  wearable: msg`Wearable`,
  embedded: msg`Embedded`,
  xr: msg`XR`,
  computer: msg`Ordinateur`,
  unknown: msg`Appareil inconnu`,
};

export const makeGetDeviceTypeTranslation = (_: (descriptor: MessageDescriptor) => string) => (line: PersonDevices | undefined | null) => {
  const deviceType = getDeviceType(line);
  return _(devices[deviceType]);
};

export const getDeviceModel = (line: PersonDevices | undefined | null) => {
  const device = getDevice(line);
  if (device) {
    if (device.type === 'mobile') {
      if (device.vendor && device.model) {
        return `(${device.vendor} ${device.model})`;
      }
      if (device.vendor) {
        return `(${device.vendor})`;
      }
    }
  }
  return '';
};

export const DeviceTypeIcons: Record<DeviceType, Icon | (() => JSX.Element)> = {
  console: Cpu,
  mobile: Smartphone,
  tablet: Tablet,
  smarttv: Tv,
  wearable: Watch,
  embedded: Cpu,
  xr: Box,
  computer: Monitor,
  unknown: Question,
};

export const getBrowser = (line: PersonDevices | null | undefined): UAParser.IBrowser | null => {
  if (line) {
    const parser = new UAParser(line.value);
    return parser.getBrowser();
  }
  return null;
};

export const makeGetBrowserNameWithTranslation = (_: (descriptor: MessageDescriptor) => string) => (line: PersonDevices | null | undefined) => {
  const browser = getBrowser(line);
  return browser?.name ?? _(msg`Navigateur inconnu`);
};

export const getOs = (line: PersonDevices | null | undefined): UAParser.IOS | null => {
  if (line) {
    const parser = new UAParser(line.value);
    return parser.getOS();
  }
  return null;
};

export const makeGetOSNameWithTranslation = (_: (descriptor: MessageDescriptor) => string) => (line: PersonDevices | null | undefined) => {
  const os = getOs(line);
  return os?.name ?? _(msg`OS inconnu`);
};
