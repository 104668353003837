import { useAtomValue } from 'jotai';
import { PersonAccounts } from '../../type';
import { useFilterByTAccId } from '../../utils';
import { usePersonAccountsApi, usePersonAccountTransformApi, usePersonAccountsTransformApi } from '../api';
import { selectedTAccIdAtom } from '../../../../../../../Components/Sections/online/selectedAccount';

const hasBuySellRights = (line: PersonAccounts | null | undefined) => {
  if (line == null) {
    return false;
  }
  if (line['T_ACC.MC_FREEZE'] !== 20) {
    return false;
  }
  if (line['T_CAT.SHORTCUT'] === 'GESTION') {
    return false;
  }
  if (line['T_NATURE_LRP.SHORTCUT'] !== 'TITULAIRE' && line['T_PROC_TYPE.SHORTCUT'] !== 'GENERALE' && line['T_PROC_TYPE.SHORTCUT'] !== 'GESTION' && line['T_PROC_TYPE.SHORTCUT'] !== 'INTERNAL' && line['T_PROC_TYPE.SHORTCUT.SHORTCUT'] !== 'DEMO') {
    return false;
  }
  return true;
};

export const useAccountHasBuySellRights = () => {
  const filterBySelectedTAccId = useFilterByTAccId();
  const accountTypeAllowBuySellResult = usePersonAccountTransformApi({
    transformFunction: hasBuySellRights,
    filterPredicate: filterBySelectedTAccId,
  });
  return accountTypeAllowBuySellResult;
};

export const useBuySellRightsAccounts = () => {
  const buySellAccountsResult = usePersonAccountsApi({
    filterPredicate: hasBuySellRights,
  });
  return buySellAccountsResult;
};

const transformFunction = (personAccounts: PersonAccounts[] | undefined) => {
  if (personAccounts == null) {
    return undefined;
  }
  return personAccounts.map((account) => `${account['T_ACC.ID']}`);
};

export const useBuySellRightsAccountIds = () => {
  const buySellAccountsResult = usePersonAccountsTransformApi({
    filterPredicate: hasBuySellRights,
    transformFunction,
  });
  return buySellAccountsResult;
};

const hasLenght = (data) => (data?.length ?? 0) > 0;

export const useHasBuySellRightsAccounts = () => {
  const buySellAccountsResult = usePersonAccountsTransformApi({
    filterPredicate: hasBuySellRights,
    transformFunction: hasLenght,
  });
  return buySellAccountsResult;
};

export const useCanTAccIdBuySell = () => {
  const {
    data: tAccIds,
  } = useBuySellRightsAccountIds();
  const tAccId = useAtomValue(selectedTAccIdAtom);
  if (tAccIds == null) {
    return false;
  }
  if (!tAccIds.includes(`${tAccId}`)) {
    return false;
  }
  return true;
};

export const useTAccIdForSale = () => {
  const {
    data: tAccIds,
  } = useBuySellRightsAccountIds();
  const tAccId = useAtomValue(selectedTAccIdAtom);
  if (tAccIds == null) {
    return '';
  }
  if (tAccIds.includes(`${tAccId}`)) {
    return `${tAccId}`;
  }
  return tAccIds?.[0] != null ? `${tAccIds?.[0]}` : '';
};
