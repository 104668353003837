import { useContext, useMemo } from 'react';
import { GetAccountsDocumentsParams } from '@goldwasserexchange/actor/rest-services';
import { useTAccId } from '../../../shared';
import { stringifyParams } from '../../../shared/utils/makeUrl';
import { accountsDocumentsParamsContext } from '../context';
import { useCognitoUserTAddId } from '../../../../../../Components/Sections/online/tAddId';
import { useIsConnected } from '../../../../../../Components/Auth';
import { useAtomValue } from 'jotai';
import { accountDocumentPageAtom } from '../../../../../../history';

export const useAccountDocumentsDefaultParams = (params: Partial<GetAccountsDocumentsParams>): Partial<GetAccountsDocumentsParams> => {
  const contextParams = useContext(accountsDocumentsParamsContext) ?? {} as Partial<GetAccountsDocumentsParams>;
  const tAccIdString = useTAccId();
  const currentTAccId = tAccIdString ? parseInt(tAccIdString, 10) : undefined;
  const cognitoUsertAddId = useCognitoUserTAddId();
  const tAddId = params['T_ADD.ID'] ?? contextParams['T_ADD.ID'] ?? cognitoUsertAddId;
  const tAccId = params['T_ACC.ID'] ?? contextParams['T_ACC.ID'] ?? currentTAccId;
  const defaultParams = useMemo(() => ({
    'T_ADD.ID': tAddId,
    'T_ACC.ID': tAccId,
  }), [tAccId]);
  return defaultParams;
};

export const useAccountDocumentsParams = (): Omit<GetAccountsDocumentsParams, 'ROW_NUMBER_START' | 'ROW_NUMBER_END'> & {
  'ROW_NUMBER_START'?: GetAccountsDocumentsParams['ROW_NUMBER_START'],
  'ROW_NUMBER_END'?: GetAccountsDocumentsParams['ROW_NUMBER_END'],
} | null => {
  const defaultParams = useAccountDocumentsDefaultParams({});
  const tAddId = defaultParams['T_ADD.ID'];
  const isConnected = useIsConnected();
  const page = useAtomValue(accountDocumentPageAtom);
  if (tAddId == null || !isConnected) {
    return null;
  }
  const tAccId = defaultParams['T_ACC.ID'];
  if (tAccId == null) {
    return null;
  }
  return {
    'T_ADD.ID': tAddId,
    'T_ACC.ID': tAccId,
    ROW_NUMBER_START: (Math.max(page - 1, 0)) * 20,
    ROW_NUMBER_END: page * 20,
  };
};

export const useAccountDocumentsStringifiedParams = () => {
  const contextParams = useAccountDocumentsParams();
  return contextParams !== null ? stringifyParams(contextParams) : null;
};
