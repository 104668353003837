import ouiBase from '@goldwasserexchange/oui';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useCallback } from 'react';
import { MessageDescriptor } from '@lingui/core';
import { BondsCall } from '../../type';
import { lastNonPostCallCmtHist } from '../../utils/lastNonPostCall';
import { nextCallsFilterPredicate } from '../../utils/nextCall';
import { transformCmtHist } from '../../utils/transformCmtHist';
import { useBondsCallFieldTransformApiByEvtEntrySId, useBondsCallTransformApi, useBondsCallLinesTransformApi } from '../api';

export const useBondsCallEvtEntrySCmtHistPct = () => {
  const evtEntrySId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useBondsCallFieldTransformApiByEvtEntrySId({
    evtEntrySId,
    field: 'EVT_ENTRY_S.CMT_HIST',
    transformFn: transformCmtHist,
  });
  return result;
};

const transformCmtHistNexCall = (securityBondCall: BondsCall | undefined) => {
  if (securityBondCall == null) {
    return undefined;
  }
  return transformCmtHist(securityBondCall['EVT_ENTRY_S.CMT_HIST']);
};

export const useBondsCallEvtEntrySCmtHistNextCall = () => {
  const result = useBondsCallTransformApi({
    filterPredicate: nextCallsFilterPredicate,
    transformFunction: transformCmtHistNexCall,
  });
  return result;
};

export const useBondsCallEvtEntrySCmtHistLast = () => {
  const result = useBondsCallLinesTransformApi({
    transformFunction: lastNonPostCallCmtHist,
  });
  return result;
};

const postPlanningCallTexts = {
  ANYTIME: msg`À tout moment`,
  COUPON: msg`Aux dates de paiement des coupons`,
};

const makeTransformPostPlanningCall = (_: (descriptor: MessageDescriptor) => string) => (field: BondsCall['EVT_ENTRY_S.CMT_HIST'] | undefined) => {
  if (field == null) {
    return undefined;
  }
  const textFn = postPlanningCallTexts[field];
  if (!textFn) {
    return _(postPlanningCallTexts.ANYTIME);
  }
  return _(textFn);
};

export const useBondsCallEvtEntrySCmtHistPostPlanningCall = () => {
  const evtEntrySId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const {
    _,
  } = useLingui();
  const transformPostPlanningCall = useCallback((field: BondsCall['EVT_ENTRY_S.CMT_HIST'] | undefined) => makeTransformPostPlanningCall(_)(field), [_]);
  const result = useBondsCallFieldTransformApiByEvtEntrySId({
    evtEntrySId,
    field: 'EVT_ENTRY_S.CMT_HIST',
    transformFn: transformPostPlanningCall,
  });
  return result;
};
